// WebHostingData.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSharedCurrencyState } from '../layouts/Core/HeroBanner';

interface Product {
    id: number;
    name: string;
    description: string;
    stock: boolean;
    price: string;
}

interface Group {
    groupName: string;
    location: string;
    products: Product[];
}

const parseDescription = (desc: string) => {
    let parts = desc.split("\r\n");
    let ssd = parts.find(part => part.includes("SSD"));
    let cpuCores = parts.find(part => part.includes("CPU Cores")) || "";
    let ram = parts.find(part => part.includes("RAM")) || "";
    let bandwidth = parts.find(part => part.includes("Bandwidth"));
    let domains = parts.find(part => part.includes("Domains"));
    let subdomains = parts.find(part => part.includes("Subdomains"));
    let databases = parts.find(part => part.includes("Databases"));
    let emails = parts.find(part => part.includes("Email Accounts"));

    return { ssd, cpuCores, ram, bandwidth, domains, subdomains, databases, emails };
}

const productNameToIconClass = {
    'Standard Basic': 'fa-signal-bars-weak',
    'Standard Plus': 'fa-signal-bars-fair',
    'Standard Pro': 'fa-signal-bars-good',
    'Business Basic': 'fa-cart-flatbed-empty',
    'Business Plus': 'fa-cart-flatbed',
    'Business Pro': 'fa-cart-flatbed-suitcase',
    'Performance Basic': 'fa-gauge-simple-min',
    'Performance Plus': 'fa-gauge-simple',
    'Performance Pro': 'fa-gauge-simple-max'
};

const WebHostingData: React.FC<{location: string, serverType: string}> = ({ location, serverType }) => {
    const [data, setData] = useState<{ [country: string]: { [pack: string]: Product[] } }>({});
	const [loading, setLoading] = useState(true);
	const { currencySign, currencyId  } = useSharedCurrencyState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`https://billing.streamline-servers.com/store/web-hosting?json=1&currency=${currencyId}`);
                const groups = result.data as Group[];

                const groupedData = groups.reduce((prev, curr) => {
                    const country = curr.groupName.includes('Australia') ? 'Australia' : 'America';
                    curr.products.forEach(product => {
                        const pack = product.name.split(' - ')[1].split(' ')[0];
                        if (!prev[country]) {
                            prev[country] = { [pack]: [product] };
                        } else if (!prev[country][pack]) {
                            prev[country][pack] = [product];
                        } else {
                            prev[country][pack].push(product);
                        }
                    });
                    return prev;
                }, {} as { [country: string]: { [pack: string]: Product[] } });

                setData(groupedData);
				setLoading(false);
            } catch (error) {
                console.error('Error fetching data: ', error);
				setLoading(false);
            }
        };

        fetchData();
    }, [currencyId]);
	
	if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="row">
            {Object.keys(data)
                .filter(country => country.toUpperCase() === location.toUpperCase())
                .flatMap(country => 
                    Object.keys(data[country])
                    .filter(pack => pack.toUpperCase() === serverType.toUpperCase())
                    .flatMap(pack => 
                        data[country][pack].map((product, index) => {
                            const { ssd, cpuCores, ram, bandwidth, domains, subdomains, databases, emails } = parseDescription(product.description);
                            return (
                                <div key={index} className="col col-lg-4">
                                    <div className="box pt-5 pb-4">
                                        <div className="block pb-2">
                                            <h3 className="mb-3">{product.name.replace(/^(AU|US) - /, '')}</h3>
                                            <i className={`fa-duotone ${productNameToIconClass[product.name.replace(/^(AU|US) - /, '')]} fa-2xl`}></i>
                                            <h2 className="semibold block mb-n2 mt-4">{currencySign}{product.price}<strong className="font-18">/mo</strong></h2>
											{product.name === 'AU - Standard Plus' && (
												<strong className="label secondary mt-2 mb-2">Recommended</strong>
											)}
                                            <ul className="mt-2 mb-4">
                                                <li className="py-1"><i className="fa-regular fa-hard-drive blue mr-2"></i>{ssd}</li>
												{
													cpuCores && 
													<li className="py-1">
														<i className="fa-regular fa-microchip blue mr-2"></i>{cpuCores}
													</li>
												}

												{
													ram && 
													<li className="py-1">
														<i className="fa-regular fa-memory blue mr-2"></i>{ram}
													</li>
												}
                                                <li className="py-1"><i className="fa-regular fa-network-wired blue mr-2"></i>{bandwidth}</li>
                                                <li className="py-1"><i className="fa-regular fa-earth-africa blue mr-2"></i>{domains} <span className="tooltip-top inline-block" data-tooltip="Domains are unique web addresses that serve as your online identity."><i className="fa-regular fa-circle-question font-14 green ml-1"></i></span></li>
                                                <li className="py-1"><i className="fa-regular fa-globe-stand blue mr-2"></i>{subdomains}<span className="tooltip-top inline-block" data-tooltip="Subdomains are extensions of your main domain used to create separate web addresses."><i className="fa-regular fa-circle-question font-14 green ml-1"></i></span></li>
                                                <li className="py-1"><i className="fa-regular fa-database blue mr-2"></i>{databases}</li>
                                                <li className="py-1"><i className="fa-regular fa-envelope blue mr-2"></i>{emails}</li>
                                                <li className="py-1"><i className="fa-regular fa-shield blue mr-2"></i>Free SSL Certificate <span className="tooltip-top inline-block" data-tooltip="We use LetsEncrypt to secure your website on setup."><i className="fa-regular fa-circle-question font-14 green ml-1"></i></span></li>
                                            </ul>
											{product.stock 
												? <a href={`https://billing.streamline-servers.com/cart.php?a=add&pid=${product.id}&currency=${currencyId}`} className="button block">Order Now</a> 
												: <a href="javascript:void(0)" className="button out-of-stock mt-3"><i className="fa-regular fa-ban mr-1"></i>No Stock</a>
											}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    )
                )
            }
        </div>
    );
};

export default WebHostingData;
