import { useEffect, useMemo, useState } from 'react';

import Container from '../layouts/Core/Container';

import Travel from '../layouts/Core/Travel';

import { useTawkContext } from '../layouts/TawkContext';

import ReactCountryFlag from "react-country-flag";

import gslMark from '../assets/images/globalsecurelayer-mark.svg'
import ddosShield from '../assets/images/ddos-shield.svg';

import MapCloud from '../layouts/MapCloud';
import Games, { useSharedSearchState } from '../layouts/Games';
import { Dropdown, Nav, Tab } from 'react-bootstrap';
import { slideDown, slideUp } from '../helpers/animations';
import CloudServers from '../layouts/ServersCloud';

 export default function CloudPage() {
    
    const [searchFilterBuffer, setSearchFilterBuffer] = useState("");
    const [style, setStyle] = useState({height: '0', opacity: '0', overflow: 'hidden'});
    
    const { search, setSearch } = useSharedSearchState();
	
	const { handleMaximize } = useTawkContext();
	
	const [imageClicked, setImageClicked] = useState(false);

	const handleImageClick = () => {
		setImageClicked(true);
	};

	const handleOverlayClick = () => {
		setImageClicked(false);
	};

    useEffect(() => {
        setStyle(searchFilterBuffer.length !== 0 ? {height: 'inherit', opacity: 'inherit', overflow: 'inherit'} : {height: '0', opacity: '0', overflow: 'hidden'});

        const delaySearch = setTimeout(() => {
            setSearch(searchFilterBuffer);
        }, 300)
    
        return () => clearTimeout(delaySearch)
      }, [searchFilterBuffer]);

    return (
        <Container title='Streamline Servers - Cloud Gaming Servers' description='Step up your game with further access to our Game Panel. With Cloud Gaming Servers, you are able to install multiple game servers of your choosing, with the ability to swap game type whenever you want!' className='page-cloud'>
            <section className="sub-hero pb-5" id="hero">
                <div className="container">
                    <div className="row">
                    <div className="col col-lg-6">
                        <h1>Cloud Gaming Servers</h1>
                        <h4 className="font-22 pt-1 green">Experience the freedom of swapping games on the fly!</h4>
                        <p className="pb-4 pt-3 font-20">
                        Step up your game with further access to our Game Panel. With Cloud Gaming Servers, you are able to install multiple game servers of your choosing, with the ability to swap game type whenever you want!
                        </p>
                    </div>
                    <div className="col col-lg-6 ml-auto pl-5 plfix hidemobile hidetablet">
                        <ul className="font-20 pt-1 mt-1 ptfix mtfix" id="cloud-hero-list">
                        <li className="py-2">
                            <div className="cloud-hero-icon">
                            <i className="fa-regular fa-microchip"></i>
                            </div>
                            Increase & decrease server resources on the fly
                        </li>
                        <li className="py-2">
                            <div className="cloud-hero-icon">
                            <i className="fa-regular fa-mouse"></i>
                            </div>
                            Swap between games with the click of a button
                        </li>
                        <li className="py-2">
                            <div className="cloud-hero-icon">
                            <i className="fa-regular fa-globe"></i>
                            </div>
                            Choose from 7 locations world-wide
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </section>

                <section className="pt-0" id="cloud-plans">
                <div className="container">
					<div className="filter-by-us">
						<CloudServers />
					</div>
                </div>
                </section>

                <section className="features light-bg" id="cloud-features">
                <div className="container">
                    <div className="title">
                    <h2><small>What's Included?</small>Cloud Gaming Features</h2>
                    <p>
                        Our Cloud Gaming Servers provide you with exclusive hardware resources for your game panel account
                    </p>
                    </div>
                    <div className="row text-center mt-2 mtfix">
                    <div className="col col-md-6 col-lg-4 px-4">
                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-clock va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-clock va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                        <h4>Instant Deployment</h4>
                        <p className="pb-0">
                        Upon payment, our control panel facilitates the instant setup of your new Cloud Gaming account, ensuring a hassle-free and streamlined experience.
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-4 px-4">
                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-microchip va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-microchip va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                        <h4>Dedicated Resources</h4>
                        <p className="pb-0">
                        Exclusive CPU and RAM for your use - enjoy dedicated hardware resources to customize and optimize your gaming experience.
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-4 px-4">
                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-hard-drive va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-hard-drive va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                        <h4>Swap 'n Go</h4>
                        <p className="pb-0">
                        Install as many game servers as you want.*<br />Tired of your game? Install a new one!<br /><span className="font-12 opacity-50">*within the limits of your assigned resources.</span>
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-4 px-4">
                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-chart-network va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-chart-network va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                        <h4>Low Latency</h4>
                        <p className="pb-0">
                        Our low latency network provides gamers with a smooth and responsive online gaming experience, minimizing delays and ensuring optimal gameplay.
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-4 px-4">
                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-shield va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-shield va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                        <h4>DDoS Protection</h4>
                        <p className="pb-0">
                        We partner with Global Secure Layer to offer robust DDoS protection, safeguarding game servers against malicious attacks and ensuring uninterrupted gameplay for our customers.
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-4 px-4">
                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-hard-drive va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-hard-drive va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                        <h4>Latest Hardware</h4>
                        <p className="pb-0">
                        Our server hardware utilizes the latest cutting-edge technology, ensuring superior performance and reliability for an immersive gaming experience even with the newest game releases.
                        </p>
                    </div>
                    </div>
                </div>
                </section>
				
                <section id="cloud-control-panel">
                    <div className="container">
                        <Tab.Container defaultActiveKey={"one"}>
                            <div className="title">
                                <h2><small>How to manage your Cloud Gaming Servers</small>Advanced Game Panel</h2>
                                <p>
                                    Take full control with our heavily customised advance Game Panel
                                </p>
                            </div>
                            <div className="row">
                                <div className="col col-md-12 col-lg-6">
									<Tab.Content>
									  <Tab.Pane eventKey={"one"}>
										<div className={`col col-md-12 col-lg-6 mt-3 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
										  {imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
										  <img
											src={require("../assets/images/game-panel-1.webp")}
											onClick={handleImageClick}
										  />
										  {imageClicked && (
											<img
											  src={require("../assets/images/game-panel-1-full.webp")}
											  className='responsive-img enlarged'
											  onClick={handleOverlayClick}
											/>
										  )}
										</div>
									  </Tab.Pane>
									  <Tab.Pane eventKey={"two"}>
										<div className={`col col-md-12 col-lg-6 mt-3 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
										  {imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
										  <img
											src={require("../assets/images/game-panel-cgs-3.webp")}
											onClick={handleImageClick}
										  />
										  {imageClicked && (
											<img
											  src={require("../assets/images/game-panel-cgs-3-full.webp")}
											  className='responsive-img enlarged'
											  onClick={handleOverlayClick}
											/>
										  )}
										</div>
									  </Tab.Pane>
									  <Tab.Pane eventKey={"three"}>
										<div className={`col col-md-12 col-lg-6 mt-3 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
										  {imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
										  <img
											src={require("../assets/images/game-panel-cgs-2.webp")}
											onClick={handleImageClick}
										  />
										  {imageClicked && (
											<img
											  src={require("../assets/images/game-panel-cgs-2-full.webp")}
											  className='responsive-img enlarged'
											  onClick={handleOverlayClick}
											/>
										  )}
										</div>
									  </Tab.Pane>
									  <Tab.Pane eventKey={"four"}>
										<div className={`col col-md-12 col-lg-6 mt-3 ${imageClicked ? 'blur-background active' : 'blur-background'}`}>
										  {imageClicked && <div className='overlay' onClick={handleOverlayClick} />}
										  <img
											src={require("../assets/images/game-panel-cgs-1.webp")}
											onClick={handleImageClick}
										  />
										  {imageClicked && (
											<img
											  src={require("../assets/images/game-panel-cgs-1-full.webp")}
											  className='responsive-img enlarged'
											  onClick={handleOverlayClick}
											/>
										  )}
										</div>
									  </Tab.Pane>
									</Tab.Content>
                                </div>
                                    <div className="col col-md-12 col-lg-5 ml-auto">
                                        <Nav role="tablist" className="panel-tab-list mt-4 pt-1 ptfix mtfix d-block">
                                            <Nav.Item>
                                                <Nav.Link eventKey={"one"} className="panel-tab-box" as='div'>
                                                    <h5><i className="fa-light fa-gauge-simple-max font-24 mr-3 va-mid"></i>Modern Look</h5>
                                                    <p className="pb-0">
                                                    Designed to be sleek and adaptable with its dark and light modes. Effortlessly access advanced features that cater to users of all levels, whether you're a beginner or a seasoned expert.
                                                    </p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey={"two"} className="panel-tab-box">
                                                    <h5><i className="fa-light fa-square-plus font-24 mr-3 va-mid"></i>Create Game Servers</h5>
                                                    <p className="pb-0">
                                                    Create your ideal game server in a few clicks with the game panel. Customize settings to your preference and get your server up and running quickly.
                                                    </p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey={"three"} className="panel-tab-box">
                                                    <h5><i className="fa-light fa-user font-24 mr-3 va-mid"></i>Create Sub Users</h5>
                                                    <p className="pb-0">
                                                    The game panel allows creating sub-user accounts with selective feature access, enabling server management by multiple users. Improve collaboration and delegate tasks efficiently.
                                                    </p>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey={"four"} className="panel-tab-box">
                                                    <h5><i className="fa-light fa-bars-progress font-24 mr-3 va-mid"></i>Easy Game Management</h5>
                                                    <p className="pb-0">
                                                    Effortlessly manage multiple game servers on the panel. Start/stop individual or all servers, and batch update them efficiently for ease of management.
                                                    </p>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                        </Tab.Container>
                    </div>
                </section>

                <section className="light-bg" id="cloud-swap-games">
                <div className="container">
                    <div className="row">
                    <div className="col col-lg-6 text-center">
                        <img src={require("../assets/images/swap-games.webp")} className="showdesktop" />
                        <img src={require("../assets/images/swap-games-mobile.webp")} className="showmobile" />
                    </div>
                    <div className="col col-lg-5 pt-4 mt-3 mtfix">
                        <h2><small>Game Switching</small>Swap Games Instantly*</h2>
                        <span className="font-18 semibold block pt-1 green pb-3">There are so many games, why be stuck with just one?</span>
                        <p>
                        With our Cloud Gaming Servers, you can install, modify and remove as many games as you wish.** Our Game Panel automates the installations, so with just a few clicks you can go from Minecraft to Rust!<br /><span className="font-12 opacity-50">*game installs can take up to 10 minutes to download.</span><br /><span className="font-12 opacity-50">**within the limits of your assigned resources.</span>
                        </p>
                    </div>
                    </div>
                    <div className="swap-games-search">
                        <input type="text" onChange={e => {setSearchFilterBuffer(e.target.value)} } value={searchFilterBuffer} placeholder="Enter your game in here..." className="game-search mt-5 mb-n1 mx-auto quicksearch" id="gamesearch" />
                        <div style={style}>
                            <Games showCount={true}/>
                        </div>
                    </div>
                </div>
                </section>

                <section className="py-5" id="ddos-row">
					<div className="container py-3">
						<div className="row">
							<div className="col col-lg-4 text-center">
								<img src={ddosShield} className="mobile-icon-height" />
							</div>
							<div className="col col-lg-8 mtfix ptfix">
								<h2 className="pt-5 ptfix"><small>INCLUDED DDOS PROTECTION</small>Making Sure Your Server is Always Online!</h2>
								<p className="pt-1">
								Streamline-Servers has formed a strategic partnership with Global Secure Layer, a renowned cybersecurity firm known for their latest DDoS technology. This collaboration empowers us to offer our clients robust protection against DDoS attacks by integrating Global Secure Layer's cutting-edge mitigation solution into our infrastructure. Together, we ensure uninterrupted availability and optimal performance of our clients' online services, leveraging advanced technologies to swiftly detect and mitigate malicious traffic. Through this partnership, Streamline-Servers reaffirms its commitment to delivering top-tier DDoS Protection solutions and providing clients with the peace of mind they deserve.
								</p>
								<strong className="font-18 block pb-1 pt-2">DDoS Protection by <img src={gslMark} className="ml-1 mr-1 gsl-mark-sm" />Global Secure Layer</strong>
								<a href="https://globalsecurelayer.com/ddos-protection" className="strong">Find our more <i className="fa-sharp fa-solid fa-circle-chevron-right font-11"></i></a>
							</div>
						</div>
					</div>
                </section>

                <div className="container">
                <div className="line-gradient-divider"></div>
                </div>

                <section id="locations-with-list">
                <div className="container">
                    <div className="title">
                    <h2><small>Locations</small>World Wide Locations</h2>
                    <p>
                        Our CGS servers are currently available in the following locations.
                    </p>
                    </div>
                    <MapCloud/>
                </div>
                </section>

                <div className="container">
                <div className="line-gradient-divider"></div>
                </div>

                <section id="faq-one-third">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-12 col-lg-4">
                        <div className="title text-left pb-3">
                        <h2><small>CLOUD GAMING SERVERS FAQ</small>Frequently <br />Asked Questions</h2>
                        </div>
                        <span className="block">Have your own question? <br />
                        <a href="javascript:void(0);" onClick={handleMaximize}>Ask us here <i className="fa-sharp fa-solid fa-circle-chevron-right font-11"></i></a></span>
                    </div>
                    <div className="col col-md-12 col-lg-8 pl-5 plfix">

                        <div className="accordions" id="accordionFAQ">
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is Cloud Gaming Servers?
                                    </button>
                                    </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Cloud Gaming Servers is a service that enables you to purchase resources on our TCAdmin Game Panel instead of individual game servers. With this, you can efficiently install, uninstall, and manage multiple game servers within your allocated resources. You are also given some extra features (depending on the game) such as the ability to set RAM allocation per game server.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Which games can I host?
                                    </button>
                                    </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                You can host any games listed on our Game Server list above (scroll up to the "Swap Games Instantly" section to search for your game). We ensure that popular game titles are always up to date, and we also offer a selection of niche games.
								<br />
								Don't see the game you are looking for? Contact us <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1">here.</a>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Can I upgrade a specific resource?
                                    </button>
                                    </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Upgrades for individual resources are handled on a case-by-case basis. Please submit a <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1">support ticket</a> to discuss your specific requirements with our staff.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        I want to host more game servers but have reached my limit.
                                    </button>
                                        </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                If you need to host additional game servers beyond your current limit, we recommend considering our Dedicated Servers with our Game Panel addon. This option allows you to have full control over the resources of a Dedicated Server using our Game Panel. Additionally, we offer migration services from Cloud Gaming Servers to Dedicated Servers with the Game Panel addon.
                            </div>
                            </div>
                        </div>

                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="green-bg bg-cta-squares" id="cta">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-8 col-lg-6">
                        <h2>Try a Cloud Gaming Server Today!</h2>
                        <strong className="block pb-4 font-22">Not sure if this is right for you? Contact Us!</strong>
                        <strong className="font-18 block pb-1">You can contact us at anytime</strong>
                        <ul className="cta-link-list">
                        <li className="inline-block pr-3">
                            <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="white-link"><i className="fa-solid fa-envelope font-16 mr-1"></i>Support Ticket</a>
                        </li>
                        <li className="inline-block pr-3">
                            <a href="javascript:void(0);" onClick={handleMaximize} className="white-link"><i className="fa-solid fa-comments font-16 mr-1"></i> Sales Live Chat</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col col-md-4 col-lg-6 text-center">
                        <Travel to="/cloud-gaming-servers" className="button large dark mt-5">Back to Top <i className="fa-sharp fa-solid fa-arrow-up-long ml-1"></i></Travel>
                    </div>
                    </div>
                </div>
                </section>
        </Container>
     );
 }