import { useMemo } from "react";
import Travel from "../layouts/Core/Travel";
import { useGamesList, IGame } from "./GamesList";
import { STANDARD_ROUTE_URIS } from "../routes/Router";
import { useSharedCurrencyState } from '../layouts/Core/HeroBanner';

export default function LatestGames() {
    
    const gamesList: IGame[] = useGamesList();

	const { currencySign } = useSharedCurrencyState();

    const LGames = useMemo(() => {
        return <div className="row mt-5 latest-game-row">
            {
                gamesList.map((game, index) => {
					if(game.latest === true) {
                    return <div className="col col-md-4 col-lg-3">
						<Travel to={STANDARD_ROUTE_URIS.GAME_SERVERS_INNER.replace(':game', game.key === undefined ? '' : game.key)} className="game-box-large block" style={{backgroundImage: `url(${require(`../assets/images/games/${game.boxImage}`)})`}}>
							<div className="game-box-bottom text-center">
								<h3>{game.name}</h3>
								{game.comingsoon ? (
								  <span className="block">Coming Soon</span>
								) : game.stock ? (
								  <span className="block">Starting at <span className="green">{currencySign}{game.startingAt}</span>/month</span>
								) : (
								  <span className="block">Out of Stock</span>
								)}
								<span className="learn-more">
									LEARN MORE<i className="fa-regular fa-chevrons-right ml-1 font-12"></i>
								</span>
							</div>
						</Travel>
						</div>
					}
                })
            }
		</div>
    }, [gamesList]);

    return (
        <>
			{ LGames }
        </>
                
    );

}