import Travel from "../layouts/Core/Travel";

import ReactCountryFlag from "react-country-flag";

// Import svg's with import statements given it's more reactive.
import intel from '../assets/images/intel.svg';
import ryzen from '../assets/images/ryzen.svg';
import { ReactNode } from "react";

import Servers, { LOCATIONS } from '../layouts/Servers';

// Handle webp's with requirement statements as it's more reactive.
const epyc = require('../assets/images/epyc.webp');

export interface PlanLabelProps {
    className: string;
    label: string;
    icon?: string;
}

export interface ServerProps {
    // Sortables
    locationTags: string;
    sortTags: string;
    inStock: boolean;
	stockQTY: number;

    // Display
    name: string;
	
	serverLink: string;

    processorManufacturer: string;

    processorText: string[2][];
    ramText: string[2][];
    storageText: string[2][];
    transferText: string[2][];
	transferSpeed: string[2][];

    monthlyPrice: string;
	oldPrice?: string;

    // Plan Labelling
    labels?: PlanLabelProps[]

}

function renderStockInfo(stockQTY: number) {
  if (stockQTY === 0) {
    return (
      <li className="server-stock">
        <i className="fa-regular fa-cart-circle-xmark va-mid mr-2" />Out of Stock
      </li>
    );
  } else if (stockQTY === 1) {
    return (
      <li className="server-stock-low">
        <i className="fa-regular fa-cart-circle-exclamation va-mid mr-2" />Last One!
      </li>
    );
  } else {
    return (
      <li className="server-stock">
        <i className="fa-regular fa-shopping-cart va-mid mr-2" />{stockQTY} In Stock
      </li>
    );
  }
}

export default function Server( props: ServerProps ) {

	const uppercaseLocationTags = props.locationTags.toUpperCase().replace(/\s/g, '_');
	const country = LOCATIONS[uppercaseLocationTags]?.country;

    const Image = () => {
        switch(props.processorManufacturer) {
            case 'intel':
                return intel;
            case 'ryzen':
                return ryzen;
            case 'epyc':
                return epyc;
        }
    };

    return (
        <div className={`${props.inStock ? 'filter-in-stock' : 'filter-out-of-stock'}`}>
            <div className="box server-box filter-type-none">
                <div className="row">
                    <div className="col col-server-details">
						<div className="block clearfix">
							<h3 className="inline-block pull-left"><span className="tooltip-top inline-block tooltip-country" data-tooltip={props.locationTags}><ReactCountryFlag countryCode={country} className="va-top mr-2 server-flag" style={{width:'60px',height:'45px'}} svg /></span> {props.processorText[0]}</h3>
							<div className="inline-block pull-left ml-3">
								<ul className="plan-labels-list">
									<li><i className="fa-regular fa-earth-asia va-mid mr-1"></i>{props.locationTags}</li>
									{
										props.labels !== undefined && props.labels.map(function(label) {
											return <li className={label.className}>
												{
													label.icon && <i className={`fa-regular ${label.icon} va-mid mr-1`}/>
												}
												{label.label}
											</li>
										})
									}
									{renderStockInfo(props.stockQTY)}
								</ul>
							</div>
						</div>
						<div className="row mt-4 pt-1">
							<div className="col col-cpu">
								<span className="font-12 opacity-50 block"><i className="fa-regular fa-microchip"></i> PROCESSOR</span>
								<img src={Image()} className="va-mid mr-2 server-cpu-make" />
								<strong>{props.processorText[1]}</strong> - {props.processorText[2]}
							</div>
							<div className="col col-ram">
								<span className="font-12 opacity-50 block"><i className="fa-regular fa-memory"></i> RAM</span>
								<strong>{props.ramText[0]}</strong> {props.ramText[1]}
							</div>
							<div className="col col-ssd">
								<span className="font-12 opacity-50 block"><i className="fa-regular fa-hard-drive"></i> STORAGE</span>
								<strong>{props.storageText[0]}</strong> {props.storageText[1]}
							</div>
							<div className="col col-transfer">
								<span className="font-12 opacity-50 block"><i className="fa-regular fa-globe"></i> TRANSFER</span>
								<strong>{props.transferText[0]}</strong> @ {props.transferSpeed[0]}
							</div>
						</div>
                    </div>
                    <div className="col col-server-price text-right ml-auto">
                        <span className="block font-14">Starting at</span>
                        <h3>
							{props.oldPrice && (
								<span className="server-sale-price-old">{props.oldPrice}</span>
							)}
							{props.monthlyPrice}<small className="font-16">/mo</small>
						</h3>
                        {
                            props.inStock ?
                            <a href={props.serverLink} className="button mt-3">
                                <i className="fa-regular fa-gear mr-1"/>
                                Configure
                            </a>
                            :
                            <a href="javascript:void(0)" className="button mt-3">
                                <i className="fa-regular fa-ban mr-1"/>
                                No Stock
                            </a>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
