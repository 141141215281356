import Container from '../layouts/Core/Container';

import ReactCountryFlag from "react-country-flag";

import { STANDARD_ROUTE_URIS } from '../routes/Router';
import Travel from '../layouts/Core/Travel';

 export default function AllLocationsPage() {
    return (
        <Container title='Streamline Servers - Locations' description='We have over 20 locations around the world with more locations on the way' className='page-all-locations'>
            <section className="sub-hero" id="hero">
                <div className="container">
                    <h1>Datacenter Locations</h1>
                    <h4 className="font-22 pt-1 green">We’re located all around the globe, 22 locations to be exact!</h4>
                    <p className="pb-4 pt-3 ml-0 mr-0 font-18 w-50">
                    Good wow helped got looked coffee is their somewhere, and something into as been achievements frequently to in those reaching.
                    </p>
                </div>
            </section>

            <section className="locations-list pt-0" id="all-locations-list">
                <div className="container">
                    <div className="block mb-5">
                    <h3>North America</h3>
                    <div className="row mt-n3">
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>

                    </div>
                    </div>

                    <div className="block mb-5 pt-5">
                    <h3>Europe</h3>
                    <div className="row mt-n3">
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                    </div>
                    </div>

                    <div className="block pt-5">
                    <h3>Oceania</h3>
                    <div className="row mt-n3">
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>
                        <div className="col col-md-6 col-lg-4">
                        <h5><ReactCountryFlag countryCode="US" className="va-top mr-2" style={{width:'1.2em',height:'1.2em'}} svg />Chicago CHI-01 Data Center</h5>
                        <p className="pb-2 pt-1">
                            1850 Springer Dr, Lombard, IL 60148, United States
                        </p>
                        <Travel to={STANDARD_ROUTE_URIS.LOCATION}>Details<i className="fa-regular fa-circle-chevron-right font-12 ml-1"></i></Travel>
                        </div>

                    </div>
                    </div>

                </div>
            </section>


            <section className="green-bg bg-cta-squares" id="cta">
                <div className="container">
                    <div className="row">
                    <div className="col col-lg-6">
                        <h2>Join Streamline Today</h2>
                        <strong className="block semibold pb-4 font-22">Experience the freedom of switching between hundreds of games on the fly, with one more random text here click.</strong>
                        <Travel to="#" className="button dark large mt-1">CREATE ACCOUNT <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></Travel>
                    </div>
                    <div className="col col-md-4 col-lg-5 ml-auto pt-4 ptfix">
                        <ul className="font-18 pt-1" id="support-methods-list">
                        <li className="py-2"><Travel to="#" className="white-link bold"><i className="fa-light fa-hard-drive font-20 va-mid mr-2"></i> +1 (888) 945-5920</Travel></li>
                        <li className="py-2"><Travel to="#" className="white-link bold"><i className="fa-light fa-hard-drive font-20 va-mid mr-2"></i> Live Chat Online</Travel></li>
                        <li className="py-2"><Travel to="#" className="white-link bold"><i className="fa-light fa-hard-drive font-20 va-mid mr-2"></i> sales@streamline-servers.net</Travel></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </section>
        </Container>
     );
 }