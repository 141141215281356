import React, { useEffect, useState } from 'react';
import { TrustPilot, Reviews } from '../layouts/TrustPilot';
import Container from '../layouts/Core/Container';
import WebHostingData from '../layouts/WebHostingData';
import { useTawkContext } from '../layouts/TawkContext';

import ReactCountryFlag from "react-country-flag";

import cpanel from '../assets/images/cpanel-logo.svg';
import cpanelHover from '../assets/images/cpanel-logo-hover.svg';
import litespeed from '../assets/images/litespeed-logo.svg';
import litespeedHover from '../assets/images/litespeed-logo-hover.svg';
import cloudlinux from '../assets/images/cloudlinux-logo.svg';
import cloudlinuxHover from '../assets/images/cloudlinux-logo-hover.svg';
import immunify from '../assets/images/immunify-logo.svg';
import immunifyHover from '../assets/images/immunify-logo-hover.svg';

import wordpress from '../assets/images/app-wordpress.svg';
import wordpressHover from '../assets/images/app-wordpress-hover.svg';
import joomla from '../assets/images/app-joomla.svg';
import joomlaHover from '../assets/images/app-joomla-hover.svg';
import magento from '../assets/images/app-magento.svg';
import magentoHover from '../assets/images/app-magento-hover.svg';
import prestashop from '../assets/images/app-prestashop.svg';
import prestashopHover from '../assets/images/app-prestashop-hover.svg';
import codeIgniter from '../assets/images/app-code-igniter.svg';
import codeIgniterHover from '../assets/images/app-code-igniter-hover.svg';
import mybb from '../assets/images/app-mybb.svg';
import mybbHover from '../assets/images/app-mybb-hover.svg';
import opencart from '../assets/images/app-opencart.svg';
import opencartHover from '../assets/images/app-opencart-hover.svg';
import laravel from '../assets/images/app-laravel.svg';
import laravelHover from '../assets/images/app-laravel-hover.svg';
import { Dropdown } from 'react-bootstrap';

// Function to shuffle array for Reviews
function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default function WebHostingPage() {
    const LOCATIONS = {
        AUSTRALIA: 'australia',
        AMERICA: 'america'
    };
    const SERVER_TYPES = {
        STANDARD: 'standard',
        BUSINESS: 'business',
        PERFORMANCE: 'performance'
    };

    const [location, setLocation] = useState(LOCATIONS.AUSTRALIA);
    const [serverType, setServerType] = useState(SERVER_TYPES.STANDARD);
	const [randomReviews, setRandomReviews] = useState<Array<typeof Reviews[number]>>([]);
	
	const { handleMaximize } = useTawkContext();
	
	useEffect(() => {
		const shuffledReviews = shuffleArray([...Reviews]);
		setRandomReviews(shuffledReviews);
	}, []);

    return (
        <Container title='Streamline Servers - Web Hosting' description='Expand your gaming community with high-performance web hosting! We use cPanel along Wordpress plugins to allow easy customization.' className='page-hosting'>
            <section className="text-center sub-hero pb-5" id="hero-dedicated">
                <div className="container">
                    <h1>Web Hosting</h1>
                    <p className="pb-2">
                    Expand your community with high-performance web hosting!
                    </p>
                </div>
                </section>

                <section className="pt-0" id="dedicated-plans">
                <div className="container">
                    <div className="filter-row hosting-filters pb-3 text-center">
                        <div className="row">
                            <div className="col col-sm-6 col-md-4 col-lg-6 m-order-1">
                                <strong className="font-12 block">Server Location</strong>
                                <Dropdown className="sik-dropdown">
                                    <Dropdown.Toggle variant="secondary">
                                        { location === LOCATIONS.AUSTRALIA && <><ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Sydney</> }
                                        { location === LOCATIONS.AMERICA && <><ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Los Angeles</> }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-dark">
										<p className="ml-1 mt-2 font-12 bold pb-1">Australia</p>
										<Dropdown.Item onClick={function() {setLocation(LOCATIONS.AUSTRALIA)}}>
                                        <ReactCountryFlag countryCode="AU" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Sydney
                                        </Dropdown.Item>
										<p className="ml-1 mt-2 font-12 bold pb-1">North America</p>
                                        <Dropdown.Item onClick={function() {setLocation(LOCATIONS.AMERICA);}}>
                                        <ReactCountryFlag countryCode="US" className="va-top mr-2 mb-1" style={{width:'1.2em',height:'1.2em'}} svg /> Los Angeles
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col col-md-8 col-lg-6 text-center m-order-3">
                                <strong className="font-12 block">Choose a Server Type</strong>
                                <ul className="lined-tabs filter-lined-tabs mt-n1" id="nav-tab" role="tablist">
                                    <li className="inline-block px-3">
                                        <a id="standard-tab" style={{cursor: 'pointer'}} role="tab" aria-controls="standard" aria-selected={`${serverType === SERVER_TYPES.STANDARD ? 'true' : 'false'}`} className={`${serverType === SERVER_TYPES.STANDARD && 'active'}`} onClick={() => setServerType(SERVER_TYPES.STANDARD)}>Standard</a>
                                    </li>
                                    <li className="inline-block px-3">
                                        <a id="business-tab" style={{cursor: 'pointer'}} role="tab" aria-controls="business" aria-selected={`${serverType === SERVER_TYPES.BUSINESS ? 'true' : 'false'}`} className={`${serverType === SERVER_TYPES.BUSINESS && 'active'}`} onClick={() => setServerType(SERVER_TYPES.BUSINESS)}>Business</a>
                                    </li>
                                    <li className="inline-block px-3">
                                        <a id="performance-tab" style={{cursor: 'pointer'}} role="tab" aria-controls="performance" aria-selected={`${serverType === SERVER_TYPES.PERFORMANCE ? 'true' : 'false'}`} className={`${serverType === SERVER_TYPES.PERFORMANCE && 'active'}`} onClick={() => setServerType(SERVER_TYPES.PERFORMANCE)}><i className="fa-light fa-fire-flame-curved mr-1"></i> Performance</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

					{Object.values(LOCATIONS).map(loc =>
						location === loc &&
						<div className="filtered-divs active">
							<div className="tab-content">
								{Object.values(SERVER_TYPES).map(type =>
									serverType === type &&
									<div className="tab-pane active" id={type}>
										<div className="padded-row-big mt-3 pt-3 ptfix text-center">
											<WebHostingData location={location} serverType={serverType} />
										</div>
									</div>
								)}
							</div>
						</div>
					)}

                </div>
                </section>

                <section className="features light-bg" id="cloud-features">
                <div className="container">
                    <div className="title">
                    <h2><small>What's Included?</small>Web Hosting Features</h2>
                    <p>
                        Our Web Hosting packages include the latest features and addons
                    </p>
                    </div>
                    <div className="row text-center mt-2 mtfix">
                    <div className="col col-md-6 col-lg-3 px-4">
                        <div className="icon-relative inline-block">
                            <div className="custom-logo">
								<img src={cpanel} className="block mx-auto mb-3 mt-3" />
								<img src={cpanelHover} className="block mx-auto mb-3 mt-3" />
                            </div>
                        </div>
                        <h4>cPanel & WHM</h4>
                        <p className="pb-0">
                        cPanel is a user-friendly web hosting control panel that allows customers to manage and configure their websites and server settings.
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-3 px-4">
                        <div className="icon-relative inline-block">
                            <div className="custom-logo">
								<img src={litespeed} className="block mx-auto mb-3 mt-3" />
								<img src={litespeedHover} className="block mx-auto mb-3 mt-3" />
                            </div>
                        </div>
                        <h4>Litespeed Web Server</h4>
                        <p className="pb-0">
                        LiteSpeed Web Server is a high-performance, efficient web server that improves website loading speed and handles increased traffic effectively.
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-3 px-4">
                        <div className="icon-relative inline-block">
                            <div className="custom-logo">
								<img src={cloudlinux} className="block mx-auto mb-3 mt-3" />
								<img src={cloudlinuxHover} className="block mx-auto mb-3 mt-3" />
                            </div>
                        </div>
                        <h4>Cloudlinux OS</h4>
                        <p className="pb-0">
                        CloudLinux is an operating system designed for shared web hosting, providing better security, stability, and resource management for users.
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-3 px-4">
                        <div className="icon-relative inline-block">
                            <div className="custom-logo">
								<img src={immunify} className="block mx-auto mb-3 mt-3" />
								<img src={immunifyHover} className="block mx-auto mb-3 mt-3" />
                            </div>
                        </div>
                        <h4>Immunify 360</h4>
                        <p className="pb-0">
                        Immunify 360 is a comprehensive security solution that protects websites from various cyber threats like malware, viruses, and attacks.
                        </p>
                    </div>
                    </div>
                </div>
                </section>


                <section id="one-click-apps">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-12 col-lg-5 pt-5 ptfix pr-5 prfix">
                        <h2><small>SOFTACULOUS APPS</small>1-Click App Installs</h2>
                        <p className="pt-2">
                        Softaculous is an auto-installer software integrated into our web hosting control panel, allowing users to easily install and manage various web applications and scripts.
                        </p>
                        <a href="#" className="button">Order Now</a>
                    </div>
                    <div className="col col-md-12 col-lg-6 ml-auto">
                        <div className="row text-center" id="softaculous-apps">
                        <div className="col col-md-3 col-lg-4 col-xl-3">
                            <div className="box">
                            <img src={wordpress} className="block mx-auto mb-3" />
                            <img src={wordpressHover} className="block mx-auto mb-3" />
                            <strong>WordPress</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-4 col-xl-3">
                            <div className="box">
                            <img src={joomla} className="block mx-auto mb-3" />
                            <img src={joomlaHover} className="block mx-auto mb-3" />
                            <strong>Joomla</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-4 col-xl-3">
                            <div className="box">
                            <img src={magento} className="block mx-auto mb-3" />
                            <img src={magentoHover} className="block mx-auto mb-3" />
                            <strong>Magento</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-4 col-xl-3">
                            <div className="box">
                            <img src={prestashop} className="block mx-auto mb-3" />
                            <img src={prestashopHover} className="block mx-auto mb-3" />
                            <strong>PrestaShop</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-4 col-xl-3">
                            <div className="box">
                            <img src={codeIgniter} className="block mx-auto mb-3" />
                            <img src={codeIgniterHover} className="block mx-auto mb-3" />
                            <strong>Code Igniter</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-4 col-xl-3">
                            <div className="box">
                            <img src={mybb} className="block mx-auto mb-3" />
                            <img src={mybbHover} className="block mx-auto mb-3" />
                            <strong>MyBB Forum</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-4 col-xl-3">
                            <div className="box">
                            <img src={opencart} className="block mx-auto mb-3" />
                            <img src={opencartHover} className="block mx-auto mb-3" />
                            <strong>OpenCart</strong>
                            </div>
                        </div>
                        <div className="col col-md-3 col-lg-4 col-xl-3">
                            <div className="box">
                            <img src={laravel} className="block mx-auto mb-3" />
                            <img src={laravelHover} className="block mx-auto mb-3" />
                            <strong>Laravel</strong>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <div className="container">
                <div className="line-gradient-divider"></div>
                </div>

                <section id="hosting-panel" className="mb-5">
                <div className="container">
                    <div className="title mb-n3">
                    <h2><small>WEB HOSTING CONTROL PANEL</small>Powered by cPanel</h2>
                    <p>
                        Empower Your Web Hosting with cPanel's Intuitive Control Panel
                    </p>
                    </div>
                    <div className="row">
                    <div className="col col-md-12 col-lg-6">
                        <img src={require("../assets/images/webhosting-panel-1.webp")} className="mt-5"/>
                    </div>
                    <div className="col col-md-12 col-lg-6 col-xl-5 ml-auto">
                        <div className="absolute-left-parent">
                        <div className="absolute-left">
                            <i className="fa-solid fa-browser va-mid absolute-left font-44 grad-behind-icon"></i>
                            <i className="fa-thin fa-browser va-mid absolute-left font-44 green-icon"></i>
                        </div>
                        <h4 className="pt-1">User-Friendly Interface</h4>
                        <p className="pb-0">
                            cPanel offers an intuitive and easy-to-navigate control panel, making website management accessible for users of all skill levels.
                        </p>
                        </div>
                        <div className="absolute-left-parent">
                        <div className="absolute-left">
                            <i className="fa-solid fa-rocket-launch va-mid absolute-left font-44 grad-behind-icon"></i>
                            <i className="fa-thin fa-rocket-launch va-mid absolute-left font-44 green-icon"></i>
                        </div>
                        <h4 className="pt-1">Versatile Functionality</h4>
                        <p className="pb-0">
                            cPanel provides a wide range of features and tools for tasks like website creation, email management, database administration, and more.
                        </p>
                        </div>
                        <div className="absolute-left-parent">
                        <div className="absolute-left">
                            <i className="fa-solid fa-circle-info va-mid absolute-left font-44 grad-behind-icon"></i>
                            <i className="fa-thin fa-circle-info va-mid absolute-left font-44 green-icon"></i>
                        </div>
                        <h4 className="pt-1">Reliable and Widely Supported</h4>
                        <p className="pb-0">
                            As a popular and widely used web hosting control panel, cPanel benefits from regular updates, extensive documentation, and a robust support community.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="py-0" id="cta-gradient-box">
                <div className="container">
                    <div className="cta-gradient-box cta-gradient-box-green">
                    <div className="row">
                        <div className="col">
                        <h2><small>24/7/365 SUPPORT</small>Support you can count on!</h2>
                        <p>
                            Our team is always here to help. Whether you are stuck on configuring your website or need help installing a app, we can help!
                        </p>
						<a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="button outline mt-1 mr-3">SUPPORT TICKET <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></a>
						<a href="javascript:void(0);" onClick={handleMaximize} className="button mt-1 dark">Sales Live Chat</a>
						</div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="light-bg text-center" id="testimonial-slider">
                <div className="container">
                    <div id="carouselTestimonialsControls" className="carousel slide" data-bs-ride="carousel">
						<div className="carousel-inner">
						  {randomReviews.map((review, index) => (
							<div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
							  <p className="font-22 pb-2">
								<span className="font-30">"</span>{review.Review}<span className="font-30">"</span>
							  </p>
							  <img src={TrustPilot.trustpilotStarsSlider} className="block mx-auto mb-3"/>
							  <h4 className="font-22">{review.Name}</h4>
							  <span className="block font-14 opacity-50 mt-n1 mb-4">www.streamline-servers.com</span>
							  <a href={TrustPilot.URL} className="white-link font-14 bold">Read more reviews on <img src={TrustPilot.trustpilotLogoGreenSmall} className="va-mid ml-1" /></a>
							</div>
						  ))}
						</div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselTestimonialsControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselTestimonialsControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
                </section>



                <section id="faq-single">
                <div className="container">
                    <div className="title text-left">
                    <h2><small>WEB HOSTING FAQ</small>Frequently Asked Questions</h2>
                    </div>
                    <div className="accordions" id="accordionFAQ">
                    <div className="row">
                        <div className="col col-md-12 col-lg-6">
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             What is web hosting for? 
                                        </button>
                                    </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Web hosting enables you to host your own website online. This could be for community forums, server status page or even a donation link.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Can I get a Domain with my web hosting?
                                        </button>
                                    </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Yes you can! When you sign up to a hosting package, the first thing you'll be asked is if you want to buy a new domain! 
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col col-md-12 col-lg-6">
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            What is Performance web hosting?
                                        </button>
                                    </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
								On these packages, we have enabled extra CPU performance, meaning that instead of only using 1 CPU core, your website has access to more CPU cores. This enables you to run high load scripts and allows smoother performance for your MySQL Database(s). 
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingSix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            I have a question about your web hosting services...
                                        </button>
                                    </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
								Please do not hesitate to ask us any questions!
								<br />
								Contact us via <a href="">Live Support</a>.
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

            <section className="green-bg bg-cta-squares" id="cta">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-8 col-lg-6">
                        <h2>Try our Web Hosting Today!</h2>
                        <strong className="block pb-4 font-22">Not sure if this is right for you? Contact Us!</strong>
                        <strong className="font-18 block pb-1">You can contact us at anytime</strong>
                        <ul className="cta-link-list">
                        <li className="inline-block pr-3">
                            <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="white-link"><i className="fa-solid fa-envelope font-16 mr-1"></i>Support Ticket</a>
                        </li>
                        <li className="inline-block pr-3">
                            <a href="javascript:void(0);" onClick={handleMaximize} className="white-link"><i className="fa-solid fa-comments font-16 mr-1"></i> Sales Live Chat</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col col-md-4 col-lg-6 text-center">
                        <a href="#" className="button large dark mt-5">Back to Top <i className="fa-sharp fa-solid fa-arrow-up-long ml-1"></i></a>
                    </div>
                    </div>
                </div>
            </section>
        </Container>
    );
}