import { createContext, useContext, useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export const TawkContext = createContext<{
  handleMaximize?: () => void;
  tawkMessengerRef?: React.MutableRefObject<any>;
}>({});

export function TawkProvider({ children }: { children: React.ReactNode }) {
  const tawkMessengerRef = useRef<any>(null);

  const handleMaximize = () => {
    tawkMessengerRef.current?.toggle();
  };

  return (
    <TawkContext.Provider value={{ handleMaximize, tawkMessengerRef }}>
      <TawkMessengerReact
        propertyId="5c5a2cb16cb1ff3c14cb44e9"
        widgetId="default"
        ref={tawkMessengerRef}
      />
      {children}
    </TawkContext.Provider>
  );
}

export function useTawkContext() {
  return useContext(TawkContext);
}
