import { useEffect } from 'react';
import HeroBanner from './HeroBanner';
import PageFoot from './PageFoot';
import PageHead from './PageHead';

interface IProps {
    title?: string;
    description?: string;
    children: any;
    className?: string;
	bodyImage?: string;
}

export default function Container( props: IProps ) {

    const addBodyClass = className => {
        className.split(' ').forEach(x => {
            document.body.classList.add(x);
        });
    }
    
    const removeBodyClass = className => {
        className.split(' ').forEach(x => {
            document.body.classList.remove(x);
        });
    };

    useEffect(
        () => {

			if(props.className) {
                addBodyClass(props.className);
            }

            return () => {
                if(props.className) {
                    removeBodyClass(props.className);
                }
            };
        },
        []
    );
	
	useEffect(
        () => {

			if(props.bodyImage) {
				const bgIMG = require("../../assets/images/games/" + props.bodyImage);
				document.body.style.backgroundImage = `url(${bgIMG})`;
			}
			return () => {
                if(props.bodyImage) {
                    document.body.style.backgroundImage = ``;
                }
            };
        },
        []
    );
    
    return (
        <div>
            <PageHead title={props.title ?? "Streamline Servers"} description={props.description ?? ""}/>
            <HeroBanner/>
            <div>
                {props.children}
            </div>
            <PageFoot/>
        </div>
    );

}
