import { TABS } from "../pages/GameServersInnerPage";

// --- NOTES ---
// name: has to match the WHMCS product (not case sensitive)
// key: is what the URL for the game server page will be, keep it simple and replace spaces with "-"

export const gamesData = [
	{
		key: 'satisfactory',
		name: 'Satisfactory',
		keywords: 'satisfactory open-world factory building',
		boxImage: 'gamebox-satisfactory.webp',
		logoImage: 'gamelogo-satisfactory.webp',
		backgroundImage: 'gamebg-satisfactory.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Satisfactory is a first-person open-world factory building game, where you take on the role of an engineer sent to an alien planet to build and automate factories to harvest resources and manufacture products for your employer.</br></br>With a vast, procedurally-generated world to explore, there are plenty of opportunities to mine, craft, and research new technologies as you work to expand your operations. Whether you want to build intricate supply chains or design towering structures, Satisfactory offers a unique and satisfying gameplay experience that will keep you coming back for more.',
		hook: 'Build and automate your base',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/526870/Satisfactory',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		earlyaccess: false,
		latest: true
	},
	{
		key: 'soulmask',
		name: 'Soulmask',
		keywords: 'survival crafting building multiplayer base open world sandbox thirdperson magic fantasy',
		boxImage: 'gamebox-soulmask.webp',
		logoImage: 'gamelogo-soulmask.webp',
		backgroundImage: 'gamebg-soulmask.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Soulmask is a sandbox game that prioritizes an authentic survival experience. As the "last one" blessed with the mysterious mask, players will grapple for survival in a primitive land steeped in mysterious faiths, carving out a path to ascendance.</br></br>Start from nothing, explore, build, recruit tribesmen to fortify your clan, and ultimately unravel the mysterious truths hidden behind the civilizations of this world.',
		hook: 'Explore and unveil the truths behind the enigmatic mask!',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/2646460/Soulmask/',
		youtubeLink: 'https://www.youtube.com/watch?v=UYYvLxK2FFg',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ],
		earlyaccess: true,
		latest: true
	},
	{
		key: 'enshrouded',
		name: 'Enshrouded',
		keywords: 'survival crafting building multiplayer base open world sandbox thirdperson magic fantasy',
		boxImage: 'gamebox-enshrouded.webp',
		logoImage: 'gamelogo-enshrouded.webp',
		backgroundImage: 'gamebg-enshrouded.webp',
		extraClassName: '',
		sortTags: '',
		description: 'In their greed for magical power, your ancestors unleashed a pestilence that consumed the world. Enshrouded is a game of survival, crafting, and Action RPG combat, set within a sprawling voxel-based continent. As you journey across the mountains and deserts of an open world, you are free to choose your path and shape your destiny.</br></br>Ignite the Ancient power of the Flame, and piece together the fragments of a story that unfolds below the surface.',
		hook: 'Can your save the realm of Embervale?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/1203620/Enshrouded/',
		youtubeLink: 'https://www.youtube.com/watch?v=BSSIH5L2liI',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ],
		earlyaccess: true,
		latest: true
	},
	{
		key: 'palworld',
		name: 'Palworld',
		keywords: 'survival crafting building multiplayer base open world sandbox shooter pets thirdperson shooter guns pokemon',
		boxImage: 'gamebox-palworld.webp',
		logoImage: 'gamelogo-palworld.webp',
		backgroundImage: 'gamebg-palworld.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Embark on a captivating journey in a world teeming with mysterious Pals. Live peacefully or confront a ruthless poaching syndicate, making tough choices for survival even if it means consuming your own Pals. Traverse land, sea, and sky by mounting Pals, build structures, farm, and automate tasks.</br></br>Explore dungeons, breed Pals with unique genetics, and engage in risky poaching endeavors. In multiplayer mode, team up with friends for adventures, battles, and Pal trading.',
		hook: 'Got to catch them all! ',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/1623730/Palworld/',
		youtubeLink: 'https://www.youtube.com/watch?v=IltMIJeK-1M',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ],
		earlyaccess: true,
		latest: true,
		trending: true,
		trendingIMG: 'trending-game-palworld.webp'
	},
	{
		key: 'the-front',
		name: 'The Front',
		keywords: 'survival crafting building multiplayer base open world sandbox shooter',
		boxImage: 'gamebox-thefront.webp',
		logoImage: 'gamelogo-thefront.webp',
		backgroundImage: 'gamebg-thefront.webp',
		extraClassName: '',
		sortTags: '',
		description: 'The Front takes place in a post-apocalyptic sandbox world populated by mutants and hostile factions. On this war-torn land, players will engage tyrannical imperial forces, exiles, thugs, and rebels in an epic battle that will determine the fate of humanity.</br></br>Team up with your friends, explore ruins and enemy areas, craft weapons and equipment, build bases and defend them with tower defense-style traps, upgrade tech, pilot tanks and helicopters, battle the elements, and contend with fearsome foes!',
		hook: 'Can you survive this post-apocalyptic world?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/2285150/The_Front/',
		youtubeLink: 'https://www.youtube.com/watch?v=Ep0yC9tHILs',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ],
		earlyaccess: true,
		latest: false
	},
	{
		key: 'counter-strike-2',
		name: 'Counter-Strike 2',
		keywords: 'counter-strike global offensive csgo multiplayer shooter cs2 counter-strike 2',
		boxImage: 'gamebox-counter-strike-2.webp',
		logoImage: 'gamelogo-counter-strike-2.webp',
		backgroundImage: 'gamebg-counter-strike-2.webp',
		extraClassName: '',
		sortTags: '',
		description: 'This team-based first-person shooter lets you choose between terrorists or counter-terrorists, as you battle it out in a range of game modes and maps.</br></br>With a wide variety of weapons and equipment to choose from, you\'ll need to work together with your team to complete objectives and emerge victorious. With its competitive gameplay and strong community, CS2 is a must-play for FPS fans. So gear up, communicate with your team, and get ready to engage in intense firefights!',
		hook: 'Choose your side and dominate the competition',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/730/CounterStrike_2/',
		youtubeLink: 'https://www.youtube.com/watch?v=nSE38xjMLqE',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'icarus',
		name: 'Icarus',
		keywords: 'icarus multiplayer-survival',
		boxImage: "gamebox-icarus.webp",
		logoImage: "gamelogo-icarus.webp",
		backgroundImage: "gamebg-icarus.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Icarus is a multiplayer survival game set in a harsh and unforgiving alien world. As a brave space explorer, you and your team must undertake dangerous missions to gather resources, build your base, and unlock new technologies to aid your survival.</br></br>But the planet\'s harsh weather, treacherous wildlife, and deadly alien organisms will test your skills and your wits at every turn. With stunning graphics and immersive gameplay, Icarus offers a thrilling survival experience like no other. Will you be able to endure the challenges of this alien world and emerge victorious?',
		hook: 'Play Icarus today and find out',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/1149460/ICARUS/',
		youtubeLink: 'https://www.youtube.com/watch?v=mwZ_hVuPJk0',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'v-rising',
		name: 'V Rising',
		keywords: 'vrising vampire vampires',
		boxImage: 'gamebox-vrising.webp',
		logoImage: 'gamelogo-vrising.webp',
		backgroundImage: 'gamebg-vrising.webp',
		extraClassName: '',
		sortTags: 'new survival',
		description: 'Awaken as a weakened vampire after centuries of slumber. Hunt for blood in nearby settlements to regain your strength while hiding from the scorching sun to survive.</br></br>Explore a vast open-world together with friends or hunt solo as you pillage villages, raid bandit camps, and traverse the territories of supernatural beasts. Rebuild your castle and convert humans into loyal servants in a quest to raise your vampire empire. Make allies online and wage war against other players in a world of conflict.',
		hook: 'Will you become the next Dracula?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/1604030/V_Rising/',
		youtubeLink: 'https://www.youtube.com/watch?v=fjjrmnX2FfI',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'minecraft',
		name: 'Minecraft',
		keywords: 'minecraft sandbox craft mining',
		boxImage: 'gamebox-minecraft.webp',
		logoImage: 'gamelogo-minecraft.webp',
		backgroundImage: 'gamebg-minecraft.webp',
		extraClassName: '',
		sortTags: 'new survival sandbox',
		description: 'Survival is key as you strive to thrive in this dynamic world. Grab your trusty pickaxe and team up with friends for epic adventures that will test your skills and teamwork.</br></br>However, if combat isn’t your cup of tea, fear not! In Creative Mode, the possibilities are endless as you unleash your imagination and build whatever your heart desires. Join forces with friends to create monumental structures and push the boundaries of your creativity in a cooperative building frenzy.',
		hook: 'What will you create?',
		purchaseOn: 'windows',
		storeLink: 'https://www.minecraft.net/en-us/store/minecraft-java-bedrock-edition-pc',
		youtubeLink: 'https://www.youtube.com/watch?v=Rla3FUlxJdE',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'no-one-survived',
		name: 'No One Survived',
		keywords: 'zombies noonesurvived',
		boxImage: 'gamebox-no-one-survived.webp',
		logoImage: 'gamelogo-no-one-survived.webp',
		backgroundImage: 'gamebg-no-one-survived.webp',
		extraClassName: '',
		sortTags: 'new survival openworld',
		description: 'In No One Survived, players take on the role of survivors trying to escape an abandoned research facility overrun by genetically modified monsters. Fight for survival against hordes of creatures and team up with other players to increase your chances of making it out alive.</br></br>Gather resources, build fortifications, and upgrade weapons in this thrilling cooperative multiplayer game. But be careful: every decision could mean the difference between life and death. Can you survive the horrors of the research facility and make it out alive? Join the fight today.',
		hook: 'Survive together or die alone',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/1963370/No_One_Survived',
		youtubeLink: 'https://www.youtube.com/watch?v=W94Zy39Wlbg',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ],
		earlyaccess: true,
		latest: false
	},
	{
		key: 'skyrim-together-reborn',
		name: 'Skyrim Together Reborn',
		keywords: 'skyrim skyrimtogether reborn',
		boxImage: 'gamebox-skyrim-together.webp',
		logoImage: 'gamelogo-skyrim-together.webp',
		backgroundImage: 'gamebg-skyrim-together.webp',
		extraClassName: '',
		sortTags: 'new survival openworld',
		description: 'Experience an epic adventure in the land of Tamriel with Elder Scrolls V: Skyrim with friends! Explore a vast and immersive open world filled with dragons, ancient ruins, and countless quests. Choose your path as you customize your character’s skills and abilities, and discover the power of the Thu’um - the dragon language.</br></br>Whether you choose to follow the main quest or embark on your own adventures, Skyrim offers endless possibilities for exploration and adventure.',
		hook: 'Unleash your inner dragonborn',
		purchaseOn: 'steam',
		storeLink: 'https://www.nexusmods.com/skyrimspecialedition/mods/69993',
		youtubeLink: 'https://www.youtube.com/watch?v=7ogIhWUxPyE',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ],
		latest: false
	},
	{
		key: 'valheim',
		name: 'Valheim',
		keywords: 'valheim',
		boxImage: 'gamebox-valheim.webp',
		logoImage: 'gamelogo-valheim.webp',
		backgroundImage: 'gamebg-valheim.webp',
		extraClassName: '',
		sortTags: 'popular survival openworld',
		description: 'Embark on an epic Norse-inspired adventure in Valheim. Set in a procedurally generated world, this survival game combines exploration, crafting, and combat in a stunning open-world environment. Gather resources, build mighty fortresses, and face off against powerful creatures.</br></br>With a seamless blend of cooperative multiplayer and challenging solo play, Valheim offers a truly immersive and rewarding experience. Discover hidden treasures, unravel ancient mysteries, and forge your own Viking saga in the untamed wilderness.',
		hook: 'Will you rise as a legendary hero?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/892970/Valheim/',
		youtubeLink: 'https://www.youtube.com/watch?v=cZOuBjvETR8',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false,
		trending: true,
		trendingIMG: 'trending-game-valheim.webp'
	},
	{
		key: 'arma3',
		name: 'ARMA 3',
		keywords: 'arma arma3',
		boxImage: 'gamebox-arma3.webp',
		logoImage: 'gamelogo-arma3.webp',
		backgroundImage: 'gamebg-arma3.webp',
		extraClassName: '',
		sortTags: 'popular fps openworld',
		description: 'Enter the world of modern military simulation with ARMA 3. With stunning graphics and immersive gameplay, ARMA 3 offers an unparalleled gaming experience. Join a team and engage in large-scale battles featuring realistic weapons, vehicles, and tactics.</br></br>Use strategy and skill to complete missions and advance your rank. Create your own content using the built-in editor, or join the thriving modding community to expand your ARMA 3 experience even further.',
		hook: 'Experience modern warfare like never before in a realistic military simulation',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/107410/Arma_3/',
		youtubeLink: 'https://www.youtube.com/watch?v=M1YBZUxMX8g',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false,
		trending: true,
		trendingIMG: 'trending-game-arma3.webp'
	},
	{
		key: 'ark',
		name: 'Ark: Survival Evolved',
		keywords: 'ark survival evolved',
		boxImage: 'gamebox-ark-survival-evolved.webp',
		logoImage: 'gamelogo-ark-survival-evolved.webp',
		backgroundImage: 'gamebg-ark-survival-evolved.webp',
		extraClassName: '',
		sortTags: 'popular fps openworld survival',
		description: 'Step into a prehistoric world filled with danger and adventure in Ark: Survival Evolved. Gather resources, craft tools, and build your own base while taming dinosaurs and other ancient creatures.</br></br>Explore the vast open world, fight against other players or form alliances to survive in this harsh environment. With stunning graphics and immersive gameplay, Ark: Survival Evolved will take you on an unforgettable journey through time.',
		hook: 'Unleash your primal instincts and conquer a prehistoric world',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/346110/ARK_Survival_Evolved/',
		youtubeLink: 'https://www.youtube.com/watch?v=FW9vsrPWujI',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false,
		// trending: true,
		// trendingIMG: 'trending-game-ark-survival-evolved.webp'
	},
	{
		key: 'rust',
		name: 'Rust',
		keywords: 'rust facepunch build survival',
		boxImage: 'gamebox-rust.webp',
		logoImage: 'gamelogo-rust.webp',
		backgroundImage: 'gamebg-rust.webp',
		extraClassName: '',
		sortTags: 'popular fps openworld survival sandbox',
		description: 'Rust is an intense multiplayer survival game that will keep you on your toes at every turn. From battling other players to fending off wildlife and surviving the elements, every moment in Rust is a fight for survival. Build your base, scavenge for resources, and craft weapons and tools to ensure your survival in this unforgiving world.</br></br>With a massive open world and endless possibilities for adventure and danger, Rust is a game that will keep you hooked for hours. Are you ready to take on the challenge and see if you have what it takes to survive in Rust?',
		hook: 'Outsmart your enemies',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/agecheck/app/252490/',
		youtubeLink: 'https://www.youtube.com/watch?v=LGcECozNXEw',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false,
		trending: true,
		trendingIMG: 'trending-game-rust.webp'
	},
	{
		key: '7-days-to-die',
		name: '7 Days to Die',
		keywords: '7days to die survival horror zombies post-apocalyptic',
		boxImage: 'gamebox-7days-to-die.webp',
		logoImage: 'gamelogo-7days-to-die.webp',
		backgroundImage: 'gamebg-7days-to-die.webp',
		extraClassName: '',
		sortTags: '',
		description: '7 Days to Die is a survival horror game that takes place in a post-apocalyptic world infested with zombies. Players must scavenge for resources, craft weapons, and build fortifications to withstand the relentless attacks of the undead.</br></br>With a vast open world to explore and a range of different playstyles to suit every type of gamer, 7 Days to Die is a thrilling and immersive survival experience that will keep you coming back for more.',
		hook: 'Who will you survive the horde with?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/251570/7_Days_to_Die/',
		youtubeLink: 'https://www.youtube.com/watch?v=lmPHz0dwBj4',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		earlyaccess: true,
		latest: false
	},
	{
		key: 'atlas',
		name: 'Atlas',
		keywords: 'atlas survival exploration pirate open-world sandbox',
		boxImage: 'https://cdn2.steamgriddb.com/grid/c45336d8b21a80da28d793136fbf00b8.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Embark on an epic adventure of survival and exploration in ATLAS. Set in a vast open-world sandbox environment, this game offers a pirate-themed experience where players can sail the high seas, discover uncharted islands, and engage in thrilling naval battles.</br></br>With a dynamic ecosystem, players must hunt for resources, construct their own bases, and form alliances to dominate the vast and treacherous world. Uncover hidden treasures, tame wild creatures, and build mighty ships to become the ultimate pirate in ATLAS.',
		hook: 'Sail the seas, conquer the world, and become a legendary pirate',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/834910/ATLAS/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'arma2-epoch',
		name: 'ARMA 2: DayZ Epoch',
		keywords: 'arma 2 epoch survival military simulation post-apocalyptic sandbox',
		boxImage: 'gamebox-arma2-epoch.webp',
		logoImage: 'gamelogo-arma2-epoch.webp',
		backgroundImage: 'gamebg-arma2-epoch.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Experience a unique blend of survival and military simulation gameplay in ARMA 2: Epoch. Set in a post-apocalyptic world, this mod offers an open-world sandbox environment where players must scavenge for resources, build their own bases, and defend against hostile factions while fighting for survival.</br></br>With an extensive crafting system, a wide variety of vehicles and weapons, and a dynamic economy, ARMA 2: Epoch provides an immersive and challenging experience like no other.',
		hook: 'Survive against all odds in a post-apocalyptic sandbox',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/33900/Arma_2/',
		youtubeLink: 'https://www.youtube.com/watch?v=UgXq6z6-644',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'arma3-headless',
		name: 'ARMA 3: Headless Client',
		keywords: 'arma 3 headless client server performance ai processing',
		boxImage: 'gamebox-arma3-headless.webp',
		logoImage: 'gamelogo-arma3.webp',
		backgroundImage: 'gamebg-arma3-headless.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Experience unparalleled performance and scalability with ARMA 3 Headless Client. This powerful mod allows server administrators to offload AI processing to a dedicated server, freeing up resources for better server performance and reducing lag.</br></br>With easy installation and configuration, ARMA 3 Headless Client is a must-have for any ARMA 3 server owner who wants to provide the best possible gaming experience to their players.',
		hook: 'Maximize your server performance with ARMA 3 Headless Client',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/107410/Arma_3/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'arma-reforger',
		name: 'ARMA Reforger',
		keywords: 'arma reforger cold war combat realistic',
		boxImage: 'gamebox-arma-reforger.webp',
		logoImage: 'gamelogo-arma-reforger.webp',
		backgroundImage: 'gamebg-arma-reforger.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Take part in a realistic Cold War conflict with ARMA Reforger. Set in Germany during the 1980s, this mod allows players to experience the tension and intensity of a war between NATO and the Warsaw Pact.</br></br>Choose your side and engage in large-scale battles featuring accurate weapons, vehicles, and tactics. With challenging gameplay and a wealth of historical detail, ARMA Reforger provides a unique and immersive gaming experience.',
		hook: 'Experience the gritty intensity of Cold War combat with this realistic and immersive mod',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/1874880/Arma_Reforger/',
		youtubeLink: 'https://www.youtube.com/watch?v=AIervYhiEZ8',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'assetto-corsa',
		name: 'Assetto Corsa',
		keywords: 'assetto corsa realistic racing cars tracks',
		boxImage: 'gamebox-assetto-corsa.webp',
		logoImage: 'gamelogo-assetto-corsa.webp',
		backgroundImage: 'gamebg-assetto-corsa.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Get ready to experience the thrill of realistic racing with Assetto Corsa. This game features stunningly detailed cars and tracks, providing an immersive driving experience. Whether you\'re a seasoned racing enthusiast or a casual gamer, Assetto Corsa has something for everyone. With customizable settings, you can fine-tune your driving experience to match your preferences. So get behind the wheel, rev up your engine, and take on the competition in the ultimate racing simulation.</br></br>Feel the adrenaline rush of the racetrack like never before.',
		hook: 'Feel the adrenaline rush of the racetrack like never before',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/244210/Assetto_Corsa/',
		youtubeLink: 'https://www.youtube.com/watch?v=JfJNvlFa9TE',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'avorion',
		name: 'Avorion',
		keywords: 'avorion sandbox exploration mining building',
		boxImage: 'gamebox-avorion.webp',
		logoImage: 'gamelogo-avorion.webp',
		backgroundImage: 'gamebg-avorion.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Embark on a journey through the vast and dangerous galaxy of Avorion, where you\'ll mine, trade, and build your way to success. This sandbox-style game lets you explore a universe full of possibilities as you gather resources, design your own ships, and battle enemy factions.</br></br>With endless procedurally generated galaxies to explore, the adventure never ends in Avorion. So gear up, blast off, and let your imagination run wild in this thrilling sci-fi adventure.',
		hook: 'Forge your own path in a limitless universe',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/445220/Avorion/',
		youtubeLink: 'https://www.youtube.com/watch?v=ZLQMTILp8H0',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'battalion-1944',
		name: 'Battalion 1944',
		keywords: 'battalion 1944 world war ii shooter competitive',
		boxImage: 'gamebox-battalion-1944.webp',
		logoImage: 'gamelogo-battalion-1944.webp',
		backgroundImage: 'gamebg-battalion-1944.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Experience the intensity of World War II combat in Battalion 1944. This first-person shooter is set in the heart of the war, featuring realistic weapons and maps inspired by historic battles. With skill-based gameplay, you\'ll need to rely on your tactics and strategy to outmaneuver the enemy and secure victory.</br></br>Choose from a variety of game modes, including classic competitive, capture the flag, and more. So grab your weapon, take aim, and lead your team to victory in this authentic and thrilling war game.',
		hook: 'Enter the fray and fight for victory in the ultimate World War II shooter',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/489940/BATTALION_Legacy/',
		youtubeLink: 'https://www.youtube.com/watch?v=-rFPCblqnEA',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'black-mesa',
		name: 'Black Mesa',
		keywords: 'black mesa half-life remake',
		boxImage: 'gamebox-black-mesa.webp',
		logoImage: 'gamelogo-black-mesa.webp',
		backgroundImage: 'gamebg-black-mesa.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Step back into the world of Half-Life with Black Mesa, the critically acclaimed remake of the classic first-person shooter. Featuring stunning graphics, revamped sound design, and updated gameplay mechanics, Black Mesa puts you back in the shoes of Gordon Freeman as he battles his way through the infamous Black Mesa Research Facility.</br></br>With improved AI, physics, and a host of new features, this beloved game has been elevated to new heights. So suit up, grab your crowbar, and prepare to relive the iconic story of Half-Life in a whole new way.',
		hook: 'Relive the legend of Half-Life in stunning new detail',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/362890/Black_Mesa/',
		youtubeLink: 'https://www.youtube.com/watch?v=V7vDsarvyoc',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'blackwake',
		name: 'Blackwake',
		keywords: 'blackwake naval combat multiplayer ships',
		boxImage: 'gamebox-blackwake.webp',
		logoImage: 'gamelogo-blackwake.webp',
		backgroundImage: 'gamebg-blackwake.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Set sail on the high seas in Blackwake, the multiplayer naval combat game that puts you at the helm of your own ship. Coordinate with your crew and work together to navigate treacherous waters and engage in epic ship-to-ship battles.</br></br>With a range of customizable ships and weapons, you can outfit your vessel to suit your playstyle and dominate the competition. Whether you\'re a seasoned seafarer or a newcomer to the genre, Blackwake offers a thrilling and immersive experience that will keep you coming back for more.',
		hook: 'Master the seas and command your own ship in epic naval battles',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/420290/Blackwake/',
		youtubeLink: 'https://www.youtube.com/watch?v=ssqeD5Ryito',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'call-of-duty-4-modern-warfare',
		name: 'Call of Duty 4: Modern Warfare',
		keywords: 'call of duty 4 modern warfare multiplayer shooter',
		boxImage: 'gamebox-call-of-duty-4-mw.webp',
		logoImage: 'gamelogo-call-of-duty-4-mw.webp',
		backgroundImage: 'gamebg-call-of-duty-4-mw.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Take on the world\'s best in the adrenaline-fueled multiplayer of Call of Duty 4: Modern Warfare. Featuring fast-paced, tactical gameplay and a range of customizable loadouts, this game has become a staple of the competitive shooter genre.</br></br>With a variety of game modes and maps, every match is a new and thrilling experience. So grab your weapon, join a squad, and prove your worth on the virtual battlefield.',
		hook: 'Join the fight in the ultimate modern warfare experience',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/7940/Call_of_Duty_4_Modern_Warfare_2007/',
		youtubeLink: 'https://www.youtube.com/watch?v=LhuIjNSg7Gg',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'call-of-duty-modern-warfare-3',
		name: 'Call of Duty: Modern Warfare 3',
		keywords: 'call of duty modern warfare 3 multiplayer shooter',
		boxImage: 'gamebox-call-of-duty-modern-warfare-3.webp',
		logoImage: 'gamelogo-call-of-duty-modern-warfare-3.webp',
		backgroundImage: 'gamebg-call-of-duty-modern-warfare-3.webp',
		extraClassName: '',
		sortTags: '',
		description: 'This blockbuster first-person shooter takes you on a global mission to stop a full-scale war, featuring intense missions in locations around the world.</br></br>With fast-paced gameplay, high-octane action, and a range of customizable loadouts, this game has become a fan favorite. Call of Duty: Modern Warfare 3 offers a thrilling and immersive experience. So grab your weapon, join the fight, and save the world.',
		hook: 'Join the fight and save the world in the ultimate Modern Warfare experience',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/115300/Call_of_Duty_Modern_Warfare_3/',
		youtubeLink: 'https://www.youtube.com/watch?v=1xjCdN_rWCE&ab_channel=CallofDuty',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'chivalry-medieval-warfare',
		name: 'Chivalry: Medieval Warfare',
		keywords: 'chivalry medieval warfare medieval combat multiplayer',
		boxImage: 'gamebox-chivalry-medieval-warfare.webp',
		logoImage: 'gamelogo-chivalry-medieval-warfare.webp',
		backgroundImage: 'gamebg-chivalry-medieval-warfare.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Step into the world of medieval combat with Chivalry: Medieval Warfare. This first-person slasher game lets you take on the role of a knight, vanguard, archer, or other medieval warrior, as you battle your way through a range of game modes and maps.</br></br>With realistic swordplay mechanics, a variety of weapons and armor, and a deep progression system, Chivalry offers an immersive and satisfying experience. Whether you prefer to charge into battle or strike from the shadows, this game has something for everyone. So don your armor, sharpen your blade, and prepare for war!',
		hook: 'Unleash your inner warrior and dominate the battlefield',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/219640/Chivalry_Medieval_Warfare/',
		youtubeLink: 'https://www.youtube.com/watch?v=tUT0RTrNTW0&ab_channel=GAME',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'citadel-forged-with-fire',
		name: 'Citadel: Forged with Fire',
		keywords: 'citadel forged with fire sandbox exploration magic',
		boxImage: 'gamebox-citadel-forged-with-fire.webp',
		logoImage: 'gamelogo-citadel-forged-with-fire.webp',
		backgroundImage: 'gamebg-citadel-forged-with-fire.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Enter the magical world of Citadel: Forged with Fire, a massive online sandbox RPG that lets you explore a vast and immersive world filled with mythical creatures and magic. Craft your own spells, build towering castles, tame mythical beasts, and compete with other players in epic battles.</br></br>With a range of customizable classes and a deep progression system, Citadel offers a unique and thrilling gameplay experience. So gather your friends, explore the world, and become the ultimate wizard!',
		hook: 'Harness the power of magic and explore a fantastical world',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/487120/Citadel_Forged_with_Fire/',
		youtubeLink: 'https://www.youtube.com/watch?v=ZUObC4c9eQE&ab_channel=Citadel%3AForgedwithFire',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'conan-exiles',
		name: 'Conan Exiles',
		keywords: 'conan exiles survival open-world building',
		boxImage: 'gamebox-conan-exiles.webp',
		logoImage: 'gamelogo-conan-exiles.webp',
		backgroundImage: 'gamebg-conan-exiles.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Survive, build, and dominate in the savage world of Conan Exiles. This open-world survival game takes you on a journey through the brutal and unforgiving lands of the Exiled Lands. Gather resources, craft tools and weapons, and build your own kingdom from the ground up.</br></br>With a range of dangerous creatures and hostile players to contend with, every day is a struggle for survival. But with perseverance and cunning, you can rise to become the ultimate conqueror. So sharpen your blade, gather your allies, and enter the world of Conan Exiles.',
		hook: 'Survive, build, and dominate in this savage world',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/440900/Conan_Exiles/',
		youtubeLink: 'https://www.youtube.com/watch?v=mzubhJpbUK8&ab_channel=Funcom',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'contagion',
		name: 'Contagion',
		keywords: 'contagion zombie apocalypse survival shooter',
		boxImage: 'gamebox-contagion.webp',
		logoImage: 'gamelogo-contagion.webp',
		backgroundImage: 'gamebg-contagion.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Survive the zombie apocalypse in Contagion, a first-person shooter that pits you against hordes of undead monsters. With a range of game modes and maps, you\'ll need to use your wits and your weapons to stay alive in this unforgiving world.</br></br>Whether you prefer to play solo or team up with friends, Contagion offers a thrilling and immersive experience. With realistic graphics and intense gameplay, this game is sure to keep you on the edge of your seat. So lock and load, and get ready to fight for survival!',
		hook: 'Survive the undead and fight for survival',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/238430/Contagion/',
		youtubeLink: 'https://www.youtube.com/watch?v=OqlExlMrCq4&ab_channel=MonochromeGames',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 's-n-box',
		name: 'S&Box',
		keywords: 'garrysmod sandbox garrys mod garrymod snbox sbox',
		boxImage: "gamebox-snbox.webp",
		logoImage: "gamelogo-snbox.webp",
		backgroundImage: "gamebg-snbox.webp",
		extraClassName: '',
		sortTags: '',
		description: 'The sequal to the popular game Garry\'s Mod comes S&Box, from Facepunch Studios.</br></br>We\'re eagerly anticipating the release of the exciting game \'S & Box\' by Facepunch Studios, and as soon as it becomes available, we\'ll be ready to host it and provide an incredible hosting experience to our customers.',
		hook: 'Note: S&Box is in very early development',
		purchaseOn: 'steam',
		storeLink: 'https://sbox.facepunch.com/news',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ],
		earlyaccess: true,
		latest: false
	},
	{
		key: 'counter-strike-2-war-server',
		name: 'Counter-Strike 2: War Server',
		keywords: 'counter-strike global offensive cs go shooter multiplayer war cs2',
		boxImage: 'gamebox-counter-strike-2-war.webp',
		logoImage: 'gamelogo-counter-strike-2.webp',
		backgroundImage: 'gamebg-counter-strike-2.webp',
		extraClassName: '',
		sortTags: '',
		description: 'This team-based first-person shooter lets you choose between terrorists or counter-terrorists, as you battle it out in a range of game modes and maps.</br></br>With a wide variety of weapons and equipment to choose from, you\'ll need to work together with your team to complete objectives and emerge victorious. With its competitive gameplay and strong community, CS2 is a must-play for FPS fans. So gear up, communicate with your team, and get ready to engage in intense firefights!',
		hook: 'CS2 War Server is a restricted 12 slot server type.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/730/CounterStrike_2/',
		youtubeLink: 'https://www.youtube.com/watch?v=nSE38xjMLqE',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'counter-strike-global-offensive',
		name: 'Counter-Strike: Global Offensive',
		keywords: 'counter-strike global offensive csgo multiplayer shooter',
		boxImage: 'gamebox-counter-strike-global-offensive.webp',
		logoImage: 'gamelogo-counter-strike-global-offensive.webp',
		backgroundImage: 'gamebg-counter-strike-global-offensive.webp',
		extraClassName: '',
		sortTags: '',
		description: 'This team-based first-person shooter lets you choose between terrorists or counter-terrorists, as you battle it out in a range of game modes and maps.</br></br>With a wide variety of weapons and equipment to choose from, you\'ll need to work together with your team to complete objectives and emerge victorious. With its competitive gameplay and strong community, CS:GO is a must-play for FPS fans. So gear up, communicate with your team, and get ready to engage in intense firefights!',
		hook: 'Choose your side and dominate the competition',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/730/CounterStrike_Global_Offensive/',
		youtubeLink: 'https://www.youtube.com/watch?v=edYCtaNueQY&ab_channel=Valve',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'counter-strike-go-war-server',
		name: 'Counter-Strike: GO War Server',
		keywords: 'counter-strike global offensive cs go shooter multiplayer war',
		boxImage: 'gamebox-counter-strike-go-war.webp',
		logoImage: 'gamelogo-counter-strike-global-offensive.webp',
		backgroundImage: 'gamebg-counter-strike-global-offensive.webp',
		extraClassName: '',
		sortTags: '',
		description: 'This team-based first-person shooter lets you choose between terrorists or counter-terrorists, as you battle it out in a range of game modes and maps.</br></br>With a wide variety of weapons and equipment to choose from, you\'ll need to work together with your team to complete objectives and emerge victorious. With its competitive gameplay and strong community, CS:GO is a must-play for FPS fans. So gear up, communicate with your team, and get ready to engage in intense firefights!',
		hook: 'CSGO War Server is a restricted 12 slot server type.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/730/CounterStrike_Global_Offensive/',
		youtubeLink: 'https://www.youtube.com/watch?v=edYCtaNueQY&ab_channel=Valve',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'counter-strike-1-6',
		name: 'Counter-Strike 1.6',
		keywords: 'counter-strike 1.6 classic shooter multiplayer',
		boxImage: 'gamebox-counter-strike-1-6.webp',
		logoImage: 'gamelogo-counter-strike-1-6.webp',
		backgroundImage: 'gamebg-counter-strike-1-6.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Experience the classic first-person shooter that started it all with Counter-Strike 1.6. Join a team of terrorists or counter-terrorists, and engage in intense tactical combat across a range of maps and game modes.</br></br>With a wide variety of weapons and equipment at your disposal, you\'ll need to work together with your team to complete objectives and emerge victorious. With its fast-paced gameplay and intense firefights, Counter-Strike 1.6 is a timeless classic that\'s still played by millions today. So gear up, communicate with your team, and get ready for some adrenaline-pumping action!',
		hook: 'Join the fight in this legendary shooter',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/10/CounterStrike/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'counter-strike-source',
		name: 'Counter-Strike: Source',
		keywords: 'counter-strike source shooter multiplayer',
		boxImage: 'gamebox-counter-strike-source.webp',
		logoImage: 'gamelogo-counter-strike-source.webp',
		backgroundImage: 'gamebg-counter-strike-source.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Enter the next chapter of the iconic Counter-Strike series with Counter-Strike: Source. With updated graphics and enhanced physics, this classic first-person shooter offers a fresh take on the tactical combat gameplay that made the series famous. Join a team of terrorists or counter-terrorists, and engage in thrilling firefights across a range of maps and game modes.</br></br>With a vast selection of weapons and equipment to choose from, you\'ll need to use strategy and teamwork to outmaneuver your opponents and complete your objectives. So get ready to experience the thrill of combat like never before in Counter-Strike: Source.',
		hook: 'Enter the next chapter of the legendary shooter series',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/240/CounterStrike_Source/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'dark-and-light',
		name: 'Dark and Light',
		keywords: 'dark and light sandbox exploration magic survival',
		boxImage: 'gamebox-dark-and-light.webp',
		logoImage: 'gamelogo-dark-and-light.webp',
		backgroundImage: 'gamebg-dark-and-light.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Step into a world of magic and mystery with Dark and Light. This massive sandbox MMORPG invites you to explore a vast open world filled with danger and adventure. From battling fierce creatures to mastering powerful spells,</br></br> Dark and Light offers a variety of gameplay experiences that will keep you engaged for hours on end. Join a tribe and work together to build epic fortresses, tame magical beasts, and explore the unknown. With endless possibilities and an active community, Dark and Light is the perfect game for anyone looking for a new adventure.',
		hook: 'Experience a world of magic and adventure the ultimate sandbox MMORPG',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/529180/Dark_and_Light/',
		youtubeLink: 'https://www.youtube.com/watch?v=Dj2dlDS_s68',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'day-of-defeat-1-3',
		name: 'Day of Defeat 1.3',
		keywords: 'day of defeat 1.3 world war II combat multiplayer',
		boxImage: 'gamebox-day-of-defeat-1-3.webp',
		logoImage: 'gamelogo-day-of-defeat-1-3.webp',
		backgroundImage: 'gamebg-day-of-defeat-1-3.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Join the battlefields of World War II with Day of Defeat 1.3. This classic first-person shooter offers intense multiplayer combat across a variety of maps and game modes. Choose to fight as a member of the Allied or Axis forces and work together with your team to complete objectives and gain victory.</br></br>With a range of weapons and equipment at your disposal, you\'ll need to use tactics and teamwork to outmaneuver your opponents and claim victory. With fast-paced gameplay and authentic period details, Day of Defeat 1.3 is a timeless classic that\'s still played by thousands today.',
		hook: 'Experience the intensity and authenticity of World War II combat',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/30/Day_of_Defeat/',
		youtubeLink: 'https://www.youtube.com/watch?v=AqKQyT2KPpE',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'day-of-defeat-source',
		name: 'Day of Defeat: Source',
		keywords: 'day of defeat source world war II combat multiplayer',
		boxImage: 'gamebox-day-of-defeat-source.webp',
		logoImage: 'gamelogo-day-of-defeat-source.webp',
		backgroundImage: 'gamebg-day-of-defeat-source.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Experience the gritty intensity of World War II combat in Day of Defeat: Source. This classic first-person shooter features fast-paced multiplayer gameplay across a range of maps and game modes.</br></br>Choose to fight as a member of the Allied or Axis forces and work together with your team to complete objectives and gain victory. So grab your weapons and join the fight in this timeless classic that\'s still played by thousands today.',
		hook: 'Experience the upgraded intensity and authenticity of World War II combat',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/300/Day_of_Defeat_Source/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'day-of-infamy',
		name: 'Day of Infamy',
		keywords: 'day of infamy world war II combat tactical shooter',
		boxImage: 'gamebox-day-of-infamy.webp',
		logoImage: 'gamelogo-day-of-infamy.webp',
		backgroundImage: 'gamebg-day-of-infamy.webp',
		extraClassName: '',
		sortTags: '',
		description: 'This tactical shooter offers fast-paced multiplayer combat across a variety of maps and game modes, set in the European theater of the war. Choose to fight as a member of the Allied or Axis forces and work together with your team to complete objectives and gain victory.</br></br>With a range of authentic weapons and equipment at your disposal, you\'ll need to use tactics and communication to outmaneuver your opponents and claim victory. With intense gameplay and a strong focus on realism, Day of Infamy is a must-play for fans of tactical shooters',
		hook: 'Immerse yourself in the brutal battles of World War II',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/447820/Day_of_Infamy/',
		youtubeLink: 'https://www.youtube.com/watch?v=ZhQoAz3Ozow',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'dayz',
		name: 'DayZ',
		keywords: 'dayz survival open-world zombie apocalypse',
		boxImage: 'gamebox-dayz.webp',
		logoImage: 'gamelogo-dayz.webp',
		backgroundImage: 'gamebg-dayz.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Survive a post-apocalyptic world filled with danger and uncertainty in DayZ. This hardcore survival game puts you in the shoes of a survivor in a zombie-infested wasteland, where food, water, and shelter are your top priorities.</br></br>Explore a vast open world filled with abandoned cities, military bases, and wilderness, where danger lurks around every corner. With a range of weapons, vehicles, and equipment at your disposal, you\'ll need to use your wits and resourcefulness to stay alive. Team up with other survivors or compete against them in this intense and immersive survival experience',
		hook: 'Survive at all costs',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/221100/DayZ/',
		youtubeLink: 'https://www.youtube.com/watch?v=7jk8prJA9nI',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'days-of-war',
		name: 'Days of War',
		keywords: 'days of war world war II combat multiplayer shooter',
		boxImage: 'gamebox-days-of-war.webp',
		logoImage: 'gamelogo-days-of-war.webp',
		backgroundImage: 'gamebg-days-of-war.webp',
		extraClassName: '',
		sortTags: '',
		description: 'Relive the intense battles of World War II in Days of War, the fast-paced multiplayer shooter. Choose your side and take part in epic battles across a variety of locations, from the deserts of North Africa to the streets of Berlin. With a range of authentic weapons and equipment at your disposal, you\'ll need to work together with your team to complete objectives and achieve victory.</br></br>Customize your loadout and choose from a range of classes to suit your playstyle, from the heavy-hitting assault class to the sneaky scout class. With thrilling gameplay and a strong focus on historical accuracy, Days of War is a must-play for fans of classic World War II shooters.',
		hook: 'Experience the grit and chaos of World War II',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/454350/Days_of_War_Definitive_Edition/',
		youtubeLink: 'https://www.youtube.com/watch?v=-KJoiVNDCZ0',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'dont-starve-together',
		name: "Don\'t Starve Together",
		keywords: "don\'t starve together survival multiplayer exploration",
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Survive the wilderness with your friends in Don\'t Starve Together, the ultimate multiplayer survival game. With a unique blend of crafting, exploration, and resource management, you\'ll need to work together to gather supplies, build shelter, and fend off dangerous creatures.</br></br>With a range of different characters to choose from, each with their own strengths and weaknesses, you\'ll need to find the right balance of skills to ensure your group\'s survival. From harsh winter storms to giant spider infestations, every day is a fight for survival. Join forces with your friends and see how long you can last in the brutal world of Don\'t Starve Together.',
		hook: 'Brave the wilderness with your friends',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/322330/Dont_Starve_Together/',
		youtubeLink: 'https://www.youtube.com/watch?v=dU-aq2JbLkk',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'double-action-boogaloo',
		name: 'Double Action: Boogaloo',
		keywords: 'double action boogaloo action multiplayer shooter',
		boxImage: 'https://cdn2.steamgriddb.com/grid/4eefea4fc77f6a79afe6a2e4b0898d84.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Get ready for non-stop action and adrenaline-pumping stunts in Double Action: Boogaloo, the ultimate action-packed multiplayer shooter. With a unique blend of fast-paced gunplay, acrobatics, and over-the-top action, you\'ll be flying through the air and taking out your enemies with style.</br></br>Whether you\'re diving through windows, sliding under cars, or dual-wielding pistols, every moment in Double Action: Boogaloo is a thrilling rush. With a range of different game modes and maps to choose from, you\'ll never run out of ways to show off your skills. Get ready to kick some butt and take names in this explosive multiplayer shooter.',
		hook: 'Get ready for non-stop action and adrenaline-pumping stunts',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/317360/Double_Action_Boogaloo/',
		youtubeLink: 'https://www.youtube.com/watch?v=btzVypzt5nI',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'eco',
		name: 'Eco',
		keywords: 'eco sandbox survival exploration multiplayer',
		boxImage: 'https://cdn2.steamgriddb.com/grid/589ed0f0b009f655512d52bece14827c.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Welcome to Eco, the ultimate open-world survival game where you shape the world around you. Set in a lush and vibrant world, you\'ll need to gather resources, build infrastructure, and work together with other players to create a thriving civilization. But be warned, your actions have consequences.</br></br>The ecosystem is fragile, and your choices will impact the world and its inhabitants. With a robust crafting system, deep simulation mechanics, and a dynamic ecosystem, Eco is an immersive and engaging experience that challenges you to think about the impact of your actions. Will you work to protect the environment, or will you exploit it for your own gain?',
		hook: 'Experience the ultimate open-world survival game where every action has consequences',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/382310/Eco/',
		youtubeLink: 'https://www.youtube.com/watch?v=ud_refZuQoA',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'empyrion-galactic-survival',
		name: 'Empyrion Galactic Survival',
		keywords: 'empyrion galactic survival space sandbox multiplayer',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Take on the challenge of surviving in a vast and dangerous universe in Empyrion Galactic Survival. Build your own spaceship and explore the unknown, discovering new planets, asteroids, and alien civilizations along the way. Collect resources and craft new technologies to stay alive in this harsh environment.</br></br>Whether you choose to explore alone or with friends, the possibilities are endless in this sandbox-style survival game. With stunning graphics and immersive gameplay, Empyrion Galactic Survival offers an experience that will keep you coming back for more.',
		hook: 'Survive and thrive in the vast unknown',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/383120/Empyrion__Galactic_Survival/',
		youtubeLink: 'https://www.youtube.com/watch?v=y6FRqaKciaA',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'fortress-forever',
		name: 'Fortress Forever',
		keywords: 'fortress-forever team-fortress-classic',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Experience the fast-paced action and strategic gameplay of Fortress Forever, a multiplayer first-person shooter based on the classic game Team Fortress Classic. Choose your class and work with your team to capture objectives, defend your base, and dominate the enemy.</br></br>With nine unique classes to choose from and an active community of players, Fortress Forever offers endless fun and excitement. With updated graphics and mechanics, it\'s the perfect game for fans of the original Team Fortress and newcomers alike.',
		hook: 'Experience the classic gameplay of Team Fortress 1 with updated graphics and mechanics.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/253530/Fortress_Forever/',
		youtubeLink: 'https://www.youtube.com/watch?v=nxDDV7Bt99I',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'garrys-mod-standard',
		name: 'Garry\'s Mod Standard',
		keywords: 'garrys-mod sandbox',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Unleash your creativity with Garry\'s Mod, a sandbox game that lets you build, create, and experiment with anything you can imagine. Use the game\'s tools to spawn objects, manipulate the environment, and create your own unique scenarios.</br></br>Whether you want to build a towering structure, create a custom game mode, or just mess around with physics, Garry\'s Mod gives you the freedom to do it all.',
		hook: 'Garry\'s Mod Standard limits your tickrate to 11 - 33 which is recommended for clients who know that it will be enough for their server.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/4000/Garrys_Mod/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'garrys-mod-premium',
		name: 'Garry\'s Mod Premium',
		keywords: 'garrys-mod sandbox',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Unleash your creativity with Garry\'s Mod, a sandbox game that lets you build, create, and experiment with anything you can imagine. Use the game\'s tools to spawn objects, manipulate the environment, and create your own unique scenarios.</br></br>Whether you want to build a towering structure, create a custom game mode, or just mess around with physics, Garry\'s Mod gives you the freedom to do it all.',
		hook: 'Garry\'s Mod Premium provides you with access to 33 - 100 tick allowing you to run faster - more responsive servers.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/4000/Garrys_Mod/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'ground-branch',
		name: 'Ground Branch',
		keywords: 'ground-branch tactical-shooter',
		boxImage: 'https://cdn2.steamgriddb.com/grid/160f87c25f2fe7deefb24f00558b0c8f.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Ground Branch is a tactical shooter that emphasizes realism and authenticity in gameplay. With a focus on strategic planning and execution, players must utilize their tactical skills to complete missions and survive intense firefights.</br></br>With a variety of weapons, equipment, and maps to choose from, Ground Branch offers a challenging and immersive experience that will keep players coming back for more.',
		hook: 'Plan. Execute. Survive',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/16900/GROUND_BRANCH/',
		youtubeLink: 'https://www.youtube.com/watch?v=OC9NJSxDm5g',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'heat',
		name: 'Heat',
		keywords: 'heat multiplayer-survival',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Heat is a multiplayer survival game where players must gather resources, build shelter, and fend off dangerous wildlife in a harsh, unforgiving world. The game features intense PvP combat and a variety of unique game modes, including Battle Royale and Capture the Flag.</br></br>With beautiful graphics and immersive gameplay, Heat offers a thrilling experience for players looking for a challenging survival game. Join the battle today and fight for survival in a world that\'s out to get you.',
		hook: 'Survive, Build, Dominate',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/656240/Heat/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'hell-let-loose',
		name: 'Hell Let Loose',
		keywords: 'hell-let-loose world-war-ii',
		boxImage: 'https://cdn2.steamgriddb.com/grid/47db5cf891fe03b0e6bd51b7c8ee4a20.jpg',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Hell Let Loose is a realistic World War II first-person shooter that puts players in the midst of large-scale battles across Europe. With 50 vs 50 players in each match, players must work together to capture objectives and outmaneuver the enemy.</br></br>The game features stunning graphics and immersive sound design that transport players to the front lines of the war. With multiple classes and weapons to choose from, players can customize their loadouts and play styles to suit their preferences. Are you ready to join the fight and experience the chaos of war firsthand?',
		hook: 'Enlist now',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/686810/Hell_Let_Loose/',
		youtubeLink: 'https://www.youtube.com/watch?v=S3hLu58KXg8',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'hellion',
		name: 'Hellion',
		keywords: 'hellion multiplayer-space-survival',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Hellion is a multiplayer space survival game that challenges players to survive the harsh conditions of deep space. Players are stranded on a decaying space station, where they must scavenge for resources and repair their ship to escape.</br></br>The game features realistic physics and mechanics, including orbital mechanics, which players must master to survive. In addition to the challenging survival gameplay, Hellion offers intense PvP combat and a vast, immersive universe to explore. With stunning graphics and deep, engaging gameplay, Hellion is a must-play for fans of survival and space exploration games. Will you survive the cold void of space and make it home?',
		hook: 'Survive the cold void of space',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/588210/HELLION/',
		youtubeLink: 'https://www.youtube.com/watch?v=FsSzoQJek70',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'hurtworld',
		name: 'Hurtworld',
		keywords: 'hurtworld multiplayer-survival',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Hurtworld is a multiplayer survival game developed by Bankroll Studios. Players must scavenge for resources, build shelter, and fight off other players and deadly creatures to survive in a harsh and unforgiving world.</br></br>With a deep crafting system, a vast open world, and intense PvP combat, Hurtworld offers a challenging and rewarding experience for players looking for a hardcore survival game.',
		hook: 'Can you outlast the other?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/393420/Hurtworld/',
		youtubeLink: 'https://www.youtube.com/watch?v=RdvbYG_dSyI',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'insurgency-2014',
		name: 'Insurgency 2014',
		keywords: 'insurgency-2014-sa intense-close-quarters-combat',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Insurgency is a tactical team-based shooter that emphasizes intense close-quarters combat and objective-based gameplay. With realistic weapon handling and a focus on strategy and teamwork, Insurgency offers a challenging and immersive experience for players who enjoy fast-paced, skill-based multiplayer action.</br></br>Choose your role and work together with your team to take on enemy forces and complete your objectives.',
		hook: 'Do you have what it takes?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/222880/Insurgency/',
		youtubeLink: 'https://www.youtube.com/watch?v=g6zUUASvEwU',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'insurgency-sandstorm',
		name: 'Insurgency: Sandstorm',
		keywords: 'insurgency-sandstorm team-based-tactical-shooter',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Insurgency: Sandstorm is a team-based tactical shooter that delivers intense, realistic combat in a Middle Eastern setting. With stunning graphics, immersive sound design, and fluid gameplay, Sandstorm offers an unparalleled experience for players looking for a challenge.</br></br>Choose your class, customize your loadout, and work with your team to complete objectives and defeat your enemies. With a variety of game modes, including Push, Firefight, and Skirmish, Sandstorm offers endless replayability and a truly authentic military experience.',
		hook: 'Fight for survival in the unforgiving sands of war',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/581320/Insurgency_Sandstorm/',
		youtubeLink: 'https://www.youtube.com/watch?v=nXQQDlTI89Q',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'just-cause-2',
		name: 'Just Cause 2',
		keywords: 'just-cause-2 sandbox-chaos',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Just Cause 2: Multiplayer is a mod for the open-world action game "Just Cause 2" that allows hundreds of players to explore and wreak havoc in a massive sandbox environment. With a wide range of vehicles, weapons, and tools at your disposal, you can team up with friends or take on rival factions in epic battles and challenges.</br></br>With an active modding community and constant updates, "Just Cause 2: Multiplayer" offers endless possibilities for chaotic fun.',
		hook: 'Join the fray today',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/8190/Just_Cause_2/',
		youtubeLink: 'https://www.youtube.com/watch?v=EJV4h3BL3Zk',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'killing-floor',
		name: 'Killing Floor',
		keywords: 'killing-floor zombie-survival',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Killing Floor is a first-person shooter game developed and published by Tripwire Interactive. It was first released in 2009 for Microsoft Windows, and has since been released on several other platforms. In the game, players fight waves of zombies, called "Zeds," that have taken over cities and other locations around the world.</br></br>Players can choose from a variety of classes, each with its own strengths and weaknesses, and work together in co-op mode to survive the onslaught. The game features a wide range of weapons, from pistols and shotguns to rocket launchers and flamethrowers, as well as a leveling system that allows players to unlock new perks and abilities as they progress.',
		hook: 'The perfect zombie apocalypse',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/1250/Killing_Floor/',
		youtubeLink: 'https://www.youtube.com/watch?v=gKUb8KSZVPY',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'killing-floor-2',
		name: 'Killing Floor 2',
		keywords: 'killing-floor-2 cooperative-first-person-shooter',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Killing Floor 2 is a cooperative first-person shooter game where players must fight off hordes of genetically modified monsters known as Zeds. The game takes place in a post-apocalyptic world where the Zed outbreak has decimated the population and players must work together to survive.</br></br>With a wide variety of weapons and perks, players can customize their gameplay experience to fit their playstyle. The game features intense, fast-paced action and stunning graphics, making it a must-play for fans of the FPS genre. So grab your friends and get ready to face the Zed horde in Killing Floor 2!',
		hook: 'Survive the Sequel',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/232090/Killing_Floor_2/',
		youtubeLink: 'https://www.youtube.com/watch?v=PpH8oghfsT8',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'left-4-dead',
		name: 'Left 4 Dead',
		keywords: 'left-4-dead cooperative-first-person-shooter',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Left 4 Dead is a cooperative first-person shooter where up to four players fight through hordes of zombies in a post-apocalyptic world. The game features a unique "Director" AI that adjusts the gameplay to ensure a dynamic and unpredictable experience with every playthrough.</br></br>With a variety of weapons and special infected enemies, Left 4 Dead offers intense, fast-paced action that\'s perfect for gamers who enjoy team-based combat. Survive together, or die alone in this thrilling zombie shooter.',
		hook: 'Survive the apocalypse with friends',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/500/Left_4_Dead/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'left-4-dead-2',
		name: 'Left 4 Dead 2',
		keywords: 'left-4-dead-2 co-op-first-person-shooter',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Left 4 Dead 2 is a co-op first-person shooter that pits players against hordes of terrifying zombies in a post-apocalyptic world. With five unique campaigns and a variety of game modes, including the popular versus mode, players can experience intense action and endless replayability.</br></br>Choose from a diverse cast of survivors, each with their own unique abilities, and fight your way through the infected to safety. Left 4 Dead 2 offers a thrilling and challenging gaming experience that is perfect for players who love a good zombie apocalypse.',
		hook: 'Will you survive the outbreak?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/agecheck/app/550/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'miscreated',
		name: 'Miscreated',
		keywords: 'miscreated post-apocalyptic-survival',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'In Miscreated, players must navigate a harsh, post-apocalyptic world filled with deadly mutants, other players, and limited resources. With dynamic weather and a day-night cycle, survival is a constant battle. Scavenge for supplies, build shelters, and craft weapons to defend yourself from hostile creatures and players alike.</br></br>Will you work together with others to survive, or will you go it alone in this unforgiving landscape? In Miscreated, every decision you make could mean the difference between life and death.',
		hook: 'Stay alive and thrive',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/299740/Miscreated/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'mordhau',
		name: 'Mordhau',
		keywords: 'mordhau multiplayer-medieval-combat',
		boxImage: 'https://cdn2.steamgriddb.com/grid/b9bdddec75b0e69ef4e14e5ea8a3fc15.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Mordhau is a multiplayer medieval combat game that pits players against each other in intense, visceral battles. With a variety of weapons and tactics at their disposal, players must outsmart and outfight their opponents in order to emerge victorious.</br></br>The game features both team-based and free-for-all game modes, allowing for a wide range of gameplay experiences. With beautiful graphics and smooth gameplay, Mordhau" is a must-play for fans of medieval combat.',
		hook: 'Join the fight today',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/629760/MORDHAU/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'multi-theft-auto',
		name: 'Multi Theft Auto',
		keywords: 'multi-theft-auto gta-mod multiplayer',
		boxImage: 'https://cdn2.steamgriddb.com/grid/dacc0f4b7225cb4080f1b26c0bcbec54.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Multi Theft Auto (MTA) is a popular mod for Grand Theft Auto: San Andreas that brings online multiplayer functionality to the game. With MTA, players can create their own custom game modes, maps, and scripts, and compete against each other in a variety of game modes including racing, deathmatch, and roleplaying. MTA\'s community is one of the largest and most active in the GTA modding scene, with a constant stream of new content being released.',
		hook: 'Join the fun today',
		purchaseOn: 'steam',
		storeLink: 'https://multitheftauto.com/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'natural-selection-2',
		name: 'Natural Selection 2',
		keywords: 'natural-selection-2 fps-strategy',
		boxImage: 'https://cdn2.steamgriddb.com/grid/aef61ec41016607a6e3a58712cbf3e8a.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'In Natural Selection 2, players must work together to fight for survival in a hostile world. Combining first-person shooter and real-time strategy gameplay, this game offers a unique and immersive experience. Choose to play as either the human Marines or the alien Kharaa, each with their own strengths and weaknesses.</br></br>With beautiful graphics and intense gameplay, Natural Selection 2 provides an exciting challenge for players who enjoy both FPS and strategy games. Join the battle today and discover who will come out on top in the fight for survival.',
		hook: 'Fight for survival',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/4920/Natural_Selection_2/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'no-more-room-in-hell',
		name: 'No More Room in Hell',
		keywords: 'nomoreroominhell zombies survival horror',
		boxImage: 'https://cdn2.steamgriddb.com/grid/2f5650147d33576ce234fc9739fd6735.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'No More Room in Hell is a co-op survival horror game where players fight their way through waves of zombies to survive. With intense gameplay and realistic mechanics, players must work together to scavenge for resources, barricade themselves against the undead hordes, and fight for survival in a world overrun by the infected.</br></br>Will you make it out alive?</br></br>The apocalypse awaits.',
		hook: 'Survive together or die alone.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/224260/No_More_Room_in_Hell/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'onset',
		name: 'Onset',
		keywords: 'onset open-world sandbox',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Onset is an open-world sandbox game where players can create their own stories and experiences. With a variety of vehicles, weapons, and jobs to choose from, players can live out their wildest fantasies in a sprawling, dynamic world. Whether you want to become a law-abiding citizen or a notorious criminal, the choice is yours.</br></br>Play with friends or take on the world solo - the possibilities are endless. With its robust modding support and dedicated community, Onset is the perfect game for players who want to take control of their own destiny.</br></br>Are you ready to create your own story?',
		hook: 'Are you ready to create your own story?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/1105810/Onset/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'openttd',
		name: 'OpenTTD',
		keywords: 'openttd transport simulation',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'OpenTTD is a transport simulation game that puts you in charge of a growing transportation network. Build your own airports, train stations, and harbors to connect cities and industries, while managing your finances and dealing with challenges like traffic congestion and natural disasters.</br></br>With a massive online community and endless modding possibilities, OpenTTD offers limitless gameplay possibilities for transport enthusiasts.</br></br>Build, connect, and prosper',
		hook: 'Build, connect, and prosper',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/1536610/OpenTTD/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'orion-prelude',
		name: 'Orion: Prelude',
		keywords: 'orionprelude first-person shooter dinosaurs',
		boxImage: 'https://cdn2.steamgriddb.com/grid/8eaf9f3262c29cf2938699653cd93f48.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Orion: Prelude is an action-packed first-person shooter set in a futuristic world where you fight against hordes of deadly dinosaurs. You can team up with other players to complete missions and survive the onslaught, or battle against each other in thrilling PvP matches.</br></br>With a vast array of weapons, vehicles, and classes to choose from, you\'ll never run out of ways to take down your prehistoric foes. Get ready for an intense and unforgettable experience!</br></br>Survive the dinosaur apocalypse.',
		hook: 'Survive the dinosaur apocalypse.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/104900/ORION_Prelude/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'pixark',
		name: 'PixARK',
		keywords: 'pixark sandbox adventure',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'PixARK is an action-packed sandbox adventure game that lets you explore a vast, pixelated world full of danger and adventure. Build your own unique base and tame fantastical creatures as you battle dangerous foes and uncover mysterious ruins.</br></br>With multiplayer support and endless possibilities, PixARK is the ultimate pixel sandbox experience. Are you ready to embark on your epic journey?</br></br>Survive the Pixels',
		hook: 'ARK like you have never seen before',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/593600/PixARK/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'post-scriptum',
		name: 'Post Scriptum',
		keywords: 'postscriptum tactical first-person shooter',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Post Scriptum is a tactical first-person shooter set in World War II. Immerse yourself in realistic battles and choose your role as a soldier, squad leader, or commander. Coordinate with your team and plan your attacks carefully to achieve victory.</br></br>With accurate historical details and beautiful graphics, this game delivers an authentic experience. Join the fight and rewrite history in Post Scriptum.</br></br>Plan your attack, rewrite history.',
		hook: 'Plan your attack, rewrite history.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/736220/Post_Scriptum/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'project-argo',
		name: 'Project Argo',
		keywords: 'projectargo tactical first-person shooter',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Project Argo is a tactical first-person shooter that pits two mercenary teams against each other in intense battles across a variety of maps. With customizable loadouts and diverse game modes, every match is a unique experience that demands strategy and skill. Will you fight for the Enforcers or the Syndikat? Choose your side and prove your worth on the battlefield.</br></br>Choose your side and fight for victory.',
		hook: 'Choose your side and fight for victory.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/530700/Argo/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'project-zomboid',
		name: 'Project Zomboid',
		keywords: 'projectzomboid hardcore survival',
		boxImage: 'https://cdn2.steamgriddb.com/grid/c885743821cce93ad525c5919aa9faa9.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Project Zomboid is a hardcore, post-apocalyptic, survival game that challenges players to scavenge for supplies, fortify shelters, and fend off hordes of zombies.</br></br>With a vast and dynamic world, players must adapt and improvise in order to survive in this dangerous, undead-infested environment. Whether playing solo or cooperatively with others, every decision matters in this unforgiving world where death is always just one bite away.</br></br>Every decision counts',
		hook: 'Every decision counts',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/108600/Project_Zomboid/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		earlyaccess: true,
		latest: false
	},
	{
		key: 'rage-mp',
		name: 'RAGE:MP (GTAV)',
		keywords: 'ragemp multiplayer modification',
		boxImage: 'https://cdn2.steamgriddb.com/grid/58a5cd56aa642c3852a0a63795960132.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'RAGE:MP is a multiplayer modification for Grand Theft Auto V that brings a new level of freedom and customization to the game. With an active community creating endless new content, players can enjoy everything from simple races to complex role-playing scenarios.</br></br>Whether you prefer to team up with friends or go head-to-head with strangers, RAGE:MP has something for everyone. Take your GTA V experience to the next level with this exciting mod.</br></br>Unleash endless possibilities in GTA',
		hook: 'Unleash endless possibilities in GTA',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/271590/Grand_Theft_Auto_V/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'reflexfps',
		name: 'Reflex FPS',
		keywords: 'reflexfps arena shooter',
		backgroundImage: "gamebg-default.webp",
		boxImage: "https://cdn2.steamgriddb.com/grid/b7cd562c784eaab61d8a9168c98bc112.png",
		extraClassName: '',
		sortTags: '',
		description: 'Reflex FPS is an arena shooter that is all about skill, speed, and precision. It features a wide variety of weapons and maps, allowing players to create their own unique playstyle.</br></br>With fast-paced gameplay and smooth mechanics, Reflex FPS is a must-play for fans of the genre. Get ready to frag like a pro!</br></br>Test your reflexes!',
		hook: 'Test your reflexes!',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/328070/Reflex_Arena/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'mountandbladewarband',
		name: 'Mount & Blade: Warband',
		keywords: 'medieval rpg open world strategy sandbox multiplayer action',
		backgroundImage: 'gamebg-default.webp',
		extraClassName: '',
		sortTags: '',
		description: 'In a land torn asunder by incessant warfare, it is time to assemble your own band of hardened warriors and enter the fray. Lead your men into battle, expand your realm, and claim the ultimate prize: the throne of Calradia!</br></br>Mount & Blade: Warband is a stand alone expansion pack for the game that brought medieval battlefields to life with its realistic mounted combat and detailed fighting system.',
		hook: 'Charge into Battle, Ride into Legend!',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/48700/Mount__Blade_Warband/',
		youtubeLink: 'https://www.youtube.com/watch?v=hwGxzdGqEDg&pp=ygUebW91bnQgJiBibGFkZSB3YXJiYW5kIHRyYWlsZXIg',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ],
		earlyaccess: false,
		latest: false
	},
	{
		key: 'reign-of-kings',
		name: 'Reign of Kings',
		keywords: 'reignofkings medieval survival',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'In Reign of Kings, you\'ll fight for survival in a harsh medieval world filled with rival kingdoms and dangerous wildlife. With an emphasis on multiplayer, form alliances, raid enemy castles, and stake your claim on the throne.</br></br>But beware, as other players can turn on you in a heartbeat, and the harsh world itself is an enemy to be reckoned with. Will you rise to the top and rule over all, or will you succumb to the dangers that lurk around every corner?</br></br>Claim your throne.',
		hook: 'Claim your throne.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/344760/Reign_Of_Kings/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'rend',
		name: 'Rend',
		keywords: 'rend survival mythical beasts',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Rend is a survival game set in a beautiful, yet deadly world full of mythical beasts and magical elements. Work together with your tribe to gather resources, build structures, and fight off enemy factions.</br></br>Explore the map, uncover ancient artifacts, and harness the power of the gods to gain an edge in this unforgiving land. Will you band together and emerge victorious, or fall to the elements and opposing tribes? The choice is yours in Rend.',
		hook: 'Can you control the power of gods?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/547860/Rend/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'rising-storm-2',
		name: 'Rising Storm 2: Vietnam',
		keywords: 'risingstorm2 tactical combat Vietnam War',
		boxImage: 'https://cdn2.steamgriddb.com/grid/c8dbb917fde2753ad9eebf9cc58c69eb.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Rising Storm 2: Vietnam immerses players into a brutal and realistic portrayal of the Vietnam War, with intense tactical combat and true-to-life weaponry. Fight your way through the dense jungles and ruins of war-torn cities in 64-player matches, where teamwork and communication are crucial for survival.</br></br>With authentic sound design and beautiful graphics, this game delivers an unparalleled experience of the conflict that shaped a generation.</br></br>Can you win the war?',
		hook: 'Can you win the war?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/418460/Rising_Storm_2_Vietnam/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'samp',
		name: 'San Andreas Multiplayer (SA-MP)',
		keywords: 'samp multiplayer modification',
		boxImage: 'https://cdn2.steamgriddb.com/grid/9c820130a4fdf88d8193f10d4d3fa7ce.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'San Andreas Multiplayer (SA-MP) is a modification for the popular video game Grand Theft Auto: San Andreas that allows players to connect and play on custom-made multiplayer servers. With an active community and regular updates, SA-MP offers a wide range of gameplay options, from roleplaying to stunt challenges to shooter game modes.</br></br>The mod also includes unique features such as synced vehicle systems, custom animations, and player skins. Whether you want to join a gang or start your own, SA-MP offers a dynamic and constantly evolving multiplayer experience.',
		hook: 'Only better with multiplayer.',
		purchaseOn: 'steam',
		storeLink: 'https://www.sa-mp.mp/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'sniper-elite-4',
		name: 'Sniper Elite 4',
		keywords: 'sniperelite4 tactical shooter',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Sniper Elite 4 is a tactical shooter that rewards skillful play and patience. The multiplayer mode offers tense, heart-pumping matches as players fight for control of the battlefield. Choose your class and weapon loadout wisely, and coordinate with your team to outflank and outgun your opponents.</br></br>Whether you prefer stealthy kills or full-frontal assaults, Sniper Elite 4\'s multiplayer has something for everyone. So grab your rifle, steady your aim, and get ready for some intense sniper action.</br></br>One shot, one kill.',
		hook: 'One shot, one kill.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/312660/Sniper_Elite_4/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'space-engineers',
		name: 'Space Engineers',
		keywords: 'spaceengineers sandbox space exploration',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Space Engineers is a sandbox-style space exploration game developed and published by Keen Software House. Players can design and build their own spaceships, stations, and planetary outposts, explore vast procedurally generated worlds, and engage in thrilling space battles.</br></br>With realistic physics and an extensive modding community, Space Engineers offers endless possibilities for players to explore and create. Whether you\'re looking for a solo adventure or a multiplayer experience, Space Engineers has something for everyone. So put on your spacesuit, and strap in.</br></br>Adventure among the stars',
		hook: 'Adventure among the stars',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/244850/Space_Engineers/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'starbound',
		name: 'Starbound',
		keywords: 'starbound 2d space exploration',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Starbound is a 2D space exploration game that puts players in the shoes of an astronaut who has crash-landed on a mysterious planet. With a procedurally generated universe, there\'s no shortage of things to do and discover in this vibrant and colorful game.</br></br>Whether it\'s building your own base, crafting new items, or battling strange creatures, there\'s always something to keep you occupied. With its charming art style and relaxing soundtrack, Starbound is the perfect game for players who want to immerse themselves in a sci-fi adventure that\'s both engaging and relaxing.</br></br>Travel through the stars',
		hook: 'Travel through the stars',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/211820/Starbound/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'stationeers',
		name: 'Stationeers',
		keywords: 'stationeers sci-fi survival',
		boxImage: 'https://cdn2.steamgriddb.com/grid/5f9267a44610aa25faf3bb7831d797bb.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Stationeers is a challenging multiplayer sci-fi survival game that puts players in charge of running a space station. With a focus on realism, players must maintain the station\'s atmosphere, power, and food supply while dealing with a variety of hazards such as radiation and equipment malfunctions.</br></br>The game features detailed systems for construction, crafting, and automation, allowing players to build their stations as they see fit. Whether working together or alone, players must stay vigilant to keep their stations running smoothly and survive the harsh conditions of space.</br></br>Can you survive space?',
		hook: 'Can you survive space?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/544550/Stationeers/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'stormworks',
		name: 'Stormworks: Build and Rescue',
		keywords: 'stormworks sandbox rescue',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Stormworks: Build and Rescue is an exciting multiplayer sandbox game that allows players to design and build their own rescue vehicles to save lives in dynamic and challenging missions. From helicopters to boats, players have the freedom to construct vehicles using a variety of modular parts and realistic physics.</br></br>Embark on thrilling rescue missions, battle against turbulent weather conditions, and work together with friends to overcome the elements. With its immersive gameplay and endless possibilities, Stormworks: Build and Rescue offers a unique and rewarding experience for those who love engineering, teamwork, and the thrill of saving lives.</br></br>Bring your rescue vehicles to life.',
		hook: 'Bring your rescue vehicles to life.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/573090/Stormworks_Build_and_Rescue/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'supraball',
		name: 'Supraball',
		keywords: 'supraball multiplayer sports',
		boxImage: 'https://cdn2.steamgriddb.com/grid/6b0190f1c3f3d3b7a4ae7fc280f82821.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Supraball is an adrenaline-pumping multiplayer sports game that combines the fast-paced action of football with futuristic technology. Team up with friends or compete against players from around the world in intense matches where skill and teamwork are key to victory.</br></br>Use gravity-defying jumps, precise passes, and powerful shots to outplay your opponents and score spectacular goals. With its unique blend of strategy, coordination, and fast reflexes, Supraball offers an exciting and competitive experience for sports enthusiasts and gamers alike.</br></br>The future of football',
		hook: 'The future of football',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/321400/Supraball/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'tf2classic',
		name: 'Team Fortress 2 Classic',
		keywords: 'tf2classic multiplayer shooter',
		boxImage: 'https://cdn2.steamgriddb.com/grid/13044c7e209051ae514a1c5428cac96c.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Team Fortress 2 Classic is a nostalgic return to the beloved multiplayer shooter that started it all. Engage in fast-paced, team-based battles as iconic characters with unique abilities and playstyles. Experience the thrill of strategic gameplay, intense firefights, and hilarious moments that made the original game a classic.</br></br>With a vibrant and dedicated community, endless customization options, and a wealth of game modes to choose from, Team Fortress 2 Classic offers a timeless multiplayer experience that will keep you coming back for more.</br></br>Relive the glory days',
		hook: 'Relive the glory days',
		purchaseOn: 'steam',
		storeLink: 'https://tf2classic.com/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'team-fortress-2',
		name: 'Team Fortress 2',
		keywords: 'team fortress 2 tf2 multiplayer shooter',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Team Fortress 2 is the ultimate team-based multiplayer experience that has stood the test of time. Choose from a diverse roster of quirky and memorable characters, each with their own unique abilities, and engage in thrilling objective-based battles.</br></br>With a perfect blend of fast-paced action, strategic gameplay, and comedic charm, Team Fortress 2 offers endless hours of addictive fun. Whether you\'re a skilled sniper, a sneaky spy, or a relentless heavy hitter, there\'s a role for every player. Join the iconic battle between RED and BLU teams and prove your worth in this timeless classic.',
		hook: 'Which class suits you best?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/440/Team_Fortress_2/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'team-fortress-classic',
		name: 'Team Fortress Classic',
		keywords: 'team fortress classic tfc multiplayer shooter',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Team Fortress Classic takes you back to the roots of online team-based gaming. Immerse yourself in the fast-paced action as you choose from nine unique classes, each with their own special abilities and weapons.</br></br>Work together with your teammates to capture objectives, defend your base, or go head-to-head in intense deathmatches. With a variety of maps and game modes, every match is a new challenge. Experience the nostalgia and adrenaline of classic team warfare as you dominate the battlefield in Team Fortress Classic.',
		hook: 'Are you ready to join the fight?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/20/Team_Fortress_Classic/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'terraria',
		name: 'Terraria',
		keywords: 'terraria sandbox adventure exploration crafting',
		boxImage: 'https://cdn2.steamgriddb.com/grid/9dcbde954a70a131677604b46d3d235d.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Embark on an extraordinary adventure in Terraria, a sandbox action-adventure game where the world is your canvas and the possibilities are endless. Dig deep into the earth to discover precious resources and treasures, build intricate structures, and craft powerful weapons and armor to conquer the diverse biomes and face formidable foes.</br></br>Whether you choose to explore, build, fight, or all of the above, Terraria offers an immersive and dynamic experience that will keep you hooked for hours on end. Unleash your creativity, test your skills, and shape your own destiny in the vast world of Terraria.',
		hook: 'Adventure awaits!',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/105600/Terraria/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'the-forest',
		name: 'The Forest',
		keywords: 'the forest survival horror open-world',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Step into the chilling world of The Forest, a gripping survival horror game that will push your limits of fear and endurance. After a plane crash leaves you stranded on a mysterious island, you must navigate the treacherous environment and uncover the dark secrets that lie within.</br></br>Craft weapons, build shelter, and scavenge for resources as you fight for survival against cannibalistic mutants lurking in the shadows. With its haunting atmosphere, intense gameplay, and a compelling narrative, The Forest delivers a truly immersive and terrifying experience. Brace yourself for the ultimate test of courage in a world where every step could be your last.',
		hook: 'Hunter or hunted?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/agecheck/app/242760/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'the-isle',
		name: 'The Isle',
		keywords: 'the isle open-world survival dinosaurs',
		boxImage: 'https://cdn2.steamgriddb.com/grid/4fbb34893d933629fe7d7e31ed3d7cc0.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Embark on an untamed journey into The Isle, a vast and mysterious open-world survival game where players must adapt and survive in a land dominated by prehistoric creatures. Roam breathtaking landscapes, from lush forests to desolate plains, as you navigate the harsh environments and fight for your place in the food chain.</br></br>Play as a variety of dinosaur species, each with unique abilities and behaviors, and experience the thrill of both predator and prey. With its realistic graphics, immersive gameplay, and an ever-evolving world, The Isle offers an unparalleled dinosaur survival experience.',
		hook: 'Can you become the apex predator?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/376210/The_Isle/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'unturned',
		name: 'Unturned',
		keywords: 'unturned sandbox survival zombies',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Step into a post-apocalyptic world teeming with zombies and fight for survival in Unturned. This free-to-play sandbox game combines survival, exploration, and crafting elements to deliver a thrilling and challenging experience. Scavenge for resources, build shelter, and fend off hordes of undead as you navigate a vast and dangerous landscape.</br></br>Team up with friends or go it alone in intense multiplayer battles, or test your skills in the intense PvP arenas. With its charming blocky art style and addictive gameplay, Unturned offers endless hours of zombie-infested excitement.',
		hook: 'Can you outlast the apocalypse?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/304930/Unturned/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'alien-swarm-reactive-drop',
		name: 'Alien Swarm: Reactive Drop',
		keywords: 'alien swarm reactive drop top-down shooter cooperative multiplayer',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Alien Swarm: Reactive Drop is an extension of the classic top-down shooter Alien Swarm. Join your team of heavily armed marines in a cooperative multiplayer environment as you battle through hordes of alien creatures.</br></br>Experience the intense combat scenarios, test your strategic skills, and try to survive in this thrilling and challenging game.',
		hook: 'Can you survive the alien onslaught?',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/563560/Alien_Swarm_Reactive_Drop/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'arma2-operation-arrowhead',
		name: 'ARMA 2: Operation Arrowhead',
		keywords: 'arma 2 operation arrowhead military simulation shooter multiplayer',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'ARMA 2: Operation Arrowhead is a military simulation shooter game that immerses you into a realistic war scenario. Control various units, plan strategies, and participate in combined arms warfare in a large, open-world environment.</br></br>Experience the adrenaline rush of intense combat, dynamic weather, and the freedom to tackle missions your way.',
		hook: 'Welcome to the battlefield.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/33930/Arma_2_Operation_Arrowhead/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'assetto-corsa-competizione',
		name: 'Assetto Corsa Competizione',
		keywords: 'assetto corsa competizione racing simulator',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Assetto Corsa Competizione is the official GT World Challenge videogame. Experience the thrill of high-speed racing with realistic graphics and immersive sound effects. Test your skills against the AI or go head-to-head with players around the world in exciting multiplayer matches.</br></br>Enjoy the adrenaline rush of competitive racing, from practice sessions to qualifying rounds and endurance races.',
		hook: 'Rev up your engines!',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/805550/Assetto_Corsa_Competizione/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'half-life-2-deathmatch',
		name: 'Half-Life 2: Deathmatch',
		keywords: 'half-life 2 deathmatch shooter multiplayer',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Half-Life 2: Deathmatch is a multiplayer spinoff of the acclaimed Half-Life 2. Enter a fast-paced, physics-enhanced combat environment and face off against friends or strangers. Utilize the physics manipulation in clever ways to overcome your enemies.</br></br>Experience the thrilling chaos of deathmatch in the immersive world of Half-Life 2.',
		hook: 'Join the deathmatch chaos!',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/320/HalfLife_2_Deathmatch/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'holdfast-nations-at-war',
		name: 'Holdfast: Nations At War',
		keywords: 'holdfast-nations-at-war historical multiplayer',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Holdfast: Nations At War is a multiplayer first-person shooter set during the Napoleonic era. Immerse yourself in the chaos of battle as you join a regiment and fight for your nation in epic land and naval battles.</br></br>Cooperate with your comrades, take on different roles, and use authentic weaponry to turn the tide of war. Whether storming enemy fortifications or engaging in ship-to-ship combat, strategic teamwork is the key to victory.',
		hook: 'Experience 18th-century warfare.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/589290/Holdfast_Nations_At_War/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'memories-of-mars',
		name: 'Memories of Mars',
		keywords: 'memories-of-mars sci-fi survival',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Memories of Mars is a sci-fi survival game that takes place on the Red Planet. You are a clone, tasked with surviving the harsh Martian environment, uncovering the mysteries left behind by previous colonists.</br></br>Explore the vast landscape, scavenge for resources, and build shelters to protect yourself from the lethal sandstorms and hostile creatures. Engage in intense multiplayer battles, team up with others, or go solo to unravel the secrets of Mars.\n\nSurvive, adapt, and overcome the challenges that await you on this unforgiving planet in Memories of Mars.',
		hook: 'Survive the mysteries of Mars.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/644290/Memories_of_Mars/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'osiris-new-dawn',
		name: 'Osiris: New Dawn',
		keywords: 'osiris-new-dawn sci-fi survival',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Osiris: New Dawn is a sci-fi survival game set in the distant future where you are part of a colonization team sent to explore and terraform new worlds. Crash-landed on an alien planet, you must gather resources, build structures, and survive against extraterrestrial threats.</br></br>Explore the vast and beautiful landscape, create colonies, and research new technologies to ensure humanity\'s survival. Team up with friends to overcome challenges or engage in fierce PvP battles for dominance.',
		hook: 'Embark on an interstellar survival adventure.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/402710/Osiris_New_Dawn/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'squad',
		name: 'Squad',
		keywords: 'squad military fps',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Squad is a tactical military first-person shooter that focuses on teamwork and communication. Join intense 50 vs. 50 battles with realistic infantry and vehicular combat. Coordinate with your squadmates, use authentic weaponry, and leverage your skills to complete objectives and secure victory on the battlefield.</br></br>Experience the camaraderie and coordination of modern combat in Squad, where strategic play and cooperation are crucial for success.',
		hook: 'Immerse yourself in intense military combat.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/393380/Squad/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'synergy',
		name: 'Synergy',
		keywords: 'synergy half-life-2-mod co-op',
		backgroundImage: "gamebg-default.webp",
		boxImage: "https://cdn2.steamgriddb.com/grid/95f0b15071d66f500f6193c8a5bda780.png",
		extraClassName: '',
		sortTags: '',
		description: 'Synergy is a cooperative multiplayer mod for Half-Life 2. It allows you and your friends to play through the Half-Life 2 campaign together, experiencing the iconic story as a team.</br></br>Work together to solve puzzles, battle Combine forces, and survive the dangers of City 17. Synergy brings a fresh co-op experience to the classic Half-Life 2, making it even more fun to play with friends.',
		hook: 'Experience Half-Life 2 together in co-op.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/17520/Synergy/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'the-black-death',
		name: 'The Black Death',
		keywords: 'the-black-death medieval survival',
		backgroundImage: "gamebg-default.webp",
		boxImage: "https://cdn2.steamgriddb.com/grid/27606ea7bfa6d3f4bc85f44e965e1be8.png",
		extraClassName: '',
		sortTags: '',
		description: 'The Black Death is a multiplayer survival game set in a dark and gritty medieval world. With the bubonic plague ravaging the land, you must fight to survive and thrive amidst the chaos.</br></br>Gather resources, craft essential items, and engage in both PvE and PvP combat. Explore a dynamic world filled with dangers, quests, and player-driven economy. Will you succumb to the plague or rise to power in The Black Death?',
		hook: 'Survive the dark and plague-ridden medieval world.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/412450/The_Black_Death/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'tower-unite',
		name: 'Tower Unite',
		keywords: 'tower-unite social minigames',
		boxImage: 'https://cdn2.steamgriddb.com/grid/f468819247721c298c0af965f43b2cee.png',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Tower Unite is a social virtual world where players can hang out, play minigames, and enjoy a wide variety of activities. Explore themed worlds, customize your character, and participate in multiplayer minigames like mini-golf, bowling, and more.</br></br>Connect with friends, meet new people, and create your own fun in this sandbox experience. Tower Unite offers a unique blend of social interactions and entertaining gameplay for all to enjoy.',
		hook: 'Enjoy a social virtual world with fun minigames.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/394690/Tower_Unite/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'ylands',
		name: 'Ylands',
		keywords: 'ylands sandbox adventure',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Ylands is an open-world sandbox adventure game that allows you to create and explore procedurally generated worlds. Build structures, craft items, and embark on exciting adventures alone or with friends.</br></br>With a powerful editor, you can design your own games and share them with the community. Immerse yourself in diverse environments, uncover hidden treasures, and unleash your creativity in Ylands.',
		hook: 'Unleash your creativity in a procedurally generated world.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/298610/Ylands/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'outlaws-of-the-old-west',
		name: 'Outlaws of the Old West',
		keywords: 'outlaws-of-the-old-west western survival',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Outlaws of the Old West is a multiplayer survival game set in the American frontier during the 19th century. Live the life of an outlaw, a lawman, or a pioneer as you explore a vast and untamed wilderness.</br></br>Gather resources, build settlements, and engage in fierce gunfights with other players. Hunt wildlife, mine for gold, and navigate the rugged landscape to survive and thrive in this Wild West adventure.',
		hook: 'Embark on a Wild West survival adventure.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/840800/Outlaws_of_the_Old_West/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		latest: false
	},
	{
		key: 'sons-of-the-forest',
		name: 'Sons of the Forest',
		keywords: 'the forest survival horror open-world sons of',
		backgroundImage: "gamebg-default.webp",
		extraClassName: '',
		sortTags: '',
		description: 'Sent to find a missing billionaire on a remote island, you find yourself in a cannibal-infested hellscape. Craft, build, and struggle to survive, alone or with friends, in this terrifying new open-world survival horror simulator.</br></br>An entirely new experience from the makers of ‘The Forest,’ Sons of the Forest thrusts you into a relentless struggle for survival amidst a hauntingly immersive environment. Experience the fear of the unknown as you face mutated creatures and desperate adversaries.',
		hook: 'Craft, build, and fight for survival in this terrifying open-world simulator.',
		purchaseOn: 'steam',
		storeLink: 'https://store.steampowered.com/app/1326470/Sons_Of_The_Forest/',
		youtubeLink: '',
		tabs: [TABS.FEATURES, TABS.DDOS_PROTECTION, TABS.GAME_PANEL, TABS.HPGS_HARDWARE, TABS.LOCATIONS, TABS.SUPPORT, TABS.FAQ, TABS.MODPACKS],
		earlyaccess: true,
		latest: false
	},
];

export default gamesData;