import * as $ from 'jquery';

export function slideUp(selector: string, speed: 'slow' | 'fast') {
    if(speed === 'fast') {
        $(selector).addClass('fast');
    }

    $(selector).addClass('closed');

    return new Promise<string>( function(resolve, reject) {
        setTimeout(() => {
            $(selector).removeClass('fast');
            $(selector).addClass('d-none');
            resolve('closed d-none');
        }, speed === 'slow' ? 300 : 150);
    });
}

export function slideDown(selector: string, speed: 'slow' | 'fast') {
    $(selector).removeClass('d-none');

    setTimeout(() => { 
        if(speed === 'fast') {
            $(selector).addClass('fast');
        }
        
        $(selector).removeClass('closed');
    }, 10);

    return new Promise<string>( function(resolve, reject) {
        setTimeout(() => {
            $(selector).removeClass('fast');
            resolve('');
        }, speed === 'slow' ? 290 : 140);
    });
}

export function fadeOut(selector: string, speed: 'slow' | 'fast') {
    if(speed === 'fast') {
        $(selector).addClass('fast');
    }

    $(selector).addClass('hide');

    return new Promise<string>( function(resolve, reject) {
        setTimeout(() => {
            $(selector).removeClass('fast');
            $(selector).addClass('d-none');
            resolve('hide d-none');
        }, speed === 'slow' ? 300 : 150);
    });
}

export function fadeIn(selector: string, speed: 'slow' | 'fast') {
    if(speed === 'fast') {
        $(selector).addClass('fast');
    }
    
    $(selector).removeClass('d-none');
    
    return new Promise<string>( function(resolve, reject) {
        setTimeout(() => {
            $(selector).removeClass('fast');
            resolve('');
        }, speed === 'slow' ? 290 : 140);
    });
}