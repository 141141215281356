import React from 'react';
import Travel from '../layouts/Core/Travel';
import { IGame } from './GamesList';
import { useGameImage } from './GameImages';
import { STANDARD_ROUTE_URIS } from '../routes/Router';
import { useSharedCurrencyState } from '../layouts/Core/HeroBanner';

interface GameCardProps {
  game: IGame;
}

const GameCard: React.FC<GameCardProps> = ({ game }) => {
  const { currencySign } = useSharedCurrencyState();

  // Extract the app ID from the store link
  const appId = game.storeLink ? game.storeLink.match(/\/app\/(\d+)/)?.[1] : '';
  const { image, loading } = useGameImage(appId || '', game.boxImage);

  // Determine the image to use
  const backgroundImageUrl = game.boxImage && !game.boxImage.startsWith('http')
    ? require(`../assets/images/games/${game.boxImage}`)
    : loading || !image
    ? ''
    : image.url;

  return (
    <div className={`col col-lg-25 gridcol ${game.extraClassName} ${game.sortTags}`}>
      <Travel
        to={STANDARD_ROUTE_URIS.GAME_SERVERS_INNER.replace(':game', game.key === undefined ? '' : game.key)}
        className="game-box"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
        }}
      >
        <div className="game-box-bottom font-14">
          <h5 className="game-name">{game.name}</h5>
          {game.missing ? (
            <span className="block">Out of Stock</span>
          ) : game.comingsoon ? (
            <span className="block">Coming Soon</span>
          ) : game.stock ? (
            <span className="block">
              Starting at <span className="green">{currencySign}{game.startingAt}</span>/month
            </span>
          ) : (
            <span className="block">Out of Stock</span>
          )}
          <span className="learn-more">
            LEARN MORE<i className="fa-regular fa-chevrons-right ml-1 font-12"></i>
          </span>
          <span className="extra-keywords">{game.keywords}</span>
        </div>
      </Travel>
    </div>
  );
};

export default GameCard;
