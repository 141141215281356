import { useEffect } from 'react';
import * as $ from 'jquery';
import Container from '../layouts/Core/Container';
import Travel from '../layouts/Core/Travel';

import gslMark from '../assets/images/globalsecurelayer-mark.svg'
import ddosShield from '../assets/images/ddos-shield.svg';

 export default function AboutPage() {

    return (
        <Container title='Streamline Servers - About' description='All about Streamline Servers and our History. We have been hosting since 2007, so we have a lot of experience!' className='page-about'>
            <section className="text-center sub-hero pb-5" id="hero-about">
                <div className="container">
                    <h1>About Us</h1>
                    <h3 className="green font-24 pt-1 pb-1">Fast, Secure, & Affordable Hosting Since 2007</h3>
                    <p className="pb-2">
                    Streamline Servers is a leading provider of high-performance and highly available computing solutions. Since our founding in 2007, we have been delivering enterprise-grade game, dedicated, and web servers to clients across Australia. In 2014, we expanded our services internationally, bringing our world-class solutions to customers around the globe.
					</p>
					<p></p>
					<p className="pb-2">
					Our journey began with a single box in a single location, selling Counterstrike servers. Since then, we have significantly expanded our offerings and now boast an extensive product library and state-of-the-art hardware infrastructure.
					</p>
					<p></p>
					<p className="pb-2">
					At Streamline Servers, we take pride in our commitment to delivering exceptional speed and quality to our customers. Our hardware, network, and support services are unparalleled in the industry, ensuring that our clients have access to the best resources available.
					</p>
					<p></p>
					<p className="pb-2">
					Our mission is guided by a set of core values that are fundamental to everything we do. We believe in providing our clients with the highest level of service, transparency, and honesty. Our team is dedicated to building long-lasting partnerships with our clients and helping them achieve their goals.
					</p>
					<p></p>
					<p className="pb-2">
					We understand that each client's needs are unique, and we strive to be a trusted advisor to each of them. Our experts are always available to provide guidance and support, and we are passionate about helping our clients succeed.
					</p>
				</div>
            </section>

            <section className="pt-0 text-center" id="about-three-features">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 px-4">
                            <div className="icon-relative inline-block">
                            <i className="fa-solid fa-microchip va-mid font-60 mb-4 green-icon grad-behind-icon"></i>
                            <i className="fa-thin fa-microchip va-mid font-60 mb-4 green-icon"></i>
                            </div>
                            <h4>Hardware</h4>
                            <p className="pb-0">
                            Our team find new and innovative ways to bring you the best quality and latest hardware to use for your services.
                            </p>
                        </div>
                        <div className="col col-lg-4 px-4">
                            <div className="icon-relative inline-block">
                            <i className="fa-solid fa-earth-oceania va-mid font-60 mb-4 green-icon grad-behind-icon"></i>
                            <i className="fa-thin fa-earth-oceania va-mid font-60 mb-4 green-icon"></i>
                            </div>
                            <h4>Locations</h4>
                            <p>
                            We service over 20 locations globally, using only the highest quality networks and infrastructure, check out the map section on our game servers page!
                            </p>
                        </div>
                        <div className="col col-lg-4 px-4">
                            <div className="icon-relative inline-block">
                            <i className="fa-solid fa-envelope va-mid font-60 mb-4 green-icon grad-behind-icon"></i>
                            <i className="fa-thin fa-envelope va-mid font-60 mb-4 green-icon"></i>
                            </div>
                            <h4>Support</h4>
                            <p>
                            The Streamline Servers support team pride themselves in resolving queries quickly, if you have any questions for our staff enquire below!
                            </p>
                            <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="bold">Contact Support <i className="fa-sharp fa-solid fa-arrow-right-long ml-1 font-14"></i></a>
                        </div>
                        </div>
                    </div>
                </section>

                <div className="container hidemobile hidetablet">
                    <div className="line-gradient-divider"></div>
                </div>

                <section className="hidemobile hidetablet" id="about-timeline">
                    <div className="container">
                        <div className="title">
                        <h2><small>Our Timeline</small>Company History</h2>
                        </div>

                        <div id="timelineCarousel" className="carousel slide" data-bs-ride="carousel" data-bs-wrap="false">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="row">
                                        <div className="col col-lg-3">
                                            <div className="box-outline">
                                                <h2 className="font-48">2007</h2>
                                                <p className="pt-1 font-14 pb-0">
                                                Our first server, an E5420 in Auckland, New Zealand with the simple goal of providing reliable competitive Counter-Strike servers to teams in the country.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-lg-3">
                                            <div className="box-outline">
                                                <h2 className="font-48">2012</h2>
                                                <p className="pt-1 font-14 pb-0">
                                                Our first international expansion! Moving the core of our service to Sydney Australia, we also started hosting on new hardware, with our new E5667V3 setup.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-lg-3">
                                            <div className="box-outline">
                                                <h2 className="font-48">2013</h2>
                                                <p className="pt-1 font-14 pb-0">
                                                Streamline moved to the TCAdmin Game Panel and greatly expanded our game catalog. We also became one of the first official hosts of Rust!
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-lg-3">
                                            <div className="box-outline">
                                                <h2 className="font-48">2014</h2>
                                                <p className="pt-1 font-14 pb-0">
                                                Increasing our reach in APAC, we start selling services in Singapore. This year we also became the official ARMA 3 PLAYERUNKNOWNS Battle Royal host for Oceania
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="row">
                                        <div className="col col-lg-3">
                                            <div className="box-outline">
                                                <h2 className="font-48">2015</h2>
                                                <p className="pt-1 font-14 pb-0">
                                                With Ark: Survival Evolved launching, we wanted to do something to set ourselves apart, this is the year we launched our <a href="" target="_blank" rel="noreferrer noopener">HPGS</a> range! Running overclocked servers for peak performance.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-lg-3">
                                            <div className="box-outline">
                                                <h2 className="font-48">2016</h2>
                                                <p className="pt-1 font-14 pb-0">
                                                With services in APAC selling fast, we decided to further expand and put our first lot of hardware in Los Angeles. We continued to build our America, while also building out a new look for our <a href="" target="_blank" rel="noreferrer noopener">website!</a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-lg-3">
                                            <div className="box-outline">
                                                <h2 className="font-48">2017</h2>
                                                <p className="pt-1 font-14 pb-0">
                                                While Streamline was showing alot of growth, so were the attacks we were seeing on our network. We decided the quality of the network was our responsibility, and we started to build out our own DDoS mitigation
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col col-lg-3">
                                            <div className="box-outline">
                                                <h2 className="font-48">2018</h2>
                                                <p className="pt-1 font-14 pb-0">
                                                With quality being a priority, we decided to push the performance of our hardware even further. With our team manually setting up water-cooling loops and deliding CPU's, our HPGS Plus (5Ghz) range was born!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                <div className="row">
                                    <div className="col col-lg-3">
                                        <div className="box-outline">
                                            <h2 className="font-48">2019</h2>
                                            <p className="pt-1 font-14 pb-0">
                                            This is the year we released our VDS system, where you can purchase resources on the Game Panel rather than a specific Game Server. This has since been rebranded to Cloud Servers.
                                            </p>
                                        </div>
                                        </div>
                                        <div className="col col-lg-3">
                                        <div className="box-outline">
                                            <h2 className="font-48">2020</h2>
                                            <p className="pt-1 font-14 pb-0">
                                            We decided to spend a year focusing on the network and ensuring that all customers experience the lowest possible latencies. Additionally we further expanded into Europe, establishing our own hardware presence in countries such as Germany and the Netherlands
                                            </p>
                                        </div>
                                        </div>
                                        <div className="col col-lg-3">
                                        <div className="box-outline">
                                            <h2 className="font-48">2021</h2>
                                            <p className="pt-1 font-14 pb-0">
                                            We became one of the few exlusive hosts for Hell Let Loose, this also helped us with a big push and expansion into Tokyo, Japan where our services became very popular quickly.
                                            </p>
                                        </div>
                                        </div>
                                        <div className="col col-lg-3">
                                        <div className="box-outline">
                                            <h2 className="font-48">2022</h2>
                                            <p className="pt-1 font-14 pb-0">
                                            This year we did a HUGE overhaul of our Game Panel, completely customizing TCAdmin with new themes, built in scripts for Steam Workshop and mod managers for various games and updated performance in the back end.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#timelineCarousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#timelineCarousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                        </div>
                    </div>
                </section>
                <section className="light-bg" id="about-core-features">
                <div className="container">
                    <div className="title">
                    <h2><small>A bit of History</small>Walk Through our Past</h2>
                    <p>
                        Our Evolution through Old Websites and Logos
                    </p>
                    </div>

                    <div className="padded-row pt-3 clearfix">
                    <div id="carouselCoreValues" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row">
                            <div className="col col-lg-6">
                                <img src={require("../assets/images/about-slide-1.webp")} className="responsive-img" />
                            </div>
                            <div className="col col-lg-6 pl-5 plfix">
                                <h2 className="pt-4 ptfix mt-3 mtfix">The Streamline Robot</h2>
                                <h4 className="font-20">Our most beloved mascot</h4>
                                <p className="pt-3">
                                What started out as a sketch became a part of the early logo of the company. We have now immortalized the Streamline Robot, featuring him in every revision of our website!
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                            <div className="col col-lg-6">
                                <img src={require("../assets/images/about-slide-2.webp")} className="responsive-img" />
                            </div>
                            <div className="col col-lg-6 pl-5 plfix">
                                <h2 className="pt-4 ptfix mt-3 mtfix">2007 Website</h2>
                                <h4 className="font-20">Back when Windows XP was best</h4>
                                <p className="pt-3">
                                Streamline Servers' original website, created in 2007, had a simple design, displayed essential services, and embodied the web style of its time.
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                            <div className="col col-lg-6">
                                <img src={require("../assets/images/about-slide-3.webp")} className="responsive-img" />
                            </div>
                            <div className="col col-lg-6 pl-5 plfix">
                                <h2 className="pt-4 ptfix mt-3 mtfix">2011 Website</h2>
                                <h4 className="font-20">We just started growing beyond game servers</h4>
                                <p className="pt-3">
                                This screenshot captures one of Streamline's original websites, marking the first appearance of our Robot Mascot. As you can see from the information displayed, we have come a long way from the initial focus on "8 games" and the simplicity of the original site. Our progress and growth have been significant.
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                            <div className="col col-lg-6">
                                <img src={require("../assets/images/about-slide-4.webp")} className="responsive-img" />
                            </div>
                            <div className="col col-lg-6 pl-5 plfix">
                                <h2 className="pt-4 ptfix mt-3 mtfix">2015 Website</h2>
                                <h4 className="font-20">Our longest running website</h4>
                                <p className="pt-3">
                                This image showcases our previous website, which was built approximately 8 years ago and followed a common theme among Game Server sellers. However, our entire team unanimously agreed that it could be overwhelming at times, resembling a 'flashbang.' As a result, we made the decision to modernize the website with a new, darker look.
                                </p>
                            </div>
                            </div>
                        </div>

                        </div>
                        <div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselCoreValues" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselCoreValues" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                        </div>
                        <ul className="carousel-indicators" id="core-values-indicators">
                        <li>
                            <a href="javascript:void(0);" data-bs-target="#carouselCoreValues" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1">
                            <div className="icon-relative inline-block">
                                <i className="fa-solid fa-robot va-mid font-48 grad-behind-icon"></i>
                                <i className="fa-thin fa-robot va-mid font-48"></i>
                            </div>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" data-bs-target="#carouselCoreValues" data-bs-slide-to="1" aria-label="Slide 2">
                            <div className="icon-relative inline-block">
                                <i className="fa-solid fa-window va-mid font-48 grad-behind-icon"></i>
                                <i className="fa-thin fa-window va-mid font-48"></i>
                            </div>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" data-bs-target="#carouselCoreValues" data-bs-slide-to="2" aria-label="Slide 3">
                            <div className="icon-relative inline-block">
                                <i className="fa-solid fa-browser va-mid font-48 grad-behind-icon"></i>
                                <i className="fa-thin fa-browser va-mid font-48"></i>
                            </div>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" data-bs-target="#carouselCoreValues" data-bs-slide-to="3" aria-label="Slide 4">
                            <div className="icon-relative inline-block">
                                <i className="fa-solid fa-browsers va-mid font-48 grad-behind-icon"></i>
                                <i className="fa-thin fa-browsers va-mid font-48"></i>
                            </div>
                            </a>
                        </li>
                        </ul>
                    </div>

                    </div>
                </div>
                </section>

                <section className="pb-5" id="about-story">
                    <div className="container py-3">
                        <div className="row">
                            <div className="col col-lg-8 pr-5 prfix pt-3 ptfix">
                                <h2><small>OUR STORY</small>More About Us</h2>
                                <p className="pt-1">
                               Streamline Servers was founded by a group of avid gamers who were frustrated by the lack of quality gaming servers available in the Oceanic region. They understood the importance of having reliable and high-performance servers to ensure a seamless gaming experience, especially for competitive gaming. Their focus was not only on the business aspect but also on catering to the needs of gamers.
                                </p>
                                <p>
                                While the company has expanded to include servers for community-based games, the commitment to quality has remained unchanged. Streamline Servers takes pride in delivering top-notch services, ensuring that even the most competitive gamers can rely on them..
                                </p>
								<p>
								The company is dedicated to supporting competitive gaming, regardless of the level. From hosting professional national tournaments like LetsPlayLive to high school competitions like Flaktest, Streamline Servers provides exceptional services to meet the needs of all gamers. The company's priority has always been and will continue to be delivering outstanding performance, because that's what gamers deserve.
								</p>
                            </div>
                            <div className="col col-lg-4 text-center pt-5 ptfix">
                                <picture>
                                    <source media="1x" srcSet={require('../assets/images/old-logo.webp')}/>
                                    <source media="2x" srcSet={require('../assets/images/old-logo@2x.webp')}/>
                                    <img src={require('../assets/images/old-logo.webp')} className="mt-5 pt-3 mtfix"/>
                                </picture>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5 light-bg" id="ddos-row">
                <div className="container py-3">
                    <div className="row">
                    <div className="col col-lg-4 text-center">
                        <img src={ddosShield} className="mobile-icon-height" />
                    </div>
                    <div className="col col-lg-8 pt-4 mt-2 mtfix ptfix">
                        <h2 className="pt-5 ptfix"><small>INCLUDED DDOS PROTECTION</small>Make Sure Your Server is Always Online!</h2>
                        <p className="pt-1">
                        Streamline-Servers has formed a strategic partnership with Global Secure Layer, a renowned cybersecurity firm known for their latest DDoS technology. This collaboration empowers us to offer our clients robust protection against DDoS attacks by integrating Global Secure Layer's cutting-edge mitigation solution into our infrastructure. Together, we ensure uninterrupted availability and optimal performance of our clients' online services, leveraging advanced technologies to swiftly detect and mitigate malicious traffic. Through this partnership, Streamline-Servers reaffirms its commitment to delivering top-tier DDoS Protection solutions and providing clients with the peace of mind they deserve.
                        </p>
                        <strong className="font-18 block pb-1 pt-2">DDoS Protection by <img src={gslMark} className="ml-1 mr-1 gsl-mark-sm" />Global Secure Layer</strong>
                        <a href="https://globalsecurelayer.com/ddos-protection" className="strong">Find our more <i className="fa-sharp fa-solid fa-chevrons-right font-11"></i></a>
                    </div>
                    </div>
                </div>
                </section>

                <section className="green-bg bg-cta-squares" id="cta">
                    <div className="container">
                        <div className="row">
                        <div className="col col-lg-6">
                            <h2>Join Streamline Servers Today!</h2>
                            <strong className="block semibold pb-4 font-22">We strive to be the best host possible, always adding more products and hardware to our infrastructure.</strong>
                            <a href="https://billing.streamline-servers.com/register.php" className="button dark large mt-1">CREATE ACCOUNT <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></a>
                        </div>
                        <div className="col col-md-4 col-lg-5 ml-auto pt-4 ptfix">
                            <ul className="font-18 pt-1" id="support-methods-list">
                            <li className="py-2"><Travel to="/game-servers" className="white-link bold"><i className="fa-light fa-gamepad font-20 va-mid mr-2"></i> Game Servers</Travel></li>
                            <li className="py-2"><Travel to="/cloud-gaming-servers" className="white-link bold"><i className="fa-light fa-cloud font-20 va-mid mr-2"></i> Cloud Gaming Servers</Travel></li>
                            <li className="py-2"><Travel to="/dedicated-servers" className="white-link bold"><i className="fa-light fa-server font-20 va-mid mr-2"></i> Dedicated Servers</Travel></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </section>
        </Container>
     );
 }
