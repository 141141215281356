import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import the useLocation hook
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";

import '../../assets/css/bootstrap.css';
import '../../assets/css/fontawesome.css';
import '../../assets/css/style.css';
import '../../assets/css/992.css';
import '../../assets/css/768.css';
import '../../assets/css/320.css';

import favicon from '../../assets/images/favicon.svg';

interface IProps {
    title: string;
    description: string;
}

export default function PageHead(props: IProps) {
    const location = useLocation(); // Get the current location

    useEffect(() => {
        ReactGA.initialize("G-CR10R9D18F");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: props.title });
    }, [props.title]);

    useEffect(() => {
        // Scroll to the top of the page whenever the pathname changes
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="keywords" content='Servers, Game Servers, Dedicated Servers, Server Host, DDoS Protection, Web Hostings, Voice Servers, Cloud Game Servers, Valheim Server, Arma 3 Server, Ark Server, Rust Server'/>
                <meta name="description" content={props.description}/>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5, viewport-fit=cover"/>

                <meta property="og:title" content={props.title} />
                <meta property="og:description" content={props.description} />
                <meta name="twitter:title" content={props.title} />
                <meta name="twitter:description" content={props.description} />

                <link rel="icon" href={favicon}/>

                <title>{props.title}</title>

                <link href="https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,600;1,700&display=swap" rel="stylesheet"/>

                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"/>

                <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.3/jquery.min.js" integrity="sha512-STof4xm1wgkfm7heWqFJVn58Hm3EtS31XFaagaa8VMReCXAkQnJZ+jEy8PCC/iT18dFy95WcExNHFTqLyp72eQ==" crossOrigin="anonymous" referrerPolicy="no-referrer"/>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-/bQdsTh/da6pkI1MST/rWKFNjaCP5gBSY4sEBT38Q/9RBh9AH40zEOg7Hlq2THRZ" crossOrigin="anonymous"/>
                <script src="https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.min.js"></script>
            </Helmet>
            <CookieConsent
                location="bottom"
                buttonText="Got It!"
                cookieName="StreamlineServersWebsite"
                style={{ background: "#111", borderTop: "1px solid #333" }}
                buttonStyle={{ color: "#fff", background: "#7cc214", fontSize: "15px", marginRight: "110px", width: "150px", fontWeight: "bold", borderRadius: "4px" }}
                expires={150}
            >
                Streamline Servers uses cookies to provide a tailored experience on our website.{" "}
                <span style={{ fontSize: "14px", marginLeft: "5px" }}><a href="/legal">Learn more</a></span>
            </CookieConsent>
        </>
    );
}