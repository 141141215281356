import { useEffect, useMemo, useState } from 'react';
import * as $ from 'jquery';
import { fadeIn, fadeOut, slideDown, slideUp } from '../helpers/animations';

import Container from '../layouts/Core/Container';

import { TrustPilot } from '../layouts/TrustPilot';

import { useTawkContext } from '../layouts/TawkContext';

import Games, { useSharedSearchState } from '../layouts/Games';
import TrendingGames from '../layouts/GamesTrending';
import LatestGames from '../layouts/GamesLatest';
import { STANDARD_ROUTE_URIS } from '../routes/Router';
import Travel from '../layouts/Core/Travel';
import { Nav, Tab } from 'react-bootstrap';

export default function GameServersPage() {
    const [searchFilterBuffer, setSearchFilterBuffer] = useState("");
    const [searchOrigin, setSearchOrigin] = useState("bottom");
    const [topGamesPane, setTopGamesPane] = useState("trending");

    const {search, setSearch, filter, setFilter } = useSharedSearchState();
    const games = useMemo(() => <Games/>, []);
	const trendinggames = useMemo(() => <TrendingGames/>, []);
	const latestgames = useMemo(() => <LatestGames/>, []);

    const [slideClasses, setSlideClasses] = useState('');
    const [fadeClasses, setFadeClasses] = useState('hide d-none');
	
	const { handleMaximize } = useTawkContext();

    useEffect(() => {
        const delaySearch = setTimeout(() => {
            // Show top parts if search origin bottom OR search is empty
            if(searchOrigin === 'bottom' || searchFilterBuffer.length === 0) {
                fadeOut('.filterselect-main', 'fast').then(function(x) {
                    setFadeClasses(x);
                });

                slideDown('.hidebigsearch', 'slow').then(function(x) {
                    setSlideClasses(x);
                });

            }
            else if(searchOrigin === 'top' && searchFilterBuffer.length !== 0) {
                fadeIn('.filterselect-main', 'fast').then(function(x) {
                    setFadeClasses(x);
                });

                slideUp('.hidebigsearch', 'slow').then(function(x) {
                    setSlideClasses(x);
                });
            }
            else {
                slideDown('.hidebigsearch', 'slow').then(function(x) {
                    setSlideClasses(x);
                });

                fadeOut('.filterselect-main', 'fast').then(function(x) {
                    setFadeClasses(x);
                });
            }

            setSearch(searchFilterBuffer);

        }, 300)
    
        return () => clearTimeout(delaySearch)
      }, [searchFilterBuffer]);

    return (
        <Container title='Streamline Servers - Game Server Hosting' description='Whether it’s for a competitive match, or just a casual game server with friends. Streamline-Servers has the perfect game server hosting package for you!' className='page-game-servers'>
            <section className="text-center sub-hero" id="hero">
                <div className="container">
                    <h1>Game Servers</h1>
                    <p className="pb-2">
                    Whether it’s for a competitive match, or just a casual game server with friends.
					<br />
					Streamline-Servers has the perfect game server package for you!
                    </p>
                    <div className="inline-block relative mt-5 mb-n1 game-search-container">
                    <input type="text" value={searchFilterBuffer} onChange={e => { setSearchOrigin("top"); setSearchFilterBuffer(e.target.value)}} placeholder="Enter your game in here..." className="game-search quicksearchbig" />
                    </div>
                    <div className={`block mt-5 hidebigsearch ${slideClasses}`} id="games-trustpilot">
                        <span className="tp-line-one">
                        <span className="inline-block font-18 pr-2 hidemobile">We're Rated</span>
                        <h3 className="inline-block">"{TrustPilot.Rating}"</h3>
                        <a href={TrustPilot.URL}><img src={TrustPilot.trustpilotStarsHome} className="inline-block va-mid mx-3 tp-stars" /></a>
                        </span>
                        <strong className="inline-block">{TrustPilot.Reviews} reviews on</strong>
                        <a href={TrustPilot.URL}><img src={TrustPilot.trustpilotLogoHome} className="inline-block va-mid ml-2 tp-logo" /></a>
                    </div>
                </div>
            </section>

            <section className={`pt-0 hidebigsearch ${slideClasses}`} id="games-tabs">
                <div className="container text-center pb-5 mt-n2 pbfix">
                    <Tab.Container defaultActiveKey={"trending"}>
                        <Nav style={{display: 'block'}} role='tablist' className="text-center pbfix lined-tabs">
                            <Nav.Item className="inline-block">
                                <Nav.Link role="tab" eventKey={"trending"}>
                                    <i className="fa-regular fa-chart-line-up mr-1"></i> Trending Games
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="inline-block">
                                <Nav.Link role="tab" eventKey={"latest"}>
                                <i className="fa-regular fa-plus mr-1"></i> Latest Games                                    
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="tab-content" id="nav-format-tabContent">
                            <Tab.Pane eventKey={"trending"} role="tabpanel">
								{trendinggames}
                            </Tab.Pane>
                            <Tab.Pane eventKey={"latest"} role="tabpanel">
								{latestgames}
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>

            <section className="text-center pt-0" id="games-all-games">
                <div className="container">
                    <div className={`hidebigsearch pb-5 ${slideClasses}`}>
                        <h2 className="font-30">Browse our entire game catalog below</h2>
                        <div className="row mt-4 w-66 m-w-100 mx-auto">
                            <div className="col">
                            <input type="text" onChange={e => {setSearchOrigin("bottom"); setSearchFilterBuffer(e.target.value)} } value={searchFilterBuffer} placeholder="Find your game below" className="games-input quicksearch" id="gamesearch" />
                            </div>
                        </div>
                    </div>

                    {games}

                    <div className="block pt-5">
                        <strong className="block">Is your game not listed here?</strong>
                        <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="bold">Contact us</a> and we’ll see if we’re able to help.
                    </div>
                </div>
            </section>


            <section className="green-bg bg-cta-game-list" id="cta">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-8 col-lg-8 col-xl-6">
                        <h2>Deploy your game server!</h2>
                        <strong className="block pb-4 font-22">Not sure if this is right for you? Contact Us!</strong>
                        <ul className="cta-link-list">
                        <li className="inline-block pr-3">
                            <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="white-link"><i className="fa-solid fa-envelope font-16 mr-1"></i>Contact Us</a>
                        </li>
                        <li className="inline-block pr-3">
                            <a href="javascript:void(0);" onClick={handleMaximize} className="white-link"><i className="fa-solid fa-comments font-16 mr-1"></i> Sales Live Chat</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col col-md-4 col-lg-6 text-center">
                        <Travel to="/game-servers" className="button large dark mt-5">Back to Top <i className="fa-sharp fa-solid fa-arrow-up-long ml-1"></i></Travel>
                    </div>
                    </div>
                </div>
            </section>
        </Container>
    );
}