import axios from 'axios';
import { useEffect, useState } from 'react';

interface GameImage {
  url: string;
  mime: string;
}

const PROXY_URL = 'http://billing.streamline-servers.com/steamgriddb-proxy.php';

const generateImageUrl = (appId: string): GameImage => {
  return {
    url: `https://steamcdn-a.akamaihd.net/steam/apps/${appId}/library_600x900_2x.jpg`,
    mime: 'image/jpeg'
  };
};

const generateLogoUrl = (appId: string): GameImage => {
  return {
    url: `https://steamcdn-a.akamaihd.net/steam/apps/${appId}/logo.png`,
    mime: 'image/png'
  };
};

const fetchLogoFromProxy = async (appId: string): Promise<GameImage | null> => {
  try {
    const response = await axios.get(`${PROXY_URL}?type=logos&appId=${appId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const logos = response.data.data;
    if (!logos || logos.length === 0) {
      return null;
    }

    // Look for static png logos
    const staticPngLogo = logos.find((img: any) => img.mime === 'image/png');
    if (staticPngLogo) {
      return { url: staticPngLogo.url, mime: staticPngLogo.mime };
    }

    return null;
  } catch (error) {
    console.error('Error fetching logo from proxy:', error);
    return null;
  }
};

const fetchGameImageFromProxy = async (appId: string): Promise<GameImage | null> => {
  try {
    const response = await axios.get(`${PROXY_URL}?type=grids&appId=${appId}&dimensions=600x900&mimes=image/png&types=static`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const images = response.data.data;
    if (!images || images.length === 0) {
      return null;
    }

    // Look for static png images
    const staticPngImage = images.find((img: any) => img.mime === 'image/png' && img.width === 600 && img.height === 900);
    if (staticPngImage) {
      return { url: staticPngImage.url, mime: staticPngImage.mime };
    }

    return null;
  } catch (error) {
    console.error('Error fetching game image from proxy:', error);
    return null;
  }
};

export const useGameImage = (appId: string, boxImage?: string) => {
  const [image, setImage] = useState<GameImage | null>(null);
  const [logo, setLogo] = useState<GameImage | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (boxImage && boxImage.startsWith('http')) {
      setImage({ url: boxImage, mime: 'image/png' });
      setLoading(false);
      return;
    }

    if (appId) {
      const fetchImage = async () => {
        // First, check if the Steam library image exists
        const steamImageUrl = generateImageUrl(appId);
        const img = new Image();
        img.src = steamImageUrl.url;
        img.onload = () => {
          setImage(steamImageUrl);
          setLoading(false);
        };
        img.onerror = async () => {
          // If the Steam library image doesn't exist, fetch from proxy
          const proxyImage = await fetchGameImageFromProxy(appId);
          if (proxyImage) {
            setImage(proxyImage);
            setLoading(false);
          } else {
            setLoading(false); // No image available
          }
        };
      };

      fetchImage();
    }
  }, [appId, boxImage]);

  useEffect(() => {
    if (appId) {
      const fetchLogo = async () => {
        // First, check if the Steam logo exists
        const steamLogoUrl = generateLogoUrl(appId);
        const logoImg = new Image();
        logoImg.src = steamLogoUrl.url;
        logoImg.onload = () => {
          setLogo(steamLogoUrl);
          setLoading(false);
        };
        logoImg.onerror = async () => {
          // If the Steam logo doesn't exist, fetch from proxy
          const proxyLogo = await fetchLogoFromProxy(appId);
          if (proxyLogo) {
            setLogo(proxyLogo);
            setLoading(false);
          } else {
            setLoading(false); // No logo available
          }
        };
      };

      fetchLogo();
    }
  }, [appId]);

  return { image, logo, loading };
};
