import { useEffect, useState } from 'react';
import Container from '../layouts/Core/Container';
import Travel from '../layouts/Core/Travel';

import $ from 'jquery';

import bullet from '../assets/images/bullet.svg';

export default function LegalPage() {

    const TABS = {
        TOS: 'tos',
        AUP: 'aup',
        PP: 'pp'
    };

    const [selectedTab, setSelectedTab] = useState(TABS.TOS);

    useEffect(() => {
        var $window = $(window),
        $mainMenuBar = $('#green-bar-nav'),
        $mainMenuBarAnchor = $('#greenBarAnchor');

        $window.on('scroll', function() {
            // Make the green menu bar stick when we scroll past it.
            var window_top = $window.scrollTop();
            var div_top = $mainMenuBarAnchor.offset().top;
            if (window_top > div_top) {
                // Make the div sticky.
                $mainMenuBar.addClass('stick');
                $mainMenuBarAnchor.height($mainMenuBar.height());
            } else {
                // Unstick the div.
                $mainMenuBar.removeClass('stick');
                $mainMenuBarAnchor.height(0);
            }
        });
		
		// Check the hash on initial load and set the selected tab accordingly
		const hash = window.location.hash.slice(1); // Get the hash from the URL, remove the leading '#'
		if (Object.values(TABS).includes(hash)) {
			setSelectedTab(hash);
		}

		// Listen for hash changes and update the selected tab accordingly
		const handleHashChange = () => {
			const hash = window.location.hash.slice(1); // Get the hash from the URL, remove the leading '#'
			if (Object.values(TABS).includes(hash)) {
				setSelectedTab(hash);
			}
		};

		window.addEventListener('hashchange', handleHashChange);

        return () => {
            $window.off('scroll');
			window.removeEventListener('hashchange', handleHashChange);
        };
    }, [TABS]);
	
	const handleTabClick = (tab) => {
		setSelectedTab(tab);
		window.scrollTo(0, 0); // Scroll to the top when a tab is clicked
	};

    return (
        <Container title='Streamline Servers - Legal' description='Streamline Servers Terms of Service, Privacy Policy and Acceptable Use Policy' className='page-legal'>
            <section className="sub-hero" id="hero">
                <div className="container">
                        <h1>Legal</h1>
                        <h4 className="font-22 pt-1">What you can and can't do at Streamline Servers.</h4>
                </div>
            </section>

            <div id="greenBarAnchor"></div>
            <div className="green-bar-nav" id="green-bar-nav">
                <div className="container">
                    <ul id="gameserver-nav" role="tablist">
                        <li><a href="#tos" id="tos-tab" style={{cursor: 'pointer'}} role="tab" aria-controls="tos" onClick={() => handleTabClick(TABS.TOS)} aria-selected={`${TABS.TOS === selectedTab ? 'true' : 'false'}`} className={`${TABS.TOS === selectedTab && 'active'} font-16`}>Terms of Service</a></li>
                        <li><a href="#pp" id="pp-tab" style={{cursor: 'pointer'}} role="tab" aria-controls="pp" onClick={() => handleTabClick(TABS.TOS)} aria-selected={`${TABS.PP === selectedTab ? 'true' : 'false'}`} className={`${TABS.PP === selectedTab && 'active'} font-16`}>Privacy Policy</a></li>
						<li><a href="#aup" id="aup-tab" style={{cursor: 'pointer'}} role="tab" aria-controls="aup" onClick={() => handleTabClick(TABS.TOS)} aria-selected={`${TABS.AUP === selectedTab ? 'true' : 'false'}`} className={`${TABS.AUP === selectedTab && 'active'} font-16`}>Acceptable Use Policy</a></li>
                    </ul>
                </div>
            </div>

            <section className="text-page" id="legal-text">
                <div className="container">
                    <div className="padded-row-big">
                        <div className="tab-content">
                            {TABS.TOS === selectedTab && <div className="tab-pane active" id="tos">
                                <h2>Terms of Service</h2>
								<h5 className="opacity-50 mb-4">Last Updated 4th July 2015</h5>
                                <p>Streamline Servers provides game servers &amp; dedicated servers, we have a responsibility to protect each client and to provide quality services. All clients of Streamline Servers are subject to the following terms of service:</p>
                                <p>You agree that Streamline Servers may modify this agreement and the conditions at anytime without prior contact. You agree to be bound by this agreement, and should periodically review the Terms of Service to ensure they meet your needs. Streamline may be referred to as 'we', 'us' or any first-person pronouns. These Terms and Conditions apply to you if you have purchased services from Streamline Servers or if you play on any of our public servers.</p>
                                <h4>Service Level Agreement</h4>
                                <p>Streamline Servers will endeavour to provide a 100% uptime and are able to most of the time, we offer a service level guarantee to our clients of 99.95% up time per month, this is to allow for maintenance on services. The uptime agreement excludes third party aspects such as Control Panel issues &amp; Game Server FTP issues that may be encountered during the month. If we fail to achieve this agreement, clients affected will receive a percentage of credit towards the following month.</p>
                                <h4>Duration of Contract</h4>
                                <p>The duration of your contract and services provided will align with the period for which you have made payment (monthly, quarterly, semi-annually or annually). For example, if you order and pay for a 'monthly' service on the 15th of April 2023, your service will expire on the 15th of May 2023 (unless payment is received to renew the service). You will be invoiced 14 days before the renewal date, failure to settle this invoice will result in the service being suspended, unless an extension is approved (at Streamline's sole discretion). Should payment not be received by 30 days after suspension or any extended due date, all files will be deleted and may not be recoverable after this period. We reserve the right to terminate your contract/service at any time with Streamline Servers if you are found to have breached the Terms and Conditions.</p>
                                <h4>Methods of Payment</h4>
                                <p>Payments to Streamline Servers are performed via PayPal Gateway. Using the PayPal gateway ensures that we don't keep on file and payment credentials including but not limited to (bank details, credit card details). Payment via this Gateway allows users to pay via their credit card, bank card and other payment methods PayPal provides.</p>
                                <h4>Billing Disputes / ChargeBacks</h4>
                                <p>Any disputes relating to charges must be reported directly to us within 30 days of the charge occurring.</p>
                                <p>If a billing dispute / chargeback such as but not limited to PayPal dispute / chargeback is raised, the billing account will be suspended until the dispute / chargeback has been resolved. This includes all services associated with the billing account.</p>
                                <h4>Refunds</h4>
                                <p>Streamline Servers provides a 48 hour money back guarantee. This offer isn't valid under the following circumstances and products</p>
                                <ul>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Due to scheduled downtime or maintenance</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Due to malicious attacks against your server resulting in downtime</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Due to a suspended service for reasons stated in the terms of service</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Dedicated Servers</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Virtual Private Servers</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Domain Registrations</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Domains or domain related services</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Due to Alpha / Beta related crashing on game services</li>
                                </ul>
                                <p>Payments made through the Public Pay system are NON-REFUNDABLE. By submitting payment to this client, you agree to our Terms of Service and that the client has shared his / her Public Pay ID to accept payments.</p>
                                <p>Payments made via cryptocurrencies including but not limited to Bitcoin are non-refundable to external accounts. Such cryptocurrency payments can only be refunded to account credit on Streamline Servers Limited.</p>
                                <p>Payments made via PoliPay are non-refundable to external bank accounts. Such payments can only be refunded to account credit on Streamline Servers Limited.</p>
                                <p>Credit on your Streamline Servers account can’t be refunded / withdrawn to an external account such as PayPal.</p>
                                <p>The Company uses the third party PayPal payment gateway provided by PayPal for its secure online payment transactions. Payments made through PayPal are subject to PayPal’s own terms and conditions and privacy policy, links to which are provided from the PayPal checkout pages. For more information about PayPal, see the PayPal website (http://www.paypal.com)</p>
                                <br/>
                                <h4>PublicPay Donations</h4>
                                <p>We offer you the ability to seek donations to assist with the costs of the services we may provide to you, by PublicPay donations. Any payments made through this service are non-refundable and nontransferable. Any payment a donator may make through this service may be used by the account owner as payment towards any service associated with their account, and we have no ability to limit or restrict this. Should a donator make any payment through this service, there is no relationship formed between the Company and the donator, nor will that donated payment give the donator any rights or remedies against us, or the account holder.</p>
                                <p>Any funds donated through this system, will be credited to your account, and are non-transferable, and are not redeemable for cash or otherwise. All donations made through this system must otherwise comply with the terms of service provided by PayPal.</p>
                                <br/>
                                <h4>Level of Support</h4>
                                <p>Our support team will endeavor to assist you as soon as possible, to the best of their ability and knowledge<br/>All services unless specifically referenced are self managed. We are not required to install or assist in the installation of (but not limited to):</p>
                                <ul>
                                <li><img src={bullet} className="va-mid mr-2"/>  Plugins / Mods</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  Configuration Files</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  Models / Materials</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  Maps</li>
                                </ul>
                                <p>Streamline Servers limited reserves the right to terminate any service and or account if you are abusive and or disrespectfully towards a member of staff</p>
                                <br/>
                                <h4>Banned Scripts</h4>
                                <p>The following scripts are banned from use on any of our services; the installation of these scripts will result in the immediate termination of service.</p>
                                <ul>
                                    <li><img src={bullet} className="va-mid mr-2"/>  IRC egg drops</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Proxy servers</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Mail bombers</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Anonymous mailers</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  IP spoofers</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Port scanners</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Hivemail</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Telnet or SSH Access Scripts</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  UBB (Ultimate Bulletin Board)</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  lstmrge.cgi</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  phpShell</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  FormMail.cgi, FormMail.pl from Matt's Script Archive</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  PERP role play for GMOD</li>
                                    <li><img src={bullet} className="va-mid mr-2"/>  Grav CMD Line ?PlanetManagerName</li>
                                </ul>
                                <br/>
                                <h4>FTP Restrictions</h4>
                                <p>The following file formats are banned from upload to our services for security reasons.
                                </p><ul>
                                <li><img src={bullet} className="va-mid mr-2"/>  .dll</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  .exe</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  .so</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  .bat</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  .cmd</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  .vbs</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  .pif</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  .scr</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  .com</li>
                                </ul>
                                <br/>
                                <h4>Malicious Attacks</h4>
                                <p>Streamline Servers will not tolerate any malicious attacks attempting to disrupt our services. This includes but is not limited to Denial of Service Attacks (DDos, Dos). If your service is subject to three attacks, we reserve the right to terminate your service without warning.</p>
                                <h4>Resource Usages</h4>
                                <p>Streamline Servers retains the rights to suspend or terminate services for utilizing excessive amounts of resources. This includes but is not limited to:</p>
                                <ul>
                                <li><img src={bullet} className="va-mid mr-2"/>  CPU usage</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  RAM usage</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  Disk usage</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  Disk IO usage</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  Bandwidth usage</li>
                                </ul>
                                <p>Note: Excessive usage is deemed by Streamline Servers technical and management staff. Fair usage amounts are equated by examining the average usage for the same product / service type.</p>
                                <h4>Upgrading / Downgrading</h4>
                                <p>Streamline Servers allows a maximum downgrade of 2GB without prior contact to our support staff. Secondly a downgrade can only be applied after 7 days of the last payment. Failure to do so will result in the transaction being reversed and the original amount reinstated. All credits need to be verified by our support staff before they will be charged onto the account.</p>
                                <h4>Server Addon Usages</h4>
                                <p>Streamline Servers restricts the usage of any addons purchased on our website to services hosted with Streamline Servers. Any clients found utilizing such services outside of our network will result in immediate termination. This includes but is not limited to:</p>
                                <ul>
                                <li><img src={bullet} className="va-mid mr-2"/>  Backup servers</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  FastDL</li>
                                <li><img src={bullet} className="va-mid mr-2"/>  SQL servers</li>
                                </ul>
                                <h4>Registration and account security</h4>
                                <p>Upon registration with this site, you will be issued with a username and password to access your
                                account. You are responsible for maintaining the security of your password for this site. The
                                Company will not be liable for any loss or damage arising from or in connection with your failure to
                                comply with this security obligation. You agree that the Company will be entitled to assume that any
                                person using this site with your username and password is you or your authorised representative.
                                </p>
                                <p>You must notify the Company immediately of any known or suspected unauthorised use of any
                                password or any other breach of security.
                                </p>
                                <h4>Other</h4>
                                <p>All discount codes offered by Streamline Servers are for use only on the first billing cycle (eg one month), unless specifically stated as ‘recurring’ or ‘indefinite’ discount codes.</p>
                            </div>}
                            {TABS.PP === selectedTab && <div className="tab-pane active" id="pp">
                                <h2>Privacy Policy</h2>
								<h5 className="opacity-50 mb-4">Last Updated 4th July 2015</h5>
								<p>This Privacy Policy governs the manner in which Streamline Servers Limited collects, uses, maintains and discloses information collected from users (each, a "User") of the www.streamline-servers.com website ("Site"). This privacy policy applies to the Site and all products and services offered by Streamline Servers Limited.</p>
								<h4>Personal identification information</h4>
								<p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, place an order, fill out a form, or subscribe to the newsletter and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number,</p>
								<p>Users may, however, visit our Site anonymously.</p>
								<p>We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</p>
								<h4>Non-personal identification information</h4>
								<p>We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service provider’s utilized and other similar information.</p>
								<h4>Web browser cookies</h4>
								<p>Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
								<h4>How we use collected information</h4>
								<p>Streamline Servers Limited collects and uses Users personal information for the following purposes:</p>
								<ul>
									<li><img src={bullet} className="va-mid mr-2"/>  To improve customer service</li>
									<li>- Your information helps us to more effectively respond to your customer service requests and support needs.</li>
									<li><img src={bullet} className="va-mid mr-2"/>  To personalize user experience</li>
									<li>- We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</li>
									<li><img src={bullet} className="va-mid mr-2"/>  To improve our Site</li>
									<li>- We continually strive to improve our website offerings based on the information and feedback we receive from you.</li>
									<li><img src={bullet} className="va-mid mr-2"/>  To administer a content, promotion, survey or other Site feature</li>
									<li>- To send Users information they agreed to receive about topics we think will be of interest to them.</li>
									<li><img src={bullet} className="va-mid mr-2"/>  To send periodic emails</li>
								</ul>
								<p>The email address Users provide for order processing, will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries, and/or other requests or questions. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or User may contact us via our Site.</p>
								<h4>How we protect your information</h4>
								<p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.</p>
								<p>Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures.</p>
								<h4>Sharing your personal information</h4>
								<p>We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We may use third party service providers to help us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us your permission.</p>
								<h4>Third party websites</h4>
								<p>Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own terms and policies.</p>
								<h4>Changes to this privacy policy</h4>
								<p>Streamline Servers Limited has the discretion to update this privacy policy at any time. When we do, revise the updated date at the bottom of this page,. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>
								<h4>Your acceptance of these terms</h4>
								<p>By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
								<h4>Contacting us</h4>
								<p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:</p>
								<p>Streamline Servers Limited</p>
								<p>www.streamline-servers.com</p>
								<p>support@streamline-servers.com</p>
                            </div>}
                            {TABS.AUP === selectedTab && <div className="tab-pane active" id="aup">
                                <h2>Acceptable Use Policy - Unmetered Dedicated Servers</h2>
								<h5 className="opacity-50 mb-4">Last Updated 26th May 2023</h5>
								<p>This Acceptable Use Policy (this “AUP”) describes material and activities as well as fair usage of the Unmetered dedicated server plans offered by Streamline Servers. This AUP is not exhaustive, and Streamline Servers reserves the right to take remedial action in connection with content or uses that are not specifically described below. You are responsible for violations of this AUP by Your Users, including anyone using your account with your permission or on an unauthorized basis.</p>
								<p>This policy is designed to protect the quality and integrity of the Streamline Servers network and in doing so ensure all customers on the unmetered dedicated server plans receive fair and equitable access. We may rely on our Fair Use Policy in circumstances where your usage that forms part of the unmetered plan is deemed to be 'unreasonable' or 'excessive' as defined by us.</p>
								<p>We retain the right to vary the terms of the Fair Use policy at our discretion.</p>
								<h4>Unmetered Plan Policies</h4>
								<p>In order to maintain our unmetered plans at a competitive price, there are some policies in place. If you are found to be breaching these policies below, your service may be placed into a deprioritized network queue.</p>
								<ul>
									<li><img src={bullet} className="va-mid mr-2"/>  Intentional needless consumption or abuse in an attempt to do as much traffic as possible.</li>
									<li><img src={bullet} className="va-mid mr-2"/>  Running public Speedtest servers of any kind.</li>
								</ul>
								<h4>Services Not Allowed on Unmetered Plans</h4>
								<p>You may not use the Unmetered plans for the following services. Usage of the following will result in immediate service and account termination.</p>
								<ul>
									<li><img src={bullet} className="va-mid mr-2"/>  Public VPNs/Proxies</li>
									<li>- OpenVPN, Wireguard, Softether, Pritunl, StongSwan, Tunnelblick, OpenConnect, TINC, EOIP, L2TP, IPSEC</li>
									<li><img src={bullet} className="va-mid mr-2"/>  TOR Relay & Exit Node</li>
									<li><img src={bullet} className="va-mid mr-2"/>  Outbound email servers</li>
									<li><img src={bullet} className="va-mid mr-2"/>  Port scanning</li>
									<li><img src={bullet} className="va-mid mr-2"/>  Illegal activities</li>
								</ul>
								<h4>Consequences of Violation of AUP</h4>
								<p>If we determine, at our discretion, that this Acceptable Use Policy has been violated, we may need to limit, suspend, or terminate your use of the services. We may exercise our right to act at any given time, even if a violation is committed unintentionally or without your authorisation.</p>
                            </div>}
                        </div>
                    </div>
                </div>
            </section>

			<section className="green-bg bg-cta-squares" id="cta">
				<div className="container">
					<div className="row">
					<div className="col col-lg-6">
						<h2>Join Streamline Servers Today!</h2>
						<strong className="block semibold pb-4 font-22">We strive to be the best host possible, always adding more products and hardware to our infrastructure.</strong>
						<a href="https://billing.streamline-servers.com/register.php" className="button dark large mt-1">CREATE ACCOUNT <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></a>
					</div>
					<div className="col col-md-4 col-lg-5 ml-auto pt-4 ptfix">
						<ul className="font-18 pt-1" id="support-methods-list">
						<li className="py-2"><Travel to="/game-servers" className="white-link bold"><i className="fa-light fa-gamepad font-20 va-mid mr-2"></i> Game Servers</Travel></li>
						<li className="py-2"><Travel to="/cloud-gaming-servers" className="white-link bold"><i className="fa-light fa-cloud font-20 va-mid mr-2"></i> Cloud Gaming Servers</Travel></li>
						<li className="py-2"><Travel to="/dedicated-servers" className="white-link bold"><i className="fa-light fa-server font-20 va-mid mr-2"></i> Dedicated Servers</Travel></li>
						</ul>
					</div>
					</div>
				</div>
			</section>
        </Container>
    );
}