import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSharedCurrencyState } from '../layouts/Core/HeroBanner';
import cloudIcon1 from '../assets/images/cloud-icon-1.svg';
import cloudIcon2 from '../assets/images/cloud-icon-2.svg';
import cloudIcon3 from '../assets/images/cloud-icon-3.svg';
import cloudIcon4 from '../assets/images/cloud-icon-4.svg';

interface ServerData {
	Name: string;
	ID: string;
	Price: string;
	'CPU Cores': string;
	RAM: string;
	SSD: string;
	OS: string;
	'Game Panel': string;
}

export default function ServersCloud() {
	const [serverData, setServerData] = useState<ServerData[]>([]);
	const { currencySign, currencyId  } = useSharedCurrencyState();

	useEffect(() => {
		axios
			.get(`https://billing.streamline-servers.com/custom_api/gaming_vds_api.php?currency=${currencyId}`)
			.then((response) => {
				const data = response.data;
				setServerData(data);
			})
			.catch((error) => {
				console.error('Error fetching server data:', error);
			});
	}, [currencyId]);

	const getCloudIcon = (index: number) => {
		const cloudIconNumber = index % 4 + 1;
		switch (cloudIconNumber) {
			case 1:
				return cloudIcon1;
			case 2:
				return cloudIcon2;
			case 3:
				return cloudIcon3;
			case 4:
				return cloudIcon4;
			default:
				return cloudIcon1;
		}
	};

	return (
		<div>
			{serverData.map((item, index) => (
				<div className="box server-box cloud-box" key={index}>
					<div className="row">
						<div className="col col-server-details">
							<div className="block clearfix">
								<h3 className="inline-block pull-left">
									<img src={getCloudIcon(index)} className="va-mid mr-2" /> {item.Name}
								</h3>
							</div>
							<div className="row mt-4 pt-1">
								<div className="col col-cloud-cpu">
									<span className="font-12 opacity-50 block">
										<i className="fa-regular fa-microchip"></i> CPU
									</span>
									<strong>{item['CPU Cores']} Cores</strong> @ 4.6 GHz
								</div>
								<div className="col col-cloud-ram">
									<span className="font-12 opacity-50 block">
										<i className="fa-regular fa-memory"></i> RAM
									</span>
									<strong>{item.RAM}</strong> DDR4 2600MHz
								</div>
								<div className="col col-cloud-ssd">
									<span className="font-12 opacity-50 block">
										<i className="fa-regular fa-hard-drive"></i> STORAGE
									</span>
									<strong>{item.SSD}</strong> NVMe
								</div>
								<div className="col col-cloud-os">
									<span className="font-12 opacity-50 block">
										<i className="fa-regular fa-desktop"></i> OPERATING SYSTEM
									</span>
									<strong>{item.OS}</strong>
								</div>
								<div className="col col-cloud-panel">
									<span className="font-12 opacity-50 block">
										<i className="fa-regular fa-window"></i> GAME PANEL
									</span>
									<strong>{item['Game Panel']}</strong>
								</div>
							</div>
						</div>
						<div className="col col-server-price text-right ml-auto">
							<span className="block font-14">Starting at</span>
							<h3>
								{currencySign}{item.Price}
								<small className="font-16">/mo</small>
							</h3>
							<a href={`https://billing.streamline-servers.com/cart.php?a=add&pid=${item.ID}&currency=${currencyId}`} className="button mt-1">
								<i className="fa-regular fa-gear mr-1" />
								Configure
							</a>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}
