import { useEffect, useState } from 'react';
import Container from '../layouts/Core/Container';

const oneLiners = [
	"Looks like a dragon ate this page.",
	"Warp drive malfunctioned!",
	"Halt! You've reached the edge of the digital universe.",
	"The One Ring is missing.",
	"The force is weak with this page.",
	"Error not found in our magical spells.",
	"Page encrypted by mischievous goblins.",
	"The page is trapped in the Upside Down.",
	"The cake is a lie!",
	"This page was caught in a Poké Ball.",
	"Looks like a creeper got here first.",
	"Sorry, but your princess is in another castle.",
	"The Lich King stole this page.",
	"Looks like the page was hit by a blue shell.",
	"The page is stuck in the Nether.",
	"This page is on a quest to find the lost city of Atlantis.",
	"The page's magic is on cooldown.",
	"Looks like the page encountered a glitch in the matrix.",
	"The hero couldn't save this page.",
	"This page is in a time loop, try again later.",
	"The page is lost in space.",
	"This page got caught in a zombie apocalypse.",
	"The alien invaders abducted this page.",
	"Looks like the page encountered a rogue AI.",
	"The page fell into a black hole.",
	"This page is stuck in a text-based adventure.",
	"The page is out of ammo.",
	"The game crashed, and so did this page.",
	"This page joined the Dark Side of the Force.",
	"The page is in the clutches of a dungeon boss.",
];

export default function ErrorPage() {
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    // Randomly select a one-liner on page load
    const randomIndex = Math.floor(Math.random() * oneLiners.length);
    setErrorText(oneLiners[randomIndex]);
  }, []);

  return (
    <Container title='Streamline Servers - 404' description='Streamline Servers 404' className='page-404'>
		<div className="container">
			<div className="mt-5 line-gradient-divider"></div>
		</div>
		<section className="sub-hero text-center" id="hero">
			<div className="container mt-1">
				<h1 className="font-60">404</h1>
				<h4 className="mt-2">Page Not found</h4>
				<p className="mt-2">{errorText}</p>
			</div>
		</section>
	    <div className="container">
			<div className="line-gradient-divider"></div>
		</div>
    </Container>
  );
}