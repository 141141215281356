import Container from '../layouts/Core/Container';
import { TrustPilot } from '../layouts/TrustPilot';

import qmark from '../assets/images/qmark.svg';

 export default function AffiliatesPage() {
 
	const affiliateCode1 = `
		<a href="http://streamline-servers.com/billing/aff.php?aff=YOUR_AFFILIATE_NUMBER_HERE">
			<img src="https://streamline-servers.com/images/affiliate/729x90.png" width="729" height="90" border="0">
		</a>
	`;
	
	const affiliateCode2 = `
		<a href="http://streamline-servers.com/billing/aff.php?aff=YOUR_AFFILIATE_NUMBER_HERE">
			<img src="https://streamline-servers.com/images/affiliate/185x40.png" width="185" height="40" border="0">
		</a>
	`;
 
    return (
        <Container title='Streamline Servers - Affiliates' description='Become a Streamline Servers Affiliate and Earn Money by Referring Customers' className='page-affiliates'>
            <section className="text-center sub-hero pb-5" id="hero-affiliates">
                <div className="container">
                    <h1>Earn Money by Referring <br />Our Game Server Hosting <br />to Your Friends</h1>
                    <p className="pb-2 pt-3">
                    Welcome to Streamline Servers! We offer a range of premium game servers, web hosting, and dedicated servers that are perfect for competitive matches, casual gaming with friends or even business! As an affiliate, you can earn money by referring your friends and followers to our services.
                    </p>
                    <div className="block text-center mt-5 font-14" id="dedicated-trustpilot">
                    <img src={TrustPilot.trustpilotStarsHome} className="block mx-auto mb-3 tp-stars" /> Rated <strong>{TrustPilot.TrustScore}</strong> out of <strong>5.0</strong> based on <a href={TrustPilot.URL}>{TrustPilot.Reviews} reviews</a>
                    </div>
                    <div className="order-with-line mt-5">
                    <a href="https://billing.streamline-servers.com/register.php" className="button">Become An Affiliate</a>
                    </div>
                </div>
            </section>

            <section className="pt-5" id="affiliate-hiw">
                <div className="container relative">
                    <div className="title">
                    <h2><small>how does it work?</small>Join our Affiliate Program <br /> and Earn Money by Referring Customers</h2>
                    </div>
                    <div className="row">
                    <div className="col col-md-10 col-lg-6 mx-auto">
                        <div className="hiw-step">
                        <div className="hiw-step-1"></div>
                        <div className="absolute-left-parent">
                            <div className="absolute-left">
                                <i className="fa-solid fa-handshake-angle va-mid absolute-left font-55 grayscale grad-behind-icon"></i>
                                <i className="fa-thin fa-handshake-angle va-mid absolute-left font-55"></i>
                            </div>
                            <h4 className="font-20 pt-2"><small className="aff-step">Step 1</small>Become an affiliate</h4>
                            <p className="pt-2 pb-2">
                            Sign up for our affiliate program using your existing billing account or create a new one.
                            </p>
                            <a href="https://billing.streamline-servers.com/register.php" className="bold white-link">Join now <i className="fa-regular fa-circle-chevron-right font-12 ml-1 green-icon"></i></a>
                        </div>
                        </div>
                        <div className="hiw-step mt-5">
                        <div className="hiw-step-2"></div>
                        <div className="absolute-left-parent">
                            <div className="absolute-left">
                            <i className="fa-solid fa-share-nodes va-mid absolute-left font-55 grayscale grad-behind-icon"></i>
                            <i className="fa-thin fa-share-nodes va-mid absolute-left font-55"></i>
                            </div>
                            <h4 className="font-20 pt-2"><small className="aff-step">Step 2</small>Share your unique affiliate link</h4>
                            <p className="pt-2 pb-0">
                            Share your unique affiliate link with friends, family, and followers, and earn 10% of every sale.
                            </p>
                        </div>
                        </div>
                        <div className="hiw-step mt-5">
                        <div className="absolute-left-parent">
                            <div className="absolute-left">
                            <i className="fa-solid fa-coins va-mid absolute-left font-55 grayscale grad-behind-icon"></i>
                            <i className="fa-thin fa-coins va-mid absolute-left font-55"></i>
                            </div>
                            <h4 className="font-20 pt-2"><small className="aff-step">Step 3</small>Get paid, recurringly!</h4>
                            <p className="pt-2 pb-0">
                            Earn recurring income - as long as the client stays with us via your affiliate link, you'll receive 10% of the recurring cost each month.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="hiw-step-3"></div>
                </div>
            </section>

            <section className="pt-5" id="affiliates-payouts">
                <div className="container">
                    <div className="row">
                    <div className="col col-lg-6 pr-5 prfix">
                        <h2><small>PAYOUT INFO</small>Fast and low monthly payouts</h2>
                        <p className="pt-1">
                        Ready to start earning? <a href="https://billing.streamline-servers.com/register.php">Sign Up</a> as an affiliate today and start promoting our premium game server hosting and other services to your audience.
                        </p>
                        <strong>Use your affiliate earnings as credit for invoices or get paid via PayPal.</strong>
                    </div>
                    <div className="col col-lg-6 pl-5 plfix">
                        <div className="row">
                        <div className="col col-md-6">
                            <div className="aff-box">
                            <h2 className="font-68">10%</h2>
                            <strong className="font-18">Recurring payouts <br />every month</strong>
                            <span className="aff-tooltip"><span className="tooltip-bottom" data-tooltip="You earn 10% from every completed Sale."><img src={qmark} /></span></span>
                            </div>
                        </div>
                        <div className="col col-md-6">
                            <div className="aff-box">
                            <h2 className="font-68">$10</h2>
                            <strong className="font-18">Low minimum payout <br />via PayPal</strong>
                            <span className="aff-tooltip"><span className="tooltip-bottom" data-tooltip="Once you have accumulated over $10 worth of credit, we can pay you."><img src={qmark} /></span></span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>


            <section className="features light-bg" id="cloud-features">
                <div className="container mb-5">
                    <div className="title">
                    <h2><small>AFFILIATE RESOURCES</small>Pre-Made Banners and Assets</h2>
                    </div>
                    <div className="row">
						<div className="col text-center mb-4">
							<h4 className="pb-2">729 x 90</h4>
							<img src="https://streamline-servers.com/images/affiliate/729x90.png" />
						</div>
						<textarea className="aff-textarea" readOnly value={affiliateCode1} />
                    </div>
                    <div className="row">
						<div className="col text-center mt-6">
							<h4 className="pb-2">185 x 40</h4>
							<img src="https://streamline-servers.com/images/affiliate/185x40.png" />
						</div>
						<textarea className="aff-textarea" readOnly value={affiliateCode2} />
					</div>
                </div>
            </section>


            <section id="faq-single">
                <div className="container">
                    <div className="title">
                    <h2><small>Affiliate FAQ</small>Frequently Asked Questions</h2>
                    </div>
                    <div className="row">
                    <div className="col col-lg-8 mx-auto">
                        <div className="accordions" id="accordionFAQ">
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            How do I get paid out?
                                        </button>
                                        </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                            Simply log in to your billing account, go to the affiliate page and click on the Request Withdrawal button. From here, add if you want the funds added as credit or paid out to Paypal, if you are wanting it paid into your Paypal supply your Paypal details. Our team will then process this for you.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Do you have any resources I can use as an affiliate to advertise?
                                        </button>
                                        </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Yes! You can find our Pre-Made banenrs and advertising assets above.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Are there any products that don't count towards my affiliate income?
                                        </button>
                                        </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Only domains aren’t counted.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item faq-item">
                            <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Are there any rescritions on where I can advertise?
                                        </button>
                                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Currently there are no restrictions on advertising.
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section className="green-bg bg-cta-squares cta-dbl-btn" id="cta">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-8 col-lg-6">
                        <h2>Become an Affiliate Today!</h2>
                        <strong className="block semibold pb-4 font-22">Earn money by letting your friends know about our great hosting!</strong>
                        <strong className="font-18 block pb-1">Join today and start earning!</strong>
                    </div>
                    <div className="col col-md-4 col-lg-5 ml-auto pt-4 ptfix text-right">

                        <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="button outline large mt-3 mr-3">CONTACT US <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></a>
                        <a href="https://billing.streamline-servers.com/register.php" className="button dark large mt-3">JOIN NOW <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></a>

                    </div>
                    </div>
                </div>
            </section>
        </Container>
     );
 }
