import { useState } from 'react';
import Container from '../layouts/Core/Container';

import gslMark from '../assets/images/globalsecurelayer-mark.svg';
import partner1 from '../assets/images/partner-1.svg';
import partner2 from '../assets/images/partner-2.svg';
import partner3 from '../assets/images/partner-3.svg';
import partner4 from '../assets/images/partner-4.svg';
import partner5 from '../assets/images/partner-5.svg';
import partner6 from '../assets/images/partner-6.svg';
import partner8 from '../assets/images/partner-8.svg';
import partner9 from '../assets/images/partner-9.svg';
import partner10 from '../assets/images/partner-10.svg';
import partner11 from '../assets/images/partner-11.svg';
import partner12 from '../assets/images/partner-12.svg';

import { STANDARD_ROUTE_URIS } from '../routes/Router';
import Travel from '../layouts/Core/Travel';

export default function LocationPage() {

	// Things so you can dynamically update the inner component
    const [locationDetails, setLocationDetails] = useState({
        title: 'FL-01 Datacenter'
    });

    const Background = require('../assets/images/location-one-img-1.webp');

    return (
        <Container title={"Streamline-Servers - " + (locationDetails.title)} description={"Streamline Servers has a location in " + (locationDetails.title)} className="page-location-one">
            <section className="sub-hero pb-5 location-hero" id="hero">
                <div className="container relative">
                    <Travel to={STANDARD_ROUTE_URIS.ALL_LOCATIONS} className="label back-label absolute-left back-nav">&laquo; ALL LOCATIONS</Travel>
                    <div className="row">
                    <div className="col col-md-12 col-lg-6">
                        <h1>{locationDetails.title}</h1>
                        <h4 className="font-22 pt-1 green">Hivelocity datacenter, located in Tampa Bay, Florida</h4>
                        <p className="pb-4 pt-3">
                        Good wow helped got looked coffee is their somewhere, and something into as been achievements frequently to in those I follow of raised for one was lie for himself pleasures tried considerations, opulence called weary of avoid the forwards, day friends
                        ridden unprofitable blind could duckthemed assisted or go. King's the carpeting have exploration, between own was small time universal.
                        </p>
                        <p>
                        Duis faucibus ante id lectus scelerisque, vitae blandit enim varius sed ant dignissim massa, quis aliquam massa proin at tempus nisit and vestibulum lorem. Fusce porta nulla sed elit lobortis lobortis nullams faucibus lectus pharetra facilisis.
                        </p>
                    </div>
                    <div className="col col-md-12 col-lg-5 ml-auto pl-5 plfix">
                        <ul className="mt-5 font-16 mtfix">
                        <li>
                            <strong>Address:</strong>
                            <p className="block mt-n1">
                            8010 Woodland Center Blvd #700
                            <br /> Tampa, FL 33614
                            <br />
                            <a href="#">View On Map </a>
                            </p>
                        </li>
                        <li>
                            <strong>Test IP:</strong>
                            <p className="block mt-n1">
                            184.20.49.64 - <a href="#">Ping Test »</a>
                            </p>
                        </li>
                        <li>
                            <strong>Speed Test:</strong>
                            <p className="block mt-n1 pb-0">
                            <a href="#">10 MB</a> &nbsp; - &nbsp;
                            <a href="#">100 MB</a> &nbsp; - &nbsp;
                            <a href="#">1000 MB</a>
                            </p>
                        </li>

                        </ul>
                    </div>
                    </div>
                </div>
            </section>

            <section className="pt-4" id="location-specs">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-6 col-lg-4">
                        <div className="box rounded">
                        <h4 className="mb-3">
                            <div className="icon-relative inline-block mr-3 pull-left">
                            <i className="fa-solid fa-wind-turbine va-mid font-30 green-icon grad-behind-icon"></i>
                            <i className="fa-light fa-wind-turbine va-mid font-30 green-icon"></i>
                            </div>
                            Energy & Power
                        </h4>
                        <ul className="bullet-list">
                            <li>Diesel generator load tested and certified weekly</li>
                            <li>Permanently installed load banks</li>
                            <li>True “A” and “B” redundancy N+1</li>
                            <li>390 tons built out N+1 cooling capacity with chiller and condenser</li>
                            <li>High percentage of green hydroelectric produced power</li>
                        </ul>
                        </div>
                    </div>
                    <div className="col col-md-6 col-lg-4">
                        <div className="box rounded">
                        <h4 className="mb-3">
                            <div className="icon-relative inline-block mr-3 pull-left">
                            <i className="fa-solid fa-shield va-mid font-30 green-icon grad-behind-icon"></i>
                            <i className="fa-light fa-shield-halved va-mid font-30 green-icon"></i>
                            </div>
                            Security
                        </h4>
                        <ul className="bullet-list">
                            <li>Diesel generator load tested and certified weekly</li>
                            <li>Permanently installed load banks</li>
                            <li>True “A” and “B” redundancy N+1</li>
                            <li>390 tons built out N+1 cooling capacity with chiller and condenser</li>
                            <li>High percentage of green hydroelectric produced power</li>
                        </ul>
                        </div>
                    </div>
                    <div className="col col-md-6 col-lg-4">
                        <div className="box rounded">
                        <h4 className="mb-3">
                            <div className="icon-relative inline-block mr-3 pull-left">
                            <i className="fa-solid fa-circle va-mid font-30 green-icon grad-behind-icon"></i>
                            <i className="fa-light fa-globe va-mid font-30 green-icon"></i>
                            </div>
                            Network
                        </h4>
                        <ul className="bullet-list">
                            <li>Diesel generator load tested and certified weekly</li>
                            <li>Permanently installed load banks</li>
                            <li>True “A” and “B” redundancy N+1</li>
                            <li>390 tons built out N+1 cooling capacity with chiller and condenser</li>
                            <li>High percentage of green hydroelectric produced power</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section className="pt-0" id="offered-services">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-10 col-lg-7 mx-auto">
                        <h2><small>AVAILABLE SERVICES</small>Services At This Location</h2>
                        <ul className="quick-links mt-4">
                        <li>
                            <a href="#">
                            <div className="icon-relative pull-left mr-4">
                                <i className="fa-solid fa-server va-mid font-44 grad-behind-icon"></i>
                                <i className="fa-thin fa-server va-mid font-44"></i>
                            </div>
                            <div className="pull-left">
                                Dedicated Servers
                            </div>
                            <i className="fa-regular fa-chevron-right font-18 pull-right"></i>
                            <div className="pull-right mr-4 font-16">
                                From $4.95/mo
                            </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                            <div className="icon-relative pull-left mr-4">
                                <i className="fa-solid fa-cloud-arrow-up va-mid font-44 grad-behind-icon"></i>
                                <i className="fa-thin fa-cloud-arrow-up va-mid font-44"></i>
                            </div>
                            <div className="pull-left">
                                Cloud Gaming Servers
                            </div>
                            <i className="fa-regular fa-chevron-right font-18 pull-right"></i>
                            <div className="pull-right mr-4 font-16">
                                From $4.95/mo
                            </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                            <div className="icon-relative pull-left mr-4">
                                <i className="fa-solid fa-joystick va-mid font-44 grad-behind-icon"></i>
                                <i className="fa-thin fa-joystick va-mid font-44"></i>
                            </div>
                            <div className="pull-left">
                                Game Servers
                            </div>
                            <i className="fa-regular fa-chevron-right font-18 pull-right"></i>
                            <div className="pull-right mr-4 font-16">
                                From $4.95/mo
                            </div>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                            <div className="icon-relative pull-left mr-4">
                                <i className="fa-solid fa-desktop va-mid font-44 grad-behind-icon"></i>
                                <i className="fa-thin fa-desktop va-mid font-44"></i>
                            </div>
                            <div className="pull-left">
                                Web Hosting
                            </div>
                            <i className="fa-regular fa-chevron-right font-18 pull-right"></i>
                            <div className="pull-right mr-4 font-16">
                                From $4.95/mo
                            </div>
                            </a>
                        </li>

                        </ul>
                    </div>
                    </div>
                </div>
            </section>

            <section className="light-bg" id="location-pictures">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-6 col-lg-5">
                        <h2>Swap Games Instantly</h2>
                        <span className="font-18 bold mt-n1 block green pb-3">Finds of writing seemed before get options raised.</span>
                        <p className="pb-2">
                        The make language own looked control he to of such this range console at he little upper about ton caught its random text such his this.
                        </p>
                        <ul className="bullet-list pl-2 mt-1">
                        <li>430,556 sq ft (40,000 m2) gross surface</li>
                        <li>129,166 sq ft (12,000 m2) customer space</li>
                        <li>Modular room for growth</li>
                        </ul>
                        <strong className="font-16 block pt-4">DDoS Protection by <img src={gslMark} className="ml-1 mr-1 gsl-mark-sm"/>Global Secure Layer</strong>
                    </div>
                    </div>

                    <div id="carouselPicturesControls" className="carousel slide" data-bs-ride="carousel" data-bs-interval="43534534543">
                    <div className="carousel-inner">
                        <div className="location-carousel-controls">
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselPicturesControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselPicturesControls" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselPicturesControls" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselPicturesControls" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselPicturesControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                        </div>

                        <div className="carousel-item active" style={{backgroundImage: `url(${Background})`, backgroundSize: "cover !important"}}>
                        <div className="carousel-caption">
                            Main Server Hall
                        </div>
                        </div>
                        <div className="carousel-item" style={{backgroundImage: `url(${Background})`, backgroundSize: "cover !important"}}>
                        <div className="carousel-caption">
                            Main Server Hall
                        </div>
                        </div>
                        <div className="carousel-item" style={{background: `url(${Background})`, backgroundSize: "cover !important"}}>
                        <div className="carousel-caption">
                            Main Server Hall
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section id="location-stats">
                <div className="container">
                    <div className="row text-center">
                    <div className="col col-md-3 col-lg-25">
                        <div className="icon-relative inline-block mb-2">
                        <i className="fa-solid fa-circle-check va-mid font-44 mb-3 green-icon grad-behind-icon"></i>
                        <i className="fa-thin fa-circle-check va-mid font-44 mb-3 green-icon"></i>
                        </div>
                        <h3>99.999%</h3>
                        <span className="block font-18 semibold mt-n1">Uptime Guarantee</span>
                    </div>
                    <div className="col col-md-3 col-lg-25">
                        <div className="icon-relative inline-block mb-2">
                        <i className="fa-solid fa-gauge-max va-mid font-44 mb-3 green-icon grad-behind-icon"></i>
                        <i className="fa-thin fa-gauge-max va-mid font-44 mb-3 green-icon"></i>
                        </div>
                        <h3>10+ Tbps</h3>
                        <span className="block font-18 semibold mt-n1">Bandwidth Capacity</span>
                    </div>
                    <div className="col col-md-3 col-lg-25">
                        <div className="icon-relative inline-block mb-2">
                        <i className="fa-solid fa-chart-network va-mid font-44 mb-3 green-icon grad-behind-icon"></i>
                        <i className="fa-thin fa-chart-network va-mid font-44 mb-3 green-icon"></i>
                        </div>
                        <h3>6 Tier-1</h3>
                        <span className="block font-18 semibold mt-n1">Network Carriers</span>
                    </div>
                    <div className="col col-md-3 col-lg-25">
                        <div className="icon-relative inline-block mb-2">
                        <i className="fa-solid fa-hard-drive va-mid font-44 mb-3 green-icon grad-behind-icon"></i>
                        <i className="fa-thin fa-hard-drive va-mid font-44 mb-3 green-icon"></i>
                        </div>
                        <h3>10GE</h3>
                        <span className="block font-18 semibold mt-n1">Uplink Switches</span>
                    </div>
                    <div className="col col-md-3 col-lg-25">
                        <div className="icon-relative inline-block mb-2">
                        <i className="fa-solid fa-shield-check va-mid font-44 mb-3 green-icon grad-behind-icon"></i>
                        <i className="fa-thin fa-shield-check va-mid font-44 mb-3 green-icon"></i>
                        </div>
                        <h3>~500 Gbps</h3>
                        <span className="block font-18 semibold mt-n1">DDoS Protection</span>
                    </div>
                    </div>
                </div>
            </section>

            <section className="feature-box py-0" id="cta-gradient-box">
                <div className="container">
                    <div className="cta-gradient-box">
                    <div className="row">
                        <div className="col col-lg-6">
                        <h2><small>24/7/365 SUPPORT</small>Support you can count on!</h2>
                        <p>
                            Finds of writing seemed before get options raised this of my here monstrous and from client for two the positive.
                        </p>
                        <a href="#" className="button">LEARN MORE</a>
                        </div>
                        <div className="col col-lg-5 ml-auto pt-5 ptfix mt-2">
                        <ul className="font-18" id="support-methods-list">
                            <li className="py-2"><a href="#" className="white-link bold"><i className="fa-light fa-phone font-20 va-mid mr-2"></i> +1 (888) 945-5920</a></li>
                            <li className="py-2"><a href="#" className="white-link bold"><i className="fa-light fa-comments font-20 va-mid mr-2"></i> Live Chat Online</a></li>
                            <li className="py-2"><a href="#" className="white-link bold"><i className="fa-light fa-envelope font-20 va-mid mr-2"></i> sales@streamline-servers.net</a></li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section className="light-bg" id="location-partners">
                <div className="container">
                    <div className="row text-center">
                    <div className="col col-lg-2">
                        <img src={partner1} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={partner2} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={partner3} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={partner4} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={partner5} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={partner6} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={partner10} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={partner8} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={partner9} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={require('../assets/images/partner-7.webp')} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={partner11} />
                    </div>
                    <div className="col col-lg-2">
                        <img src={partner12} />
                    </div>
                    </div>
                </div>
            </section>

            <section id="request-quote">
                <div className="container">
                    <div className="title">
                    <h2><small>Contact Us</small>Request A Quote</h2>
                    <p>
                        Our servers are currently available in the following randomly
                    </p>
                    </div>
                    <div className="row quote-form">
                    <div className="col col-lg-8 mx-auto">
                        <div className="row">
                        <div className="col col-12">
                            <label htmlFor="yourname">First Name:</label>
                            <div className="input-wrapper">
                            <i className="fa-solid fa-user"></i>
                            <input type="text" value="" placeholder="Your Name Here" className="form-control input input-name" />
                            </div>
                        </div>
                        <div className="col col-6">
                            <label htmlFor="yourname">Email Address:</label>
                            <div className="input-wrapper">
                            <i className="fa-solid fa-user"></i>
                            <input type="email" value="" placeholder="Email Address" className="form-control input input-name" />
                            </div>
                        </div>
                        <div className="col col-6">
                            <label htmlFor="yourname">Phone Number:</label>
                            <div className="input-wrapper">
                            <i className="fa-solid fa-user"></i>
                            <input type="tel" value="" placeholder="+1-(555)-555-5555" className="form-control input input-name" />
                            </div>
                        </div>
                        <div className="col col-6">
                            <label htmlFor="yourname">Services Interested In:</label>
                            <div className="input-wrapper">
                            <i className="fa-solid fa-user"></i>
                            <select className="form-control select">
                                <option>Dedicated Servers</option>
                                <option>Dedicated Servers</option>
                            </select>
                            </div>
                        </div>
                        <div className="col col-6">
                            <label htmlFor="yourname">Location:</label>
                            <div className="form-control input fake-input">
                            <img src="images/flag-usa.svg" className="va-mid mr-2" /><strong>FL-01 Tampa Bay, Florida</strong>
                            </div>
                        </div>
                        <div className="col col-12">
                            <label htmlFor="yourname">Message:</label>
                            <textarea className="form-control textarea" placeholder="Enter your message here and we’ll get back to you!"></textarea>
                        </div>
                        <div className="col col-12">
                            <input type="submit" value="Send Message" className="button large submit-button" />
                        </div>

                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section className="green-bg bg-cta-squares" id="cta">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-8 col-xl-6">
                        <h2>Deploy your game server!</h2>
                        <strong className="block pb-4 font-22">Join today using promotional code <span className="cta-underline">NEW2022</span> and save 20%!</strong>
                        <strong className="font-18 block pb-1">Need some help? We're here for you.</strong>
                        <ul className="cta-link-list">
                        <li className="inline-block pr-3">
                            <a href="#" className="white-link"><i className="fa-solid fa-phone font-16 mr-1"></i>+1 (888) 890-9921</a>
                        </li>
                        <li className="inline-block pr-3">
                            <a href="#" className="white-link"><i className="fa-solid fa-envelope font-16 mr-1"></i>help@streamline-servers.com</a>
                        </li>
                        <li className="inline-block pr-3">
                            <a href="#" className="white-link"><i className="fa-solid fa-comments font-16 mr-1"></i>Live Chat</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col col-md-4 col-xl-6 text-center">
                        <strong className="font-14 italic block bold mb-2 uppercase">Promotion expires in:</strong>
                        <strong className="block big-timer mb-2" id="timer"><div id="clock"></div></strong>
                        <a href="#" className="button large dark">Order Now <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></a>
                    </div>
                    </div>
                </div>
            </section>
        </Container>
    );
}