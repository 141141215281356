import { useNavigate } from "react-router";
import { STANDARD_ROUTE_URIS } from "../../routes/Router";

interface IProps {
    to: string;
    children: any;
    id?: string;
    className?: string;
    style?: any;
}

export default function Travel( props: IProps ) {
    const navigate = useNavigate();

    return (
        <a id={props.id} className={props.className ?? ""} style={{
            cursor: 'pointer', ...props.style
        }}
        onClick={(e) => {e.preventDefault(); window.scrollTo(0, 0); navigate(props.to, {replace: true})}}
        href={`${props.to}`}>{props.children}</a>
    );

}
