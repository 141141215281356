import { useEffect, useState } from 'react';
import * as $ from 'jquery';

import ReactCountryFlag from "react-country-flag";

interface IProps {}

const locationData = [
	{
		region: "North America",
		locations: [
			{ city: "Chicago", country: "USA", countryCode: "US", className: "location-chi" },
			{ city: "Seattle", country: "USA", countryCode: "US", className: "location-sea" },
			{ city: "Dallas", country: "USA", countryCode: "US", className: "location-dal" },
			{ city: "Los Angeles", country: "USA", countryCode: "US", className: "location-la" },
			{ city: "Miami", country: "USA", countryCode: "US", className: "location-fl" },
			{ city: "Atlanta", country: "USA", countryCode: "US", className: "location-atl" },
			{ city: "New York", country: "USA", countryCode: "US", className: "location-ny" },
			{ city: "Montreal", country: "CA", countryCode: "CA", className: "location-qc" },
		]
	},
	{
		region: "Europe",
		locations: [
			{ city: "Amsterdam", country: "NL", countryCode: "NL", className: "location-ne" },
			{ city: "Paris", country: "FR", countryCode: "FR", className: "location-fr" },
			{ city: "Frankfurt", country: "DE", countryCode: "DE", className: "location-gr" },
			{ city: "London", country: "UK", countryCode: "GB", className: "location-uk" },
		]
	},
	{
		region: "Oceania",
		locations: [
			{ city: "Auckland", country: "NZ", countryCode: "NZ", className: "location-nz" },
			{ city: "Sydney", country: "AU", countryCode: "AU", className: "location-syd" },
			{ city: "Brisbane", country: "AU", countryCode: "AU", className: "location-bris" },
			{ city: "Melbourne", country: "AU", countryCode: "AU", className: "location-mel" },
			{ city: "Adelaide", country: "AU", countryCode: "AU", className: "location-adl" },
			{ city: "Perth", country: "AU", countryCode: "AU", className: "location-perth" },
			{ city: "Singapore", country: "SG", countryCode: "SG", className: "location-sg" },
			{ city: "Tokyo", country: "JP", countryCode: "JP", className: "location-jp" },
			{ city: "Hong Kong", country: "HK", countryCode: "HK", className: "location-hk" },
		]
	},
	{
		region: "South America",
		locations: [
			{ city: "São Paulo", country: "BR", countryCode: "BR", className: "location-br" },
		]
	}
];

export default function MapWithList( props: IProps ) {
    const [currentLocation, setCurrentLocation] = useState('');

	//This is to help with col grouping for aesthetics
	const firstGroup = ["North America", "Europe"];
	const secondGroup = ["Oceania", "South America"];

    useEffect(() => {
        const locations = ['chi', 'sea', 'dal', 'la', 'fl', 'atl', 'ny', 'qc', 'ne', 'fr', 'gr', 'uk', 'nz', 'syd', 'bris', 'mel', 'adl', 'perth', 'sg', 'jp', 'hk', 'br'];

        function showLocationInfo(locationClass) {
            $(".pin-hover").hide();
            $(".pin.active").removeClass('active');
            $(`.${locationClass}-info`).show();
            $(".map-has-list ul li").removeClass('active');
            $(".map-has-list ul li." + locationClass).addClass('active');
            $('.' + locationClass).addClass('active');
        }

        locations.forEach(location => {
            $(`.location-${location}`).on('click', function () {
                showLocationInfo('location-' + location);
            });
        });

        $('.loc-list').on('click', function () {
            const locationClass = $(this).attr('class').split(' ').find(className => className.startsWith('location-'));
            if (locationClass) {
                showLocationInfo(locationClass);
            }
        });

		const hideBox = (event) => {
			if (!$(event.target).closest('.pin, .pin-hover, .loc-list').length) {
				$(".pin-hover").hide();
				$(".pin.active").removeClass('active');
				$(".map-has-list ul li").removeClass('active');
			}
		};

        $(document).on('click', hideBox);

        return () => {
            $(document).off('click', hideBox);
            $('.loc-list').off('click');
            locations.forEach(location => {
                $(`.location-${location}`).off('click');
            });
        };
    }, []);

    return (
        <>
            <div className="row mt-3 mtfix map-has-list">
                <div className="col col-lg-4 col-xl-4 hidemobile hidetablet hidelg">
                    <div className="row pt-3 ptfix">
                        <div className="col col-md-6">
                            {locationData.filter(region => firstGroup.includes(region.region)).map((region, index) => (
                                <ul className={index % 2 === 0 ? '' : 'mt-4'}>
                                    <li><strong>{region.region}</strong></li>
                                    {region.locations.map(location => (
                                        <li className={`pt-1 loc-list ${location.className}`}>
                                            <ReactCountryFlag
                                                countryCode={location.countryCode}
                                                className="va-top mr-2"
                                                style={{ width: '1.2em', height: '1.2em' }}
                                                svg
                                            />
                                            {location.city}
                                        </li>
                                    ))}
                                </ul>
                            ))}
                        </div>
                        <div className="col col-md-6">
                            {locationData.filter(region => secondGroup.includes(region.region)).map((region, index) => (
                                <ul className={index % 2 === 0 ? '' : 'mt-4'}>
                                    <li><strong>{region.region}</strong></li>
                                    {region.locations.map(location => (
                                        <li className={`pt-1 loc-list ${location.className}`}>
                                            <ReactCountryFlag
                                                countryCode={location.countryCode}
                                                className="va-top mr-2"
                                                style={{ width: '1.2em', height: '1.2em' }}
                                                svg
                                            />
                                            {location.city}
                                        </li>
                                    ))}
                                </ul>
                            ))}
                        </div>
                    </div>
                </div>
			  <div className="col col-lg-12 col-xl-8">
				<div className="map">
				  {locationData.flatMap(region => region.locations).map(location => (
					<>
					  <div className={`pin ${location.className}`}></div>
					  <div className={`pin-hover ${location.className}-info`}>
						<strong>
						  <ReactCountryFlag 
							countryCode={location.countryCode}
							className="va-top mr-2"
							style={{width:'1.2em',height:'1.2em'}}
							svg
						  /> 
						  {location.city}
						</strong>
					  </div>
					</>
				  ))}
				</div>
			  </div>
            </div>
        </>
    );
}
