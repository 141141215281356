import { useEffect, useMemo, useState } from 'react';
import { useBetween } from 'use-between';
import { useGamesList, IGame } from './GamesList';
import { useSharedCurrencyState } from '../layouts/Core/HeroBanner';
import GameCard from './GameCard';

const Isotope = require('isotope-layout');

const useSearchState = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  return {
    search,
    setSearch,
    filter,
    setFilter,
  };
};

export const useSharedSearchState = () => useBetween(useSearchState);

export default function Games(props: { showCount?: boolean }) {
  const gamesList: IGame[] = useGamesList();
  const { currencySign } = useSharedCurrencyState();

  const [searchCount, setSearchCount] = useState(0);
  const { search, setSearch, filter, setFilter } = useSharedSearchState();

  const [iso, setIso]: any = useState();

  const currentGames = useMemo(() => {
    return (
      <>
        {gamesList.map((game, index) => (
          <GameCard key={`game-${index}`} game={game} />
        ))}
      </>
    );
  }, [gamesList, currencySign]);

  useEffect(() => {
    if (iso) {
      iso.destroy(); // Destroy previous Isotope instance if it exists
    }

    setIso(
      new Isotope(document.querySelector('.gamecontainer'), {
        itemSelector: '.gridcol',
        layoutMode: 'masonry',
        originTop: true,
        getSortData: {
          name: '.game-name',
        },
        sortBy: 'name',
      })
    );
  }, [currentGames]); // Re-run whenever currentGames updates

  useEffect(() => {
    if (iso !== undefined) {
      iso.arrange({
        filter: function (x) {
          var searchMatch = search ? x.textContent.match(new RegExp(search, 'gi')) : true;
          var filterMatch = filter ? x.className.match(new RegExp(filter, 'gi')) : true;

          return searchMatch && filterMatch;
        },
      });

      setSearchCount(iso.filteredItems.length);

      iso.arrange({ sortBy: 'name' });
    }
  }, [search, filter]);

  return (
    <>
      {props.showCount && <p className="filter-count">{searchCount} results found for term "{search}"</p>}
      <div className="row gamecontainer mx-0" id="games-rows">
        {currentGames}
      </div>
    </>
  );
}
