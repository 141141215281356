import React, { useEffect, useState } from 'react';
import Container from '../layouts/Core/Container';

export default function MyIP() {
  const [ip, setIP] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    // Fetch the user's IP address using an external API
    fetch('https://api64.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => setIP(data.ip))
      .catch((error) => console.error('Error fetching IP:', error));
  }, []);

  const handleCopy = () => {
    if (ip) {
      const tempInput = document.createElement('input');
      tempInput.value = ip;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      setCopied(true);
      setTimeout(() => setCopied(false), 2500); // Reset the "copied" state after 2.5 seconds
    }
  };

  return (
	<Container title="Streamline Servers - My IP" description="Streamline Servers My IP" className="page-myip">
	  <div className="container">
		<div className="mt-5 line-gradient-divider"></div>
	  </div>
	  <section className="sub-hero text-center" id="hero">
		<div className="container mt-1">
		  <h1 className="font-40">My IP</h1>
		  <p className="font-20">Below is your IP address:</p>
		  <div className="ip-container">
			{ip && (
			  <span className="ip-info">
				<h4 className="ip-name">{ip}</h4>
				<span className="copy-icon" onClick={handleCopy}>
				  <i className="fa-regular fa-clipboard"></i>
				</span>
				{copied && <span className="copied-message"> Copied!</span>}
			  </span>
			)}
			{!ip && <h4>Loading...</h4>}
		  </div>
		</div>
	  </section>
	  <div className="container">
		<div className="line-gradient-divider"></div>
	  </div>
	</Container>
  );  
}