import icon from '../../assets/images/logo.svg';
import { TrustPilot } from '../../layouts/TrustPilot';
import payments from '../../assets/images/payments.svg';
import * as $ from 'jquery';
import { useEffect } from 'react';

import { useTawkContext } from '../TawkContext';

import Travel from './Travel';


interface IProps {}

export default function PageFoot( props: IProps ) {

	const { handleMaximize } = useTawkContext();
    
    useEffect(() => {

        $(window).on('resize', function() {
            if ($(window).width() < 768 || $(window).height() < 480) {
                $("#footer-links ul").hide();

            }
            else {
                $("#footer-links ul").show();
                
                if($("#footer-links h5").hasClass('active')) {
                    $("#footer-links h5").toggleClass('active');
                }
                    
            }
        });

        $("#footer-links h5").on('click', function(e) {
            if ($(window).width() < 768 || $(window).height() < 480) {
                $(e.currentTarget).next("ul").slideToggle("slow");
                $(e.currentTarget).toggleClass('active');
            }                
        });

        return () => {
            $("#footer-links h5").off('click');
        }
    }, []);

    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-md-12 col-lg-4 tablet-clearfix">
                            <a href="#"><img src={icon} className="mb-4 footer-logo" /></a>
                            <ul className="social-list mt-2">
							<li className="py-1"><a href="https://discord.com/invite/u2tt6mm"><i className="fa-brands fa-discord"></i>Join The Discord</a></li>
                            <li className="py-1"><a href="https://twitter.com/StreamServers/"><i className="fa-brands fa-twitter"></i>Follow us on Twitter</a></li>
                            <li className="py-1"><a href="https://www.facebook.com/StreamlineServers"><i className="fa-brands fa-facebook-f"></i>Like us on Facebook</a></li>
                            </ul>
                        </div>
                        <div className="col col-md-12 col-lg-8" id="footer-links">
                            <div className="row">
								<div className="col col-md-4">
                                    <h5 id="footer-toggle-1">Company</h5>
                                    <ul id="footer-list-1">
                                        <li><Travel to="/">Home</Travel></li>
                                        <li><Travel to="/about">About Us</Travel></li>
                                        <li><Travel to="/affiliates">Affiliates</Travel></li>
                                    </ul>
                                </div>
                                <div className="col col-md-4">
                                    <h5 id="footer-toggle-1">Services</h5>
                                    <ul id="footer-list-1">
                                        <li><Travel to="/game-servers">Game Servers</Travel></li>
                                        <li><Travel to="/cloud-gaming-servers">Cloud Gaming Servers</Travel></li>
                                        <li><Travel to="/dedicated-servers">Dedicated Servers</Travel></li>
                                        <li><Travel to="/voice-servers">Voice Servers</Travel></li>
                                        <li><Travel to="/web-hosting">Web Hosting</Travel></li>
                                        <li><a href="https://billing.streamline-servers.com/cart.php?a=add&domain=register">Domain Names</a></li>
                                    </ul>
                                </div>
								<div className="col col-md-4">
                                    <h5 id="footer-toggle-1">Support</h5>
                                    <ul id="footer-list-1">
                                        <li><a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1">Contact Us</a></li>
										<li><a href="javascript:void(0);" onClick={handleMaximize}>Live Support</a></li>
                                        <li><Travel to="/legal">Terms of Service</Travel></li>
                                        <li><Travel to="/legal">Privacy Policy</Travel></li>
                                        <li><Travel to="/legal">Acceptable Use Policy</Travel></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-secondary">
							<div className="row">
								<div className="col">
									<span className="tp-line-one">
										<h4 className="inline-block">{TrustPilot.Rating}</h4>
										<img src={TrustPilot.trustpilotStarsGreenSmall} className="inline-block va-mid mx-2" />
									</span>
									<strong className="inline-block"><span className="showmobile">Based on&nbsp;</span>{TrustPilot.Reviews} reviews at</strong>
									<a href={TrustPilot.URL}><img src={TrustPilot.trustpilotLogoGreenSmall} className="inline-block va-mid ml-1 tp-logo" /></a>
								</div>
								<div className="col ml-auto text-right">
									<img src={payments} />
								</div>
							</div>
						</div>
                    </div>
                </div>
            </footer>
            <div className="py-4" id="copyright">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-12 col-lg-6">
                        Copyright © 2007-{new Date().getFullYear()} Streamline Servers Limited - All Rights Reserved.
                    </div>
                    <div className="col col-md-12 col-lg-6 ml-auto">
                        <ul className="pull-right">
                        <li className="inline-block"><a href="/legal#tos">Terms of Service</a></li>
                        <li className="inline-block mx-3">|</li>
                        <li className="inline-block"><a href="/legal#pp">Privacy Policy</a></li>
                        <li className="inline-block mx-3">|</li>
						<li className="inline-block"><a href="/legal#aup">Acceptable Use Policy</a></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );

}
