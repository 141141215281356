import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from '../layouts/Core/Container';
import { useTawkContext } from '../layouts/TawkContext';

import mumble from '../assets/images/mumble.svg';
import mumbleHover from '../assets/images/mumble-hover.svg';

import teamspeak from '../assets/images/teamspeak.svg';
import teamspeakHover from '../assets/images/teamspeak-hover.svg';

import MapVoice from '../layouts/MapVoice';

import { useSharedCurrencyState } from '../layouts/Core/HeroBanner';

interface ServerData {
    Name: string;
    Price: string;
    ID: number;
    SlotsPrice: string;
	Stock: boolean;
}

export default function VoiceServersPage() {
	const [servers, setServers] = useState<ServerData[]>([]);
	const { currencySign, currencyId  } = useSharedCurrencyState();
	
	const { handleMaximize } = useTawkContext();
	
    useEffect(() => {
        axios.get(`https://billing.streamline-servers.com/store/voice-servers?json=1&currency=${currencyId}`)
            .then(response => {
                setServers(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [currencyId]);

    return (
        <Container title='Streamline Servers - Voice Servers' description='Need quality low latency voice coms for competitive gaming? Or an immersive voice platform for your RP? We have the perfect voice package for you!' className='page-voice'>
            <section className="text-center sub-hero pb-5" id="hero-voice">
                <div className="container">
                    <h1>Voice Servers</h1>
                    <p className="pb-2">
						Need quality low latency voice coms for competitive gaming? Or an immersive voice platform for your RP? We have the perfect voice package for you!
                    </p>
                    <strong className="font-18 block pt-5">Choose between our 2 voice server options below:</strong>
                </div>
            </section>

            <section className="pt-0 text-center mt-n3" id="voice-servers">
                <div className="container">
                    <div className="row">
						<div className="col col-lg-7 mx-auto">
							<div className="row">
								{servers.map((server, index) => (
									<div key={index} className="col col-lg-6">
										<div className="box pt-5 pb-4">
											<div className="block pb-2">
												<h5 className="pb-3">{server.Name}</h5>
												<div className="hover-image voice-image mb-3">
													<img src={index === 0 ? mumble : teamspeak} />
                                                    <img src={index === 0 ? mumbleHover : teamspeakHover} />
												</div>
												<span className="font-14">Starting at</span>
												<h2 className="semibold block mt-1">{currencySign}{server.Price}<strong className="font-18">/mo</strong></h2>
												<strong className="label secondary mt-n1">{currencySign}{server.SlotsPrice} per Slot</strong>
											</div>
											<ul className="mt-3">
												<li className="py-1"><i className="fa-solid fa-check blue mr-2"></i>Quick and Easy to Use Control Panel <span className="tooltip-top inline-block" data-tooltip="Manage your Voice Server with our Game Panel!"><i className="fa-regular fa-circle-question font-14 green ml-1"></i></span></li>
												<li className="py-1"><i className="fa-solid fa-check blue mr-2"></i>High Stability and Uptime</li>
											</ul>
											{server.Stock 
												? <a href={`https://billing.streamline-servers.com/cart.php?a=add&pid=${server.ID}&currency=${currencyId}`} className="button block mt-3">Order Now</a>
												: <a href="javascript:void(0)" className="button out-of-stock mt-3"><i className="fa-regular fa-ban mr-1"></i>No Stock</a>
											}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>

            <section className="features light-bg" id="voice-features">
                <div className="container">
                    <div className="title">
                    <h2><small>What's Included?</small>Voice Server Features</h2>
                    <p>
                        All our voice servers include the latest features to ensure the best performance
                    </p>
                    </div>
                    <div className="row text-center mt-2 mtfix">
                    <div className="col col-md-6 col-lg-3 px-4">
                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-clock va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-clock va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                        <h4>Instant Deployment</h4>
                        <p className="pb-0">
                        Upon payment, our control panel facilitates the instant installation of voice servers, ensuring a hassle-free and streamlined experience for customers.
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-3 px-4">
                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-chart-network va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-chart-network va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                        <h4>Low Latency</h4>
                        <p className="pb-0">
                        Our low latency network provides customers with a smooth and responsive voice servers, minimizing delays and ensuring optimal connectivity.
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-3 px-4">
                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-shield va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-shield va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                        <h4>DDoS Protection</h4>
                        <p className="pb-0">
                        We partner with Global Secure Layer to offer robust DDoS protection, safeguarding voice servers against malicious attacks and ensuring uninterrupted voice calls.
                        </p>
                    </div>
                    <div className="col col-md-6 col-lg-3 px-4">
                        <div className="icon-relative inline-block">
                                            <i className="fa-solid fa-hard-drive va-mid font-55 mb-3 green-icon grad-behind-icon"></i>
                                            <i className="fa-thin fa-hard-drive va-mid font-55 mb-3 green-icon"></i>
                                        </div>
                        <h4>Control Panel</h4>
                        <p className="pb-0">
                        With its flexible modern design and powerful features, our game panel gives end users complete control over their voice servers. Manage both Voice and Game servers in one panel.
                        </p>
                    </div>

                    </div>
                </div>
            </section>

            <section id="locations-with-list">
                <div className="container">
                    <div className="title">
                    <h2><small>Whats Included?</small>Hassle-Free Game Hosting</h2>
                    <p>
                        Our servers are currently available in the following international global locations.
                    </p>
                    </div>
                    <MapVoice/>
                </div>
            </section>


            <section className="py-0" id="cta-gradient-box">
                <div className="container">
                    <div className="cta-gradient-box">
                    <div className="row">
                        <div className="col">
                        <h2><small>24/7/365 SUPPORT</small>Support you can count on!</h2>
                        <p>
							Our team is always here to help. Whether you are stuck on configuring your voice server or need help with permissions, we can help!
						</p>
						<a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="button outline mt-1 mr-3">SUPPORT TICKET <i className="fa-sharp fa-solid fa-arrow-right-long ml-1"></i></a>
						<a href="javascript:void(0);" onClick={handleMaximize} className="button mt-1">Sales Live Chat</a>
						</div>
                    </div>
                    </div>
                </div>
            </section>

            <section id="faq-single">
    <div className="container">
        <div className="title">
            <h2><small>Voice Server FAQ</small>Frequently Asked Questions</h2>
        </div>
        <div className="row">
            <div className="col col-lg-8 mx-auto">
                <div className="accordions" id="accordionFAQ">
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Can I take backups of my server?
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Yes, you are able to take backups of your services. However, with Teamspeak these are taken as snapshots, which can only be applied to the same services and cannot be exported.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Can I use your Mumble servers for GTA Role Play?
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Yes, we already have many clients using Mumble for this reason. We ensure the software is constantly kept up to date with the latest features and to ensure compatibility with the RP Mods.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item faq-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Do I get a discount for ordering the service for longer?
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Yes, all game services come with a discount based on the length of time. This is 10% for 3 months, 15% for 6 months, and 20% for 12 months. You will find a button while ordering to change the length of time you are ordering for.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


            <section className="green-bg bg-cta-squares" id="cta">
                <div className="container">
                    <div className="row">
                    <div className="col col-md-8 col-lg-6">
                        <h2>Try a Cloud Gaming Server Today!</h2>
                        <strong className="block pb-4 font-22">Not sure if this is right for you? Contact Us!</strong>
                        <strong className="font-18 block pb-1">You can contact us at anytime</strong>
                        <ul className="cta-link-list">
                        <li className="inline-block pr-3">
                            <a href="https://billing.streamline-servers.com/submitticket.php?step=2&deptid=1" className="white-link"><i className="fa-solid fa-envelope font-16 mr-1"></i>Support Ticket</a>
                        </li>
                        <li className="inline-block pr-3">
                            <a href="javascript:void(0);" onClick={handleMaximize} className="white-link"><i className="fa-solid fa-comments font-16 mr-1"></i> Sales Live Chat</a>
                        </li>
                        </ul>
                    </div>
                    <div className="col col-md-4 col-lg-6 text-center">
                        <a href="#" className="button large dark mt-5">Back to Top <i className="fa-sharp fa-solid fa-arrow-up-long ml-1"></i></a>
                    </div>
                    </div>
                </div>
            </section>
        </Container>
    );
}
